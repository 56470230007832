import componentLibraries from '~/component-libraries';

export function getAllComponents({jxnApp}) {
  return [
    {name: 'If', supportsStyle: false, supportsProps: false},
    {name: 'Map', supportsStyle: false, supportsProps: false},
    {name: 'Component', supportsStyle: false, supportsProps: false},
    ..._.flatMap(componentLibraries, 'components'),
    ...jxnApp.components,
  ];
}

export function getComponent({jxnApp, type}) {
  return _.find(getAllComponents({jxnApp}), {name: type});
}
