import * as espree from 'espree';
import _ from 'lodash';

export default function detectMultipleStatements(script) {
  if (_.startsWith(script, '...')) return false;

  try {
    var parsedScript = espree.parse(script, {ecmaVersion: 2020});

    return parsedScript.body.length > 1;
  }
  catch (error) {
    return true;
  }
}
