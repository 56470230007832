import React, { useState, useRef, useEffect } from 'react';
import { Pressable, View, ScrollView, ActivityIndicator } from 'react-native';
import { TextInput, Text, Button, Tooltip } from '@symbolic/rn-lib';

import K from '~/k';
import _ from 'lodash';
import ExpandableSection from '~/components/ExpandableSection';
import useKeyDown from '~/helpers/useKeyDown';
import componentLibraries from '~/component-libraries';

const CommandInput = ({commandInputRef, jxnApp, createNodes, commandInputIsFocused, borderRadius, setCommandInputIsFocused, setCreateData, isCreatingNodes}) => {
  var [commandInputValue, setCommandInputValue] = useState('');
  var [activeCommandIndex, setActiveCommandIndex] = useState(0);

  var scrollViewRef = useRef();
  var commandRefs = useRef({});

  var commandInputValueLowercase = _.lowerCase(commandInputValue).replace('<', '').replace('>', '');
  var customComponents = _.filter(jxnApp.components, node => node.type === 'Component' && node.name !== jxnApp.rootComponent);

  var categories = _.filter(_.map([
    ...(customComponents.length ? [{title: 'Custom', commands: _.map(customComponents, component => ({
      label: component.name,
      type: component.name,
      action: 'createComponent',
      component
    }))}] : []),
    ..._.map(_.filter(componentLibraries, ({name}) => _.includes(jxnApp.componentLibraries, name)), componentLibrary => ({
      title: componentLibrary.displayName || componentLibrary.name,
      commands: _.map(componentLibrary.components, (component) => ({
        label: component.name,
        type: component.name,
        action: 'createComponent',
        component
      }))
    })),
    {title: 'Utility', commands: [
      {label: 'Component', type: 'Component', action: 'createComponent'},
      {label: 'If', type: 'If', action: 'createComponent'},
      {label: 'Map', type: 'Map', action: 'createComponent'}
    ]},
    {title: 'HTML', commands: [
      ...(commandInputValue && commandInputValue[0] === commandInputValue[0].toLowerCase() ? [{label: `<${commandInputValue.replace('<', '').replace('>', '')}>`, type: commandInputValue.replace('<', '').replace('>', ''), action: 'createComponent'}] : [])
    ]},
  ], category => {
    return {...category, commands: _.filter(category.commands, ({label}) => _.startsWith(_.lowerCase(label), commandInputValueLowercase))};
  }), ({commands}) => commands.length > 0);

  var commands = _.flatMap(categories, ({commands}) => commands);

  if (commands.length === 1) activeCommandIndex = 0;

  // commands = [
  //   {__text: 'AI create ...', __category: 'AI', type: 'AI Command', isPseudoAction: true},
  //   {__text: 'AI edit ... (coming soon)', __category: 'AI', type: 'AI Command', isPseudoAction: true}
  // ];

  var selectCommandInputText = () => {
    commandInputRef.current.select();
  };

  useEffect(() => {
    if (!commandInputIsFocused) {
      setActiveCommandIndex(0);
    }
  }, [commandInputIsFocused]);

  var createNodeFromCommand = ({command}) => {
    var newNode = {};

    if (command.type === 'Component') {
      newNode = {type: 'Component', name: 'Untitled', expressions: [], children: []}; //TODO render
    }
    else if (command.type === 'If') {
      newNode = {type: 'If', condition: '{true}', children: []};
    }
    else if (command.type === 'Map') {
      newNode = {type: 'Map', data: [{id: 1}, {id: 2}, {id: 3}], var: ['item', 'index'], children: []};
    }
    else if (command.component) {
      newNode = {type: command.type, props: {}, children: [], ...command.component.defaultConfiguration}; //TODO ensure unique

      _.forEach(command.component.propTypes, ({name, defaultValue}) => {
        if (defaultValue) {
          newNode.props[name] = defaultValue;
        }
      });
    }
    else {
      newNode = {type: command.type, props: {}, children: []};
    }

    createNodes([newNode], {...(command.type === 'Component' ? {parentNodePath: ''} : {})});

    selectCommandInputText();
  };

  var handleKeyDown = event => {
    if (!commandInputIsFocused && event.key !== '/') return;

    if (event.key === '/') {
      if (document.activeElement.tagName === 'BODY') {
        commandInputRef.current.focus();

        event.preventDefault();
      }
    }
    else if (event.key === 'Escape') {
      setCommandInputIsFocused(false);

      commandInputRef.current?.blur();
    }
    else if (event.key === 'Enter') {
      if (commandInputIsFocused) {
        // if (_.startsWith(commandInputValue.toLowerCase(), 'AI create ')) {
        //   search(commandInputValue);

        //   setCommandInputValue('');
        // }
        // else if (_.startsWith(commandInputValue.toLowerCase(), 'AI edit ')) {
        //   alert('The ability to modify your app using AI commands isn\'t ready yet, but is coming soon!')
        // }
        // else {
        var command = commands[activeCommandIndex];

        if (command) {
          // if (command.isPseudoAction) {
          //   setCommandInputValue(command.__text.replace('...', ''));
          // }
          // else {
          createNodeFromCommand({command});

          event.preventDefault();
          // }
        }
        // }

        setTimeout(() => commandInputRef.current.focus());
      }
    }
    else if (event.key === 'ArrowUp' && !event.altKey) {
      if (activeCommandIndex > 0) {
        setActiveCommandIndex(activeCommandIndex - 1);
        setCreateData({nodeType: commands[activeCommandIndex - 1].type});

        setTimeout(() => commandRefs.current[activeCommandIndex - 1].scrollIntoView({block: 'nearest', behavior: 'smooth'}));
      }

      event.preventDefault();
    }
    else if (event.key === 'ArrowDown' && !event.altKey) {
      if (activeCommandIndex < commands.length - 1) {
        setActiveCommandIndex(activeCommandIndex + 1);
        setCreateData({nodeType: commands[activeCommandIndex + 1].type});

        setTimeout(() => commandRefs.current[activeCommandIndex + 1].scrollIntoView({block: 'nearest', behavior: 'smooth'}));
      }

      event.preventDefault();
    }
  };

  useKeyDown(handleKeyDown);

  var index = 0;

  return (
    <>
      {commandInputIsFocused && (
        <View style={{height: '100%', zIndex: 100, backgroundColor: 'white', width: '100%', borderRadius, overflow: 'auto', paddingTop: K.spacing * 2, padding: K.spacing}} ref={scrollViewRef}>
          <TextInput
            getInputRef={(ref) => commandInputRef.current = ref}
            style={{height: 20, minHeight: 20, borderRadius: 10, marginBottom: K.spacing * 2, backgroundColor: K.colors.gray, outline: 'none', paddingHorizontal: K.spacing, marginHorizontal: K.spacing}}
            value={commandInputValue || ''}
            placeholder={'Search...'}
            placeholderTextColor={'rgba(0, 0, 0, 0.5)'}
            onKeyDown={handleKeyDown}
            onFocus={() => setCommandInputIsFocused(true)}
            onBlur={({event}) => {
              if (event.relatedTarget && (scrollViewRef.current.contains(event.relatedTarget) || event.relatedTarget.getAttribute('data-tree-node'))) { //TODO
                commandInputRef.current.focus();
              }
              else {
                setTimeout(() => setCommandInputIsFocused(false), 50);
              }
            }}
            onInput={({value}) => {
              setCommandInputValue(value);

              setTimeout(() => global.commandInputValue = value);
              // setActiveCommandIndex(value && value[0] === value[0].toLowerCase() ? commands.length - 1 : 0);
            }}
          />

          {_.map(categories, ({title, commands}, categoryIndex) => {
            // var categoryCommands = _.filter(commands, {__category: category});

            return (
              <ExpandableSection key={title} title={title} renderDisplayNone shouldUsePersistentState={false} isExpandedByDefault styles={{outerView: {...(categoryIndex === 0 ? {borderTopWidth: 0} : {})}}} contentContainerStyle={{flexDirection: 'row', flexWrap: 'wrap'}}>
                {_.map(commands, (command) => {
                  var isActive = activeCommandIndex === index;
                  var commandIndex = index;

                  index += 1;

                  return (
                    <Pressable dataSet={{hoverable: 1}} key={command.__text} ref={(ref) => commandRefs.current[commandIndex] = ref} onPress={(event) => {
                      // if (command.isPseudoAction) {
                      //   setCommandInputValue(command.__text.replace('...', ''));

                      //   event.preventDefault();

                      //   setActiveCommandIndex(commandIndex);
                      //   setCreateData({nodeType: command.type});
                      // }
                      // else {
                      createNodeFromCommand({command});
                      // createNodes([_.omit(command, ['__text', '__category', '__description'])], {shouldSetActiveNode: event.shiftKey ? true : false, callback: () => {
                      //   // setActiveCommandIndex(commandIndex);
                      //   // setCreateData({nodeType: command.type});

                      selectCommandInputText();
                      // }});
                      // }
                    }} style={{marginBottom: 3, marginRight: 3}}>
                      <View style={{backgroundColor: isActive ? 'black' : K.colors.doubleGray, height: 20, borderRadius: 10, ...(command.__description ? {paddingTop: 4, borderBottomLeftRadius: 0, borderBottomRightRadius: 0} : {}), justifyContent: 'center', paddingHorizontal: 12}}>
                        <Text style={{color: isActive ? 'white' : 'black'}}>{command.label}</Text>
                      </View>
                      {/* {command.__description && (
                        <View style={{backgroundColor: isActive ? 'black' : K.colors.doubleGray, paddingHorizontal: 12, paddingVertical: 2, paddingBottom: 5, borderBottomRightRadius: 10, borderBottomLeftRadius: 10}}>
                          <Text style={{color: isActive ? 'white' : 'black', opacity: 0.5}}>{command.__description}</Text>
                        </View>
                      )} */}
                    </Pressable>
                  );
                })}
              </ExpandableSection>
            );
          })}
        </View>
      )}
      {/* </View> */}
    </>
  );
};

export default CommandInput;
