import { useState } from 'react';

var usePersistentState = (initialValue, key) => {
  var localStorageValue = localStorage.getItem(key);

  initialValue = localStorageValue !== null ? JSON.parse(localStorageValue) : initialValue;

  var [state, setState] = useState(initialValue);

  return [state, (value) => {
    setState(value);

    localStorage.setItem(key, JSON.stringify(value));
  }];
};

export default usePersistentState;
