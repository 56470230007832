import React, { useEffect, useState, useRef } from 'react';

import { View, Image, Pressable, ScrollView, FlatList } from 'react-native';
import { Text, PickerInput, DocumentTitle, TextInput, Popup, Button, CheckboxInput, LabelledView, Link } from '@symbolic/rn-lib';
import { connect } from '@symbolic/redux';
import { api } from '@symbolic/lib';
import { resourceActions, setActiveView } from '~/redux';
import { v4 as uuid } from 'uuid';

import CodeInput from '~/components/CodeInput';
import _ from 'lodash';
import K from '~/k';
import downArrow from '~/assets/down-arrow-black.png';
import FileInput, { uploadFile } from '~/components/FileInput';
import Dropdown from '~/components/Dropdown';
import useKeyDown from '~/helpers/useKeyDown';

var getColumnTypeIsFocusable = type => (type === 'text' || type === 'string' || type === 'number' || type === 'json');

var Row = ({children, ...props}) => {
  return (
    <View {...props} style={{flexDirection: 'row', ...props.style}}>
      {children}
    </View>
  );
};

var DatabaseRecordCell = ({databaseRecord, cellToLeftIsActive, cellBelowIsActive, column, index, columns, session, handleKeyDownRef, setActiveColumnId, setActiveRecordId, setIsEditing, isEditing, isActive, cellRefs, ...props}) => {
  var {type} = column;
  var lastClickRef = useRef();

  var propValue = _.get(databaseRecord, `props.${column.key}`);

  var handleValueChange = ({value}) => {
    if (column.type === 'number') value = parseFloat(value) || 0;

    var newProps = {...databaseRecord.props, [column.key]: type === 'media' ? _.omit(value, ['url']) : value};

    props.updateDatabaseRecord({id: databaseRecord.id, props: {props: newProps}});

    if (type === 'media') {
      setTimeout(() => {
        var newProps = {...databaseRecord.props, [column.key]: value};

        props.updateDatabaseRecord({id: databaseRecord.id, props: {props: newProps}, hitApi: false});
      }, 50);
    }
  };

  var isEditable = getColumnTypeIsFocusable(type);

  if (!cellRefs.current[databaseRecord.id]) cellRefs.current[databaseRecord.id] = {};

  return (
    <View
      ref={ref => cellRefs.current[databaseRecord.id][`${column.id}`] = ref}
      style={{borderWidth: 0, minHeight: type === 'media' ? 100 : 0, borderRightWidth: index === columns.length - 1 ? 1 : 0, borderLeftWidth: 1, ...(cellToLeftIsActive ? {borderLeftColor: 'black'} : {}), ...(cellBelowIsActive ? {borderBottomColor: 'black'} : {}), borderBottomWidth: 1, borderStyle: 'solid', justifyContent: 'center', borderColor: isActive ? 'black' : 'rgba(0, 0, 0, 0.05)', paddingVertical: 3, paddingLeft: K.spacing, paddingRight: K.spacing, width: 150, ...(type === 'media' ? {backgroundImage: `url(${_.get(propValue, 'url')}`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat'} : {})}}
      onMouseUp={(event) => {
        if (isEditable && !isEditing) {
          if (lastClickRef.current && Date.now() - lastClickRef.current < 500) {
            event.preventDefault();

            setIsEditing(true);
          }
          else {
            setTimeout(() => document.activeElement?.blur());

            setActiveRecordId(databaseRecord.id);
            setActiveColumnId(column.id);
            setIsEditing(false);
          }

          lastClickRef.current = Date.now();
        }
      }}
    >
      {(type === 'text' || type === 'string' || type === 'number' || type === 'json') && (
        <View
          style={{width: '100%', height: '100%', minHeight: 20, justifyContent: 'center'}} //cursor text
        >
          {isEditing ? (
            <TextInput
              style={{...K.fonts.standard, lineHeight: 1.2 * 12, paddingHorizontal: 0, paddingVertical: 0, backgroundColor: 'transparent', height: '100%', width: '100%', borderRadius: 0, position: 'relative', fontSize: 12}}
              value={propValue}
              onChange={handleValueChange}
              onBlur={() => {
                setActiveRecordId(databaseRecord.id);
                setActiveColumnId(column.id);
                setIsEditing(false);
              }}
              onKeyDown={handleKeyDownRef.current}
              multiline
              autoheight
              getInputRef={ref => {
                if (ref) {
                  ref.focus();
                  ref.selectionStart = ref.selectionEnd = 10000;
                }
              }}
            />
          ) : (
            <Text style={{userSelect: 'none', fontSize: 12, lineHeight: 1.2 * 12}}>{type === 'json' ? JSON.stringify(_.get(databaseRecord, `props.${column.key}`)) : _.get(databaseRecord, `props.${column.key}`)}</Text>
          )}
        </View>
      )}
      {type === 'media' && (
        <FileInput
          handleFilesPicked={(files) => {
            uploadFile({files,
              api,
              type: 'image', //TODO video
              resource: propValue ? {mediumId: propValue.mediumId} : undefined,
              session,
              handleValueChange
            });
          }}
        />
      )}
      {type === 'boolean' && (
        <CheckboxInput
          value={propValue === true ? 1 : 0}
          onChange={({value}) => handleValueChange({value: value === 0 ? false : true})}
          checkboxStyle={{width: 20, height: 20, borderLeftWidth: 0, borderRadius: K.borderRadius/*, borderTopRightRadius: 0, borderBottomRightRadius: 0*/}}
          labelStyles={{display: 'none'}}
          style={{minHeight: 0, backgroundColor: 'transparent'}}
        />
      )}
    </View>
  );
};

DatabaseRecordCell = React.memo(connect({
  mapDispatch: {
    ..._.pick(resourceActions.databaseRecords, ['updateDatabaseRecord']),
  }
})(DatabaseRecordCell));

var DatabaseRecord = ({databaseTable, databaseRecord, activeRecordIsBelow, setActiveRecordId, isActiveRecord, activeColumnId, setActiveColumnId, recordRefs, cellRefs, setIsEditing, isEditing, handleKeyDownRef, ...props}) => {
  var rowRef = useRef();

  // useOnPressOutside(rowRef, (event) => {
  //   setTimeout(() => {
  //     if (isActive) {
  //       setActiveRecordId(undefined);
  //       setActiveColumnId(undefined);
  //     }
  //   });
  // });

  return (
    <Pressable
      ref={ref => {
        recordRefs.current[databaseRecord.id] = ref;

        rowRef.current = ref;
      }}
      style={{flexDirection: 'row', width: 60 + _.size(databaseTable.columns) * 150}}
      dataSet={{conditionalOpacityParent: 1, databaseRecordRow: 1, activeDatabaseRecordRow: isActiveRecord ? 1 : 0}}
      // onPress={() => {
      //   if (!isActive) {
      //     setActiveRecordId(databaseRecord.id);
      //     setActiveColumnId(undefined);
      //   }
      // }}
    >
      <View style={{paddingLeft: K.spacing, width: 60, justifyContent: 'center', paddingRight: K.spacing, borderWidth: 0, borderBottomWidth: 1, borderStyle: 'solid', borderColor: 'rgba(0, 0, 0, 0.05)'}}>
        <Text style={{opacity: 0.5}}>{databaseRecord.recordId}</Text>
      </View>
      {_.map(databaseTable.columns, (column, index) => (
        <DatabaseRecordCell
          {...{databaseRecord, column, index, cellRefs, setActiveColumnId, handleKeyDownRef, setIsEditing, setActiveRecordId}}
          key={column.id}
          columns={databaseTable.columns}
          isEditing={isEditing && activeColumnId === column.id}
          isActive={isActiveRecord && activeColumnId === column.id}
          cellToLeftIsActive={isActiveRecord && column.id && column.id === databaseTable.columns[_.findIndex(databaseTable.columns, {id: activeColumnId}) + 1]?.id}
          cellBelowIsActive={activeRecordIsBelow && column.id === activeColumnId}
        />
      ))}
      {/* <View style={{verticalAlign: 'middle', paddingLeft: K.spacing, paddingRight: K.spacing}}>
        <Button icon={deleteIcon} dataSet={{conditionalOpacityChild: 1}} style={{backgroundColor: 'none', opacity: 0.7, justifyContent: 'center'}} onPress={() => confirm('Are you sure?') && props.destroyDatabaseRecord({id: databaseRecord.id})}/>
      </View> */}
    </Pressable>
  );
};

DatabaseRecord = React.memo(connect({
  mapDispatch: {
    ..._.pick(resourceActions.databaseRecords, ['updateDatabaseRecord', 'destroyDatabaseRecord']),
  }
})(DatabaseRecord));

var DatabaseTable = ({databaseTableId, databaseTable, databaseRecords, activeRecordId, setActiveRecordId, activeColumnId, setActiveColumnId, ...props}) => {
  var flatListRef = useRef();
  var wrapperRef = useRef();
  var recordRefs = useRef({});
  var cellRefs = useRef({});
  var activeRecord = databaseRecords[activeRecordId];

  var [isEditing, setIsEditing] = useState(false);

  databaseRecords = _.sortBy(databaseRecords, 'recordId');

  var handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      document.activeElement?.blur();

      setTimeout(() => {
        setActiveRecordId(undefined);
        setActiveColumnId(undefined);
        setIsEditing(false);
      });
    }
    else if (event.key === 'Backspace' && !isEditing) {
      if (_.includes(['BODY', 'DIV'], document.activeElement.tagName) && confirm('Are you sure you want to delete the selected row from this table? This cannot be undone.')) {
        var recordIndex = _.findIndex(databaseRecords, {id: activeRecordId});

        if (recordIndex === databaseRecords.length - 1) {
          setActiveRecordId(databaseRecords[recordIndex - 1]?.id);
        }
        else {
          setActiveRecordId(databaseRecords[recordIndex + 1]?.id);
        }

        setIsEditing(false);

        props.destroyDatabaseRecord({id: activeRecordId});
      }
    }

    if ((!isEditing || event.altKey) && activeRecord) { //TODO or whatever the excel/sheets hotkey is
      if (event.key === 'Enter') {
        var activeColumn = _.find(databaseTable.columns, {id: activeColumnId});

        if (activeColumn.type === 'media') {
          cellRefs.current[activeRecordId][activeColumnId].querySelector('input').click();
        }
        else {
          setIsEditing(true);
        }

        event.preventDefault();
      }
      else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        var offset = event.key === 'ArrowDown' ? 1 : -1;
        var recordIndex = _.findIndex(databaseRecords, {id: activeRecordId});
        var databaseRecord = databaseRecords[recordIndex + offset];

        if (databaseRecord) {
          setActiveRecordId(databaseRecord.id);

          event.preventDefault();
          event.stopPropagation();

          setTimeout(() => {
            var recordElement = recordRefs.current[databaseRecord.id];
            var scrollViewElement = flatListRef.current._listRef._scrollRef;
            var scrollViewHeight = scrollViewElement.getBoundingClientRect().height;
            var scrollViewY = scrollViewHeight + scrollViewElement.scrollTop;
            var recordElementY = recordElement.getBoundingClientRect()[offset === 1 ? 'bottom' : 'top'];
            var newScrollTop;

            if (offset === 1) {
              if (recordElementY > scrollViewHeight - 17) {
                newScrollTop = Math.max(0, scrollViewElement.scrollTop + (recordElementY + 17 + scrollViewElement.scrollTop - scrollViewY));
              }
            }
            else {
              if (recordElementY < 111) {
                newScrollTop = recordElementY + scrollViewElement.scrollTop - 111;
              }
            }

            if (newScrollTop !== undefined) {
              scrollViewElement.scrollTo({y: newScrollTop, x: scrollViewElement.scrollLeft, behavior: 'smooth'});
            }
          });
        }
      }
      else if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
        var {columns} = databaseTable;
        var activeColumnIndex = _.findIndex(columns, {id: activeColumnId});

        var offset = event.key === 'ArrowRight' ? 1 : -1;
        var column = databaseTable.columns[activeColumnIndex + offset];

        event.preventDefault();
        event.stopPropagation();

        if (column) {
          setActiveColumnId(column.id);

          setTimeout(() => {
            if (document.activeElement) document.activeElement?.blur();

            var cellElement = cellRefs.current[activeRecordId]?.[column.id];
            var scrollViewElement = flatListRef.current._listRef._scrollRef;
            var scrollViewWidth = wrapperRef.current.getBoundingClientRect().width;
            var scrollViewX = scrollViewWidth + scrollViewElement.scrollLeft;
            var recordElementX = cellElement?.getBoundingClientRect()[offset === 1 ? 'right' : 'left'] - scrollViewElement.getBoundingClientRect().left;
            var newScrollLeft;

            if (offset === 1) {
              if (recordElementX > scrollViewWidth - 17) {
                newScrollLeft = Math.max(0, scrollViewElement.scrollLeft + (recordElementX + 17 + scrollViewElement.scrollLeft - scrollViewX));
              }
            }
            else {
              if (recordElementX < 0) {
                newScrollLeft = recordElementX + scrollViewElement.scrollLeft - 0;
              }
            }

            if (newScrollLeft !== undefined) {
              scrollViewElement.scrollTo({x: newScrollLeft, y: scrollViewElement.scrollTop, behavior: 'smooth'});
            }
          });
        }
      }
    }
  };

  var handleKeyDownRef = useRef();

  handleKeyDownRef.current = handleKeyDown; //HINT trying to avoid triggering rerenders due to prop changes on records

  useKeyDown(handleKeyDown);

  var activeRecordIndex = _.findIndex(databaseRecords, {id: activeRecordId});

  useEffect(async () => {
    props.trackDatabaseRecords({databaseRecords: await api.get('databaseRecords', {})});
  }, [databaseTableId]);

  var createDatabaseColumn = async () => {
    var {columns} = databaseTable;

    props.updateDatabaseTable({id: databaseTable.id, props: {columns: [...columns, {id: uuid(), key: 'column' + (databaseTable.columns.length + 1), type: 'string'}]}});

    setTimeout(() => flatListRef.current._listRef._scrollRef.scrollTo({x: 999999, behavior: 'smooth'}), 100);
  };

  var createDatabaseRecord = async () => {
    props.updateDatabaseTable({id: databaseTable.id, props: {lastRecordId: databaseTable.lastRecordId + 1}});

    var databaseRecord = await api.create('databaseRecord', {databaseTableId: databaseTable.id, recordId: databaseTable.lastRecordId + 1, databaseId: databaseTable.databaseId, props: {}, orgId: databaseTable.orgId});

    props.trackDatabaseRecords({databaseRecords: [databaseRecord]});

    setTimeout(() => flatListRef.current.scrollToEnd(), 100);
  };

  var updateColumn = (key, attribute, value) => {
    var columns = [...databaseTable.columns];
    var columnIndex = _.findIndex(columns, {key});

    columns[columnIndex] = {...columns[columnIndex], [attribute]: value};

    props.updateDatabaseTable({id: databaseTable.id, props: {columns}});
  };

  var deleteColumn = (key) => {
    if (confirm('Are you sure you want to delete this column permanently?')) {
      var columns = _.reject(databaseTable.columns, {key});

      props.updateDatabaseTable({id: databaseTable.id, props: {columns}});
    }
  };

  var deleteActiveDatabaseRecord = () => {
    if (confirm('Are you sure you want to delete the selected row from this table? This cannot be undone.')) {
      props.destroyDatabaseRecord({id: activeRecordId});

      setActiveRecordId(undefined);
      setActiveColumnId(undefined);
    }
  };

  var data = [
    {key: 'header', type: 'header'},
    ..._.map(databaseRecords, databaseRecord => ({type: 'record', key: databaseRecord.id, databaseRecord})),
    {key: 'spacer', type: 'spacer'}
  ];

  return (
    <View ref={wrapperRef} style={{borderLeftWidth: 1, borderColor: 'rgba(0, 0, 0, 0.1)', flex: 1, position: 'relative', backgroundColor: K.colors.gray}}>
      <View style={{position: 'absolute', width: '100%', height: 111, borderBottomWidth: 1, borderColor: 'rgba(0, 0, 0, 0.1)', backgroundColor: 'white'}}/>
      <FlatList
        ref={flatListRef}
        style={{height: '100vh', width: '100%', overflow: 'auto'}}
        data={data}
        stickyHeaderIndices={[0]}
        keyExtractor={(item) => item.key}
        renderItem={({item, index, separators}) => {
          if (item.type === 'header') {
            return (
              <Row style={{position: 'relative'}}>
                <View style={{flexDirection: 'row', position: 'absolute', top: 0, left: 0, zIndex: 100, padding: K.spacing}}>
                  <Pressable onPress={createDatabaseRecord} dataSet={{hoverable: 1}} style={{userSelect: 'none', marginRight: 3, height: 20, paddingHorizontal: K.spacing, justifyContent: 'center', borderRadius: 10, backgroundColor: K.colors.gray}}>
                    <Text style={{opacity: 0.5}}>+ Row</Text>
                  </Pressable>
                  <Pressable onPress={createDatabaseColumn} dataSet={{hoverable: 1}} style={{userSelect: 'none', marginRight: 3, height: 20, paddingHorizontal: K.spacing, justifyContent: 'center', borderRadius: 10, backgroundColor: K.colors.gray}}>
                    <Text style={{opacity: 0.5}}>+ Column</Text>
                  </Pressable>
                  {activeRecord && (
                    <Pressable onPress={deleteActiveDatabaseRecord} dataSet={{hoverable: 1}} style={{marginRight: 3, height: 20, paddingHorizontal: K.spacing, justifyContent: 'center', borderRadius: 10, backgroundColor: K.colors.gray}}>
                      <Text style={{opacity: 0.5}}>Delete Row</Text>
                    </Pressable>
                  )}
                </View>
                <View style={{borderBottomWidth: 1, borderBottomColor: 'rgba(0, 0, 0, 0.1)', width: 60, height: 111, backgroundColor: 'white', justifyContent: 'flex-end', padding: K.spacing}}>
                  <Text>id</Text>
                </View>
                {_.map(databaseTable.columns, (column, index) => (
                  <View dataSet={{conditionalOpacityParent: 1}} key={column.key} style={{width: 150, backgroundColor: 'white', justifyContent: 'flex-end', borderBottomWidth: 1, borderBottomColor: (activeRecordIndex === 0 && column.id === activeColumnId) ? 'black' : 'rgba(0, 0, 0, 0.1)'}}>
                    <View style={{position: 'relative'}}>
                      <PickerInput
                        value={column.type}
                        buttonStyle={{minHeight: 20, backgroundColor: 'transparent', marginBottom: 0, width: 150, color: 'rgba(0, 0, 0, 0.5)'}}
                        onChange={({value}) => updateColumn(column.key, 'type', value)}
                        options={[
                          {value: 'text', title: 'Text'},
                          {value: 'number', title: 'Number'},
                          {value: 'boolean', title: 'Boolean'},
                          {value: 'media', title: 'Media'},
                          {value: 'json', title: 'JSON'},
                        ]}
                      />
                      <Image
                        dataSet={{conditionalOpacityChild: 1}}
                        style={{position: 'absolute', right: 12, pointerEvents: 'none', bottom: 33, width: 10, height: 10, zIndex: 1, opacity: 0.7}}
                        source={downArrow}
                      />
                      <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <TextInput
                          dataSet={{databaseColumnKeyInput: 1}}
                          style={{height: 16, backgroundColor: 'transparent', marginBottom: K.spacing, paddingRight: 6, width: 127}}
                          value={column.key}
                          onChange={({value}) => updateColumn(column.key, 'key', value)}
                        />
                        <Dropdown theme='light' style={{marginBottom: K.spacing}} dropdownStyle={{right: -5, zIndex: 1, top: 20}} iconStyle={{opacity: 0.6}} actions={[
                          {label: 'Delete Column', onPress: () => deleteColumn(column.key)}
                        ]}/>
                      </View>
                    </View>
                  </View>
                ))}
              </Row>
            );
          }
          else if (item.type === 'record') {
            var {databaseRecord} = item;
            var activeRecordIsBelow = databaseRecord.id === databaseRecords[activeRecordIndex - 1]?.id;

            return (
              <DatabaseRecord
                {...{databaseRecord, databaseTable, setActiveRecordId, setActiveColumnId, setIsEditing, recordRefs, cellRefs, handleKeyDownRef, activeRecordIsBelow}}
                key={databaseRecord.id}
                isActiveRecord={databaseRecord.id === activeRecordId}
                activeColumnId={(activeRecordIsBelow || databaseRecord.id === activeRecordId) && activeColumnId}
                isEditing={databaseRecord.id === activeRecordId && activeColumnId && isEditing}
              />
            );
          }
          else if (item.type === 'spacer') {
            return (
              <View style={{height: 100}}/>
            );
          }
        }}
      />
      {/* <View style={{height: 111, borderBottomWidth: 1, zIndex: 1, borderColor: 'rgba(0, 0, 0, 0.1)', justifyContent: 'flex-end'}}>
        <View style={{flexDirection: 'row', zIndex: 1, alignItems: 'flex-end'}}>

        </View>
      </View>
      <ScrollView style={{backgroundColor: K.colors.gray, flex: 1}}>
        <ScrollView horizontal contentContainerStyle={{flexDirection: 'column'}}>

        </ScrollView>
      </ScrollView> */}
    </View>
  );
};

DatabaseTable = connect({
  mapState: (state, ownProps) => {
    return {
      databaseTable: state.resources.databaseTables.byId[ownProps.databaseTableId],
      databaseRecords: _.get(state.resources.databaseRecords, `byFieldKeyIndex.databaseTableId.${ownProps.databaseTableId}`, {})
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.databaseTables, ['updateDatabaseTable', 'destroyDatabaseTable']),
    ..._.pick(resourceActions.databaseRecords, ['trackDatabaseRecords', 'createDatabaseRecord', 'destroyDatabaseRecord']),
  }
})(DatabaseTable);

var DatabasePage = ({databases, databaseTables, match, activeView, setActiveView, ...props}) => {
  var [activeRecordId, setActiveRecordId] = useState();
  var [activeColumnId, setActiveColumnId] = useState();

  var databaseId = parseInt(match.params.databaseId);
  var activeDatabaseTableId = parseInt(match.params.databaseTableId);

  databaseTables = _.filter(databaseTables, {databaseId});

  var database = _.find(databases, {id: databaseId});
  var activeDatabaseTable = _.find(databaseTables, {id: activeDatabaseTableId});

  if (!activeDatabaseTable) return null;

  var databaseTable = _.find(databaseTables, {id: activeDatabaseTableId});

  var createDatabaseTable = async () => {
    var databaseTable = await api.create('databaseTable', {title: 'Untitled Table', databaseId, orgId: activeDatabaseTable.orgId, lastRecordId: 0, columns: [{id: uuid(), key: 'title', type: 'text', title: 'Title'}]});

    await props.trackDatabaseTables({databaseTables: [databaseTable]});

    props.history.push(`/databases/${databaseId}/tables/${databaseTable.id}`);
  };

  var deleteDatabaseTable = async () => {
    if (confirm('Are you sure?')) {
      props.destroyDatabaseTable({id: databaseTable.id});
    }
  };

  return (
    <DocumentTitle title={`${process.env.NODE_ENV === 'development' ? 'DEV ' : ''}${activeDatabaseTable.title} - ${database.title} - Database`}>
      <View style={{flexDirection: 'row', alignItems: 'stretch', flex: 1}}>
        <View style={{paddingRight: 20, marginTop: 110, padding: 20, width: 280, borderTopWidth: 1, borderColor: 'rgba(0, 0, 0, 0.1)'}}>
          <ScrollView style={{flex: 1}}>
            {_.map(databaseTables, (databaseTable, index) => {
              var isActive = databaseTable.id === activeDatabaseTableId;

              return (
                <Link to={`/databases/${database.id}/tables/${databaseTable.id}`} dataSet={{hoverable: 1, conditionalOpacityParent: 1}} key={databaseTable.id} style={{zIndex: 10000 - index, flexDirection: 'row', marginBottom: 3, height: 20, borderRadius: 10, backgroundColor: isActive ? 'black' : K.colors.gray, alignItems: 'center', paddingHorizontal: 12}}>
                  {isActive ? (
                    <TextInput
                      style={{flex: 1, backgroundColor: 'transparent', color: 'white', paddingHorizontal: 0}}
                      value={databaseTable.title}
                      onChange={({value}) => props.updateDatabaseTable({id: databaseTable.id, props: {title: value}})}
                    />
                  ) : (
                    <Text style={{flex: 1}}>{databaseTable.title}</Text>
                  )}
                </Link>
              );
            })}
            <Pressable dataSet={{hoverable: 1}} style={{zIndex: 0, marginBottom: 5, height: 20, borderRadius: 10, justifyContent: 'center', paddingHorizontal: 12, backgroundColor: K.colors.gray}} onPress={createDatabaseTable}>
              <Text style={{opacity: 0.5}}>+ Table</Text>
            </Pressable>
          </ScrollView>
          {activeDatabaseTableId && (
            <View>
              {/* + column
              + record
              delete record */}
            </View>
          )}
        </View>
        {databaseTable && (
          <DatabaseTable
            databaseTableId={activeDatabaseTableId}
            {...{activeRecordId, setActiveRecordId, activeColumnId, setActiveColumnId}}
          />
        )}
        {activeView.data.importPopupIsVisible && (
          <Popup onClose={() => setActiveView({data: {importPopupIsVisible: false}})}>
            <DatabaseRecordImporter
              orgId={props.session.activeOrg.id}
              databaseTables={databaseTables}
              onImport={() => setActiveView({data: {importPopupIsVisible: false}})}
            />
          </Popup>
        )}
      </View>
    </DocumentTitle>
  );
};

var DatabaseRecordImporter = ({orgId, databaseTables, onImport, ...props}) => {
  var [dataJson, setDataJson] = useState();
  var [schemaJson, setSchemaJson] = useState();

  return (
    <View>
      <LabelledView label='Data' styles={{outerView: {marginBottom: K.spacing}}}>
        <CodeInput language='json' onChange={({value}) => setDataJson(value)}/>
      </LabelledView>
      <LabelledView label='Schema' styles={{outerView: {marginBottom: K.spacing}}}>
        <CodeInput language='json' onChange={({value}) => setSchemaJson(value)}/>
      </LabelledView>
      <Button
        label='Import'
        onPress={async () => {
          var recordsData = [];
          var relevantDatabaseTables = [];

          var generateRecords = ({spec, data, parentTemporaryId}) => {
            var {tableId, parentIdColumn, rankColumn, schema} = spec;
            var databaseTable = _.find(databaseTables, {id: tableId});

            if (!_.includes(relevantDatabaseTables, databaseTable)) relevantDatabaseTables.push(databaseTable);

            _.forEach(data, (obj, rank) => {
              databaseTable.lastRecordId += 1;

              var temporaryId = uuid();
              var recordId = databaseTable.lastRecordId;
              var recordData = {tableId, temporaryId, recordId, props: {}};

              if (rankColumn) recordData.props.rank = rank;
              if (parentIdColumn) recordData.props[parentIdColumn] = parentTemporaryId;

              _.forEach(schema, (spec, key) => {
                var {column: columnKey} = spec;
                var value = obj[key];

                if (spec.tableId) {
                  generateRecords({parentTemporaryId: temporaryId, spec, data: value});
                }
                else {
                  recordData.props[columnKey] = value;
                }
              });

              recordsData.push(recordData);
            });
          };

          generateRecords({spec: JSON.parse(schemaJson), data: JSON.parse(dataJson)});

          await props.updateDatabaseTables({updates: _.map(relevantDatabaseTables, databaseTable => ({where: {id: databaseTable.id}, props: {lastRecordId: databaseTable.lastRecordId}}))});

          var databaseRecords = await api.create('databaseRecords', _.map(recordsData, ({props, recordId, tableId}) => ({databaseTableId: tableId, databaseId: _.find(databaseTables, {id: tableId}).databaseId, recordId, props, orgId})));

          var idsMap = {};

          _.forEach(recordsData, (recordData, index) => {
            idsMap[recordData.temporaryId] = databaseRecords[index].recordId;
          });

          var recordUpdatesData = [];

          _.forEach(databaseRecords, databaseRecord => {
            var updatedProps = {};

            var setUpdatedProps = ({spec}) => {
              if (spec.parentIdColumn && spec.tableId === databaseRecord.databaseTableId) {
                updatedProps[spec.parentIdColumn] = idsMap[databaseRecord.props[spec.parentIdColumn]];
              }

              _.forEach(spec.schema, (spec) => {
                if (spec.tableId) {
                  setUpdatedProps({spec});
                }
              });
            };

            setUpdatedProps({spec: JSON.parse(schemaJson)});

            if (_.size(updatedProps) > 0) recordUpdatesData.push({id: databaseRecord.id, props: {...databaseRecord.props, ...updatedProps}});
          });

          if (recordUpdatesData.length > 0) {
            _.forEach(recordUpdatesData, ({id, props}) => {
              var databaseRecord = _.find(databaseRecords, {id});

              databaseRecord.props = props;
            });

            await api.update('databaseRecords', _.map(recordUpdatesData, ({id, props}) => ({where: {id}, props: {props}})));
          }

          props.trackDatabaseRecords({databaseRecords});

          onImport();
        }}
      />
    </View>
  );
};

DatabaseRecordImporter = connect({
  mapDispatch: {
    ..._.pick(resourceActions.databaseRecords, ['trackDatabaseRecords']),
    ..._.pick(resourceActions.databaseTables, ['updateDatabaseTables'])
  }
})(DatabaseRecordImporter);

export default connect({
  mapState: state => {
    return {
      activeView: state.activeView,
      databases: state.resources.databases.byId,
      databaseTables: state.resources.databaseTables.byId
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.databaseTables, ['trackDatabaseTables', 'updateDatabaseTable', 'createDatabaseTable', 'destroyDatabaseTable']),
    ..._.pick(resourceActions.databaseRecords, ['trackDatabaseRecords', 'createDatabaseRecord', 'destroyDatabaseRecord']),
    setActiveView
  }
})(DatabasePage);
