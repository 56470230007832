import { useState, useEffect } from 'react';
import { View, Image, Pressable, ScrollView, ActivityIndicator } from 'react-native';
import { Text, DocumentTitle, Link, Label, Popup, LabelledView, PickerInput, TextInput } from '@symbolic/rn-lib';
import { api } from '@symbolic/lib';
import { connect, setActiveOrg } from '@symbolic/redux';
import { resourceActions } from '~/redux';
import { v4 as uuid } from 'uuid';

import _ from 'lodash';
import K from '~/k';
import Cookies from 'js-cookie';
import homeIcon from '~/assets/home-icon.png';
import databaseIcon from '~/assets/database-icon.png';
import compileJcon from '~/helpers/compileJcon';

const HomePage = ({apps, databases, session, ...props}) => {
  var orgs = _.sortBy(session.orgs, org => org.type === 'personal' ? 0 : 1);

  var [activeView, setActiveView] = useState();
  var [createPopupIsShowing, setCreatePopupIsShowing] = useState(false);
  var [createData, setCreateData] = useState({title: 'Untitled App', orgId: orgs[0].id, platform: 'web'});
  var [isCreatingApp, setIsCreatingApp] = useState(false);

  useEffect(async () => {
    setActiveView((await Cookies.get('homePageActiveView')) || 'apps');
  }, []);

  // useEffect(async () => {
  //   var appBranches = await api.get('appBranches', {where: {orgId: [1, 2, 3, 2743]}});

  //   _.forEach(appBranches, (appBranch, index) => {
  //     if (!appBranch.compiledNodes) {
  //       setTimeout(() => {
  //         var compiledNodes = compileNodes(appBranch.nodes);

  //         api.update('appBranch', {where: {id: appBranch.id}, props: {compiledNodes}});
  //       }, index * 1000);
  //     }
  //   });
  // }, []);

  if (!activeView) return null;

  var createApp = async () => {
    setIsCreatingApp(true);

    try {
      var app = await api.create('app', createData);
      var isWeb = createData.platform === 'web';

      var jxnApp = {
        type: 'app',
        platform: createData.platform,
        rootComponent: 'App',
        framework: createData.platform === 'web' ? 'next' : 'expo',
        dependencies: isWeb ? {
          'react': '^18',
          'react-dom': '^18',
          'next': '13.5.1'
        } : {
          'react': '18.2.0',
          'react-native': '0.72.6',
          'react-dom': '18.2.0'
        }, //HINT css doesn't work with expo snack
        devDependencies: isWeb ? {
          'sass': '^1.69.7'
        } : {
          'expo': '~49.0.15',
          '@expo/webpack-config': '19.0.0',
          'metro-react-native-babel-preset': '0.77.0',
          'react-native-web': '~0.19.6',
        },
        imports: [],
        componentLibraries: ['ui'],
        components: [
          {
            type: 'Component',
            name: 'App',
            expressions: [{type: 'state', var: ['counter', 'setCounter'], initialState: 1}],
            children: [
              {type: 'View', style: {flex: 1, justifyContent: 'center', alignItems: 'center'}, children: [
                {type: 'Button', props: {onPress: '{() => setCounter(counter + 1)}'}, children: [
                  {type: 'Text', props: {text: '{\'Click me: \' + counter}'}, style: {color: 'red'}}
                ]}
              ]}
            ]
          }
        ],
        ...(createData.platform === 'web' ? {
          styleSheet: `html, body, #__next {
  min-height: 100%;
}
html {
  height: 100%;
}
body {
  margin: 0px;
  padding: 0px;
  display: flex;
  overflow-y: auto;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
#__next {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}
* {
  box-sizing: border-box;
  font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}`} : {}),
      };

      var s = 'abcdefghijklmnopqrstuvwxyz0123456789';
      var devUrlSalt = Array(10).join().split(',').map(function() { return s.charAt(Math.floor(Math.random() * s.length)); }).join('');
      var branchUrlSalt = Array(10).join().split(',').map(function() { return s.charAt(Math.floor(Math.random() * s.length)); }).join('');

      var appBranch = await api.create('appBranch', {
        devUrlSalt, branchUrlSalt,
        appId: app.id,
        orgId: app.orgId,
        slug: 'main',
        jxn: JSON.stringify(jxnApp),
        devScript: (await compileJcon(jxnApp, {generateDevScript: true}, {}))['App.js']
      });

      app.appBranchId = appBranch.id;

      await api.update('app', {props: {appBranchId: appBranch.id}, where: {id: app.id}});

      localStorage.setItem('deployCounter', JSON.stringify(0));

      await sessionStore.set('activeAppId', app.id);

      props.trackApps({apps: [app]});
      props.trackAppBranches({appBranches: [appBranch]});

      props.history.push(`/apps/${app.id}`);
    }
    catch (error) {
      console.log(error);

      setIsCreatingApp(false);
    }
  };

  var createDatabase = async () => {
    var database = await api.create('database', createData);
    var databaseTable = await api.create('databaseTable', {title: 'untitledTable', databaseId: database.id, lastRecordId: 1, orgId: database.orgId, columns: [{key: 'title', type: 'text', id: uuid()}]});
    var databaseRecord = await api.create('databaseRecord', {databaseTableId: databaseTable.id, recordId: 1, databaseId: {}, orgId: database.orgId, props: {title: 'Untitled Record'}});

    props.trackDatabases({databases: [database]});
    props.trackDatabaseTables({databaseTables: [databaseTable]});
    props.trackDatabaseRecords({databaseRecords: [databaseRecord]});

    props.history.push(`/databases/${database.id}/tables/${databaseTable.id}`);
  };

  var orgsWithData = _.filter(orgs, org => {
    return _.find(apps, {orgId: org.id}) || _.find(databases, {orgId: org.id});
  });

  return (
    <View style={{height: '100%'}}>
      <DocumentTitle title={`${process.env.NODE_ENV === 'development' ? 'DEV ' : ''}Home Page`}>
        <View style={{flex: 1, position: 'relative', flexDirection: 'row'}}>
          {/* <ScrollView style={{flex: 1}} contentContainerStyle={{paddingTop: 50, paddingBottom: 50, justifyContent: 'center', alignItems: 'center', minHeight: '100%'}}> */}
          <View style={{flex: 1, padding: K.spacing * 6}}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <Text style={{fontSize: 40, fontFamily: 'HelveticaNeue-Light'}}>Scaffolding</Text>
              <Image source={homeIcon} style={{width: 30, height: 30, marginLeft: K.spacing * 2}}/>
            </View>
            <View style={{flex: 1}}/>
            <Label style={{fontSize: 9, letterSpacing: '0.2em', marginBottom: K.spacing, opacity: 1}}>Next-Generation App Development</Label>
            <Label style={{fontSize: 9, letterSpacing: '0.2em', marginBottom: K.spacing}}>by Symbolic Frameworks</Label>
            <Label style={{fontSize: 9, opacity: 0.3, letterSpacing: '0.2em'}}>support@symbolicframeworks.com</Label>
          </View>
          <ScrollView style={{padding: K.spacing * 6, maxWidth: 500, width: 500, background: 'linear-gradient(#D0BCF8, #A9C4FA)'}} contentContainerStyle={{alignItems: 'flex-start'}}>
            <View style={{flexDirection: 'row', marginBottom: K.spacing * 3}}>
              <Pressable style={{alignItems: 'center', marginRight: 3, backgroundColor: 'rgba(0, 0, 0, 0.1)', paddingHorizontal: 12, height: 20, borderRadius: 10, justifyContent: 'center'}} onPress={() => {
                if (!isCreatingApp) {
                  setCreatePopupIsShowing('app');
                  setCreateData({title: 'Untitled App', orgId: orgs[0].id, platform: 'cross-platform'});
                }
                else {
                  alert('Please wait for your app to finish creating.');
                }
              }}>
                {isCreatingApp ? (
                  <View style={{transform: 'scale(0.8)'}}>
                    <ActivityIndicator size='small' color='black'/>
                  </View>
                ) : (
                  <Text>New App</Text>
                )}
              </Pressable>
              {/* <Pressable style={{flexDirection: 'row', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.2)', paddingHorizontal: 12, height: 20, borderRadius: 10, justifyContent: 'center'}} onPress={() => {
                setCreatePopupIsShowing('database');
                setCreateData({title: 'Untitled Database', orgId: orgs[0].id});
              }}>
                <Image source={databaseIcon} style={{width: 12, height: 12, marginRight: 5, marginLeft: -2}}/>
                <Text>New Database</Text>
              </Pressable> */}
            </View>
            {props.databaseTables && _.map(orgsWithData, org => (
              <View style={{marginBottom: K.spacing * 3}}>
                <Text style={{marginBottom: K.spacing, marginLeft: 3, fontWeight: 'bold'}}>{org.type === 'personal' ? 'My Personal Workspace' : org.title}</Text>
                <View style={{}}>
                  <View style={{marginBottom: K.spacing, alignItems: 'flex-start'}}>
                    {_.map(_.filter(apps, {orgId: org.id}), app => (
                      <Link to={`/apps/${app.id}/branches/${localStorage.getItem(`app-${app.id}-active-branch`) || 'main'}`} key={app.id} style={{flexDirection: 'row', alignItems: 'center', marginTop: 3, backgroundColor: 'rgba(0, 0, 0, 0.1)', paddingHorizontal: 12, height: 20, borderRadius: 10, justifyContent: 'center'}}>
                        <Text>{app.title}</Text>
                      </Link>
                    ))}
                  </View>
                  <View style={{alignItems: 'flex-start'}}>
                    {_.map(_.filter(databases, {orgId: org.id}), database => (
                      <Link key={database.id} to={`/databases/${database.id}/tables/${_.find(props.databaseTables, {databaseId: database.id}).id}`} style={{flexDirection: 'row', alignItems: 'center', marginTop: 3, backgroundColor: 'rgba(0, 0, 0, 0.2)', paddingHorizontal: 12, height: 20, borderRadius: 10, justifyContent: 'center'}}>
                        <Image source={databaseIcon} style={{width: 12, height: 12, marginRight: 5, marginLeft: -2}}/>
                        <Text>{database.title}</Text>
                      </Link>
                    ))}
                  </View>
                </View>
              </View>
            ))}
          </ScrollView>
          {createPopupIsShowing && (
            <Popup onClose={() => setCreatePopupIsShowing(false)} windowStyle={{width: 500}}>
              <View style={{padding: K.spacing}}>
                <LabelledView label='Title' styles={{label: {fontSize: 9, letterSpacing: '0.2em', marginBottom: 3}, outerView: {marginBottom: K.spacing * 2}}}>
                  <TextInput
                    autoFocus
                    value={createData.title}
                    onChange={({value}) => setCreateData({...createData, title: value})}
                    style={{width: 'auto', minWidth: 'auto', height: 20, borderRadius: 10, backgroundColor: K.colors.doubleGray}}
                    selectTextOnFocus
                  />
                </LabelledView>
                <LabelledView label='Workspace' styles={{label: {fontSize: 9, letterSpacing: '0.2em'}}}>
                  <PickerInput
                    basic
                    showDownArrow
                    value={orgs[0].id}
                    onChange={({value}) => setCreateData({...createData, orgId: parseInt(value)})}
                    buttonStyle={{width: 'auto', minWidth: 'auto', minHeight: 20, borderRadius: 10, backgroundColor: K.colors.doubleGray}}
                    options={_.map(orgs, org => ({value: org.id, title: org.type === 'personal' ? 'My Personal Workspace' : org.title}))}
                  />
                </LabelledView>
                {createPopupIsShowing === 'app' && (
                  <LabelledView label='Platform' styles={{label: {fontSize: 9, letterSpacing: '0.2em', marginBottom: 3}, outerView: {marginTop: K.spacing * 2}}}>
                    <PickerInput
                      basic
                      showDownArrow
                      value={orgs[0].id}
                      onChange={({value}) => setCreateData({...createData, platform: value})}
                      buttonStyle={{width: 'auto', minWidth: 'auto', minHeight: 20, borderRadius: 10, backgroundColor: K.colors.doubleGray}}
                      options={[
                        {value: 'cross-platform', title: 'Cross-Platform (Web, iOS, Android) - React Native & Expo'},
                        {value: 'web', title: 'Web (SEO Optimized) - React & NextJS'},
                      ]}
                    />
                  </LabelledView>
                )}
                <Pressable style={{alignSelf: 'flex-start', alignItems: 'center', marginRight: 3, marginTop: K.spacing * 2, backgroundColor: 'black', paddingHorizontal: 12, height: 20, borderRadius: 10, justifyContent: 'center'}} onPress={() => {
                  (createPopupIsShowing === 'app' ? createApp : createDatabase)();
                  setCreatePopupIsShowing(false);
                }}>
                  <Text style={{color: 'white'}}>{`Create ${_.startCase(createPopupIsShowing)}`}</Text>
                </Pressable>
              </View>
            </Popup>
          )}
        </View>
      </DocumentTitle>
    </View>
  );
};

export default connect({
  mapState: state => {
    return {
      databaseTables: state.resources.databaseTables.byId,
      databases: state.resources.databases.byId,
      apps: state.resources.apps.byId
    };
  },
  mapDispatch: {
    setActiveOrg,
    ..._.pick(resourceActions.databases, ['trackDatabases', 'createDatabase']),
    ..._.pick(resourceActions.databaseTables, ['trackDatabaseTables']),
    ..._.pick(resourceActions.databaseRecords, ['trackDatabaseRecords']),
    ..._.pick(resourceActions.apps, ['trackApps', 'createApp']),
    ..._.pick(resourceActions.appBranches, ['trackAppBranches'])
  }
})(HomePage);
