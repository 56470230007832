import { useState, useEffect, useRef, useContext } from 'react';

import { ScrollView, Pressable, View } from 'react-native';
import { TextInput, Tooltip, Button } from '@symbolic/rn-lib';
import { ChromePicker } from 'react-color';
import { getComponent } from '~/helpers/getComponentHelpers';
import { Panel } from 'react-resizable-panels';

import K from '~/k';
import mapNodes from '~/helpers/mapNodes';
import jconObjectToJxString from '~/helpers/jconObjectToJxString';
import jxStringToJcon from '~/helpers/jxStringToJcon';
import CodeInput from '~/components/CodeInput';
import ExpandableSection from '~/components/ExpandableSection';
import JconEditor from '~/components/JconEditor/JconEditor';
import LabelWidthContext from '~/contexts/LabelWidthContext';
import getStyleKeyGroups from '~/helpers/getStyleKeyGroups';
import collectionIcon from '~/assets/settings-icon.png';

var styleKeyGroups = getStyleKeyGroups();

var PropertiesPane = ({activeNode, activeNodePath, updateNode, jxnApp, updateJxnApp}) => {
  var labelWidth = useContext(LabelWidthContext);
  var omittedPropKeys = ['props', 'style', 'staticStyle', 'expressions', 'contentContainerStyle', 'id', 'stylesByFormat', 'stylesheet'];

  if (Array.isArray(activeNode.children)) {
    omittedPropKeys.push('children');
  }

  var componentDefinition = getComponent({jxnApp, type: activeNode.type}) || {};

  var activeNodeCategory = _.split(activeNodePath, '.')[0];

  if (_.includes(['tests', 'routes', 'helpers'], activeNodeCategory)) {
    componentDefinition = {...componentDefinition, supportsProps: false, supportsStyle: false};
  }

  var filterSuggestionGroups = (suggestionGroups, value) => {
    var usedKeys = [];

    if (!Array.isArray(value)) value = [value];

    _.forEach(value, subValue => {
      if (typeof(subValue) === 'object') {
        usedKeys.push(..._.keys(subValue));
      }
    });

    return _.filter(_.map(suggestionGroups, suggestionGroup => {
      return {...suggestionGroup, keys: _.filter(suggestionGroup.keys, ({key}) => !_.includes(usedKeys, key))}
    }), suggestionGroup => suggestionGroup.keys.length > 0);
  };

  var propSuggestionGroups = filterSuggestionGroups([
    {title: activeNode.type, keys: _.map(componentDefinition.propTypes, ({name, defaultValue}) => ({key: name, defaultValue}))},
    {title: 'General', keys: [
      {key: 'style', defaultValue: {}},
      {key: 'className', defaultValue: ''},
      {key: 'ref', defaultValue: null}
    ]},
  ], activeNode.props || {});

  return (
    <Panel id='properties-pane' defaultSizePixels={400} minSizePixels={300 + K.spacing * 4} order={3} style={{height: '100%', position: 'relative', borderLeftWidth: 0, borderLeftColor: K.colors.doubleGray}}>
      <LabelWidthContext.Provider value={150 - K.spacing}>
        <ScrollView dataSet={{propertiesPane: 1}} key={activeNode.id} style={{borderTopWidth: 0, borderTopColor: K.colors.grayBorder, height: '100%', zIndex: 1, transform: 'none'}} contentContainerStyle={{position: 'relative', paddingBottom: 100, paddingHorizontal: K.spacing}}>
          <TextInput
            style={{marginLeft: K.spacing * 2, borderRadius: 0, marginTop: 20, marginBottom: 7, height: 40, ...K.fonts.pageHeader, letterSpacing: K.fonts.standard.letterSpacing, backgroundColor: 'transparent', paddingHorizontal: 0}}
            value={_.includes(['components', 'tests'], activeNodeCategory) ? activeNode.name || activeNode.type : activeNode.path}
            onChange={({value}) => {
              var path = 'path';

              if (_.includes(['components', 'tests'], activeNodeCategory)) path = 'name';

              if (value) {
                updateNode([{action: 'set', path, value}], activeNodeCategory);
              }
              else {
                updateNode([{action: 'unset', path}], activeNodeCategory);
              }
            }}
            selectTextOnFocus
          />
          <View>
            <ExpandableSection
              title={'Configuration'}
              fadeInOnLoadDelay={100}
              indicateHasContent={_.size(_.omit(activeNode, [...omittedPropKeys, 'type', 'name']))}
              isExpandedByDefault styles={{outerView: {zIndex: 1000 + 2}}}>
              <JconEditor
                value={_.omit(activeNode, omittedPropKeys)}
                schema={[
                  {type: 'Object', defaultValue: '', suggestionGroups: filterSuggestionGroups([
                    // ...(componentType?.props ? [
                    //   {title: componentType.type, keys: componentType.props}
                    // ] : []),
                    {title: 'General', keys: [
                      {key: 'name'},
                      {key: 'children'},
                      ...(activeNode.type === 'Component' ? [
                        // {key: 'arePropsEqual'},
                        {key: 'provideContexts', defaultValue: []},
                        {key: 'useContexts', defaultValue: []}
                      ] : [
                        {key: 'shouldRender', defaultValue: '{true}'},
                      ])
                    ]}
                  ], _.omit(activeNode, omittedPropKeys)), children: [{type: 'Plain'}, {type: 'Script'}]},
                ]}
                onChange={({value: object}) => {
                  var updates = [];
                  var newNode = {..._.pick(activeNode, omittedPropKeys), ...object};

                  _.forEach(object, (value, key) => {
                    updates.push({path: key, value, action: 'set'});
                  });

                  _.forEach(activeNode, (_value, key) => {
                    if (!Object.hasOwn(newNode, key)) {
                      updates.push({path: key, action: 'unset'});
                    }
                  });

                  updateNode(updates, activeNodeCategory);
                }}
              />
            </ExpandableSection>
            {activeNode.type === 'Component' && (<>
              <ExpandableSection
                title={'Expressions'}
                indicateHasContent={activeNode.expressions?.length > 0}
                isExpandedByDefault
                fadeInOnLoadDelay={200}
                styles={{outerView: {zIndex: 1000 + 1}}}
                getHeaderStyle={({isExpanded}) => ((!isExpanded || (activeNode.expressions?.length || 0) === 0) ? {} : {paddingBottom: 5})}
              >
                <View style={{marginTop: -7}}>
                  <JconEditor
                    value={activeNode.expressions || []}
                    onChange={({value}) => {
                      updateNode([{action: 'set', path: 'expressions', value}], activeNodeCategory);
                    }}
                    schema={[
                      {type: 'Array', typeOptions: [
                        {type: 'Script', defaultValue: '{(() => {\n   console.log(1)\n})()}', color: '#dfa862'},
                        {type: 'var', title: 'Variable', getDefaultValue: oldValue => {
                          if (oldValue?.type === 'state') {
                            return {type: 'var', var: oldValue.var[0], value: oldValue.initialState};
                          }
                          if (oldValue?.type === 'ref') {
                            return {type: 'var', var: oldValue.var, value: oldValue.initialValue};
                          }
                          else {
                            return {type: 'var', var: 'myVar', value: '{1}'};
                          }
                        }, color: '#b5a9dd'},
                        {type: 'state', getDefaultValue: oldValue => {
                          if (oldValue?.type === 'var') {
                            return {type: 'state', var: typeof(oldValue.var) === 'string' ? [oldValue.var, `set${_.upperFirst(oldValue.var)}`] : ['value', 'setValue'], initialState: oldValue.value};
                          }
                          else {
                            return {type: 'state', var: ['value', 'setValue'], initialState: '{1}'};
                          }
                        }, color: '#8b9ccb'},
                        {type: 'ref', color: '#99aede', getDefaultValue: oldValue => {
                          if (oldValue?.type === 'var') {
                            return {type: 'ref', var: oldValue.var, initialValue: oldValue.value};
                          }
                          else {
                            return {type: 'ref', var: 'myRef', initialValue: null};
                          }
                        }},
                        {type: 'effect', defaultValue: {type: 'effect', setup: '{() => {\n  console.log(1);\n}}', dependencies: '{[]}'}, color: '#cda8cf'},
                        // {type: 'records', defaultValue: {type: 'records', var: 'myRecords', value: ''}},
                        // {type: 'loader', defaultValue: {type: 'loader', var: 'myLoader', value: ''}},
                      ], children: [
                        {type: 'ExpressionObject'},
                        {type: 'Script'}
                      ]}
                    ]}
                  />
                </View>
              </ExpandableSection>

              {/* {false && <KeyValuePairsEditor
                collectionKey={activeNode.id + '-expressions'}
                style={{zIndex: 1000 + 1}}
                defaultValue={'{{null}}'}
                title={'Logic'}
                titleTooltip={'State, Custom Javascript, Variables, State, Event Handlers, etc'}
                keyPath={'name'}
                valuePath={'value'}
                array={activeNode.expressions || []}
                databaseTableOptions={_.map(_.sortBy(databaseTables, 'databaseId'), databaseTable => ({value: databaseTable.id, title: `${_.find(databases, {id: databaseTable.databaseId})?.title} > ${databaseTable.title}`}))}
                onChange={(array, changeData = {}) => {
                  var shouldReloadIframe = !(changeData.action === 'update' && _.includes(['handler', 'value'], changeData.expression.type));

                  updateNode([{action: 'set', path: 'expressions', value: array}], activeNodeCategory, {shouldReloadIframe});
                }}
              />} */}
              {/* <ExpandableSection
                title={'Stylesheet'}
                fadeInOnLoadDelay={200}
              >
                <CodeInput
                  {...{labelWidth}}
                  useBorderRadius
                  language='scss'
                  theme='vs-light'
                  value={activeNode.stylesheet || ''}
                  onChange={({value}) => {
                    updateNode([{path: 'stylesheet', value, action: 'set'}], activeNodeCategory);
                  }}
                />
              </ExpandableSection> */}
            </>)}
            {componentDefinition.supportsProps !== false && (<>
              <ExpandableSection
                title={'Props'}
                fadeInOnLoadDelay={200}
                isExpandedByDefault
                styles={{outerView: {zIndex: 1000}}}
                indicateHasContent={_.size(activeNode.props || {}) > 0}
                headerContent={!Array.isArray(activeNode.props || {}) && (
                  <Tooltip text='Use advanced props'>
                    <Button style={{backgroundColor: 'transparent', height: 12}} icon={collectionIcon} iconSize={{width: 12, height: 12}} onPress={() => updateNode([{action: 'set', path: 'props', value: [activeNode.props || {}]}], activeNodeCategory)}/>
                  </Tooltip>
                )}
                getHeaderStyle={({isExpanded}) => (!isExpanded || !Array.isArray(activeNode.props) ? {} : {paddingBottom: (activeNode.props.length || 0) === 0 ? 5 : 0})}
              >
                <JconEditor
                  value={activeNode.props || {}}
                  onChange={({value}) => {
                    updateNode([{action: 'set', path: 'props', value}], activeNodeCategory);
                  }}
                  schema={[
                    {type: 'Array', typeOptions: [
                      {type: 'Object', defaultValue: {}},
                      {type: 'Script', defaultValue: '{}'}
                    ], children: [
                      {type: 'Object', defaultValue: '', suggestionGroups: propSuggestionGroups, children: [{type: 'Plain'}, {type: 'Script'}]},
                      {type: 'Script'}
                    ]},
                    {type: 'Object', defaultValue: '', suggestionGroups: propSuggestionGroups, children: [{type: 'Plain'}, {type: 'Script'}]}
                  ]}
                />
              </ExpandableSection>
              {componentDefinition.supportsStyle !== false && (<>
                <ExpandableSection
                  title={'Style'}
                  isExpandedByDefault
                  fadeInOnLoadDelay={300}
                  styles={{outerView: {zIndex: 1000 - 2}}}
                  getHeaderStyle={({isExpanded}) => (!isExpanded || !Array.isArray(activeNode.style) ? {} : {paddingBottom: (activeNode.style.length || 0) === 0 ? 5 : 0})}
                  indicateHasContent={_.size(activeNode.style || {}) > 0}
                  headerContent={!Array.isArray(activeNode.style || {}) && (
                    <Tooltip text='Use advanced style'>
                      <Button style={{backgroundColor: 'transparent', height: 12}} icon={collectionIcon} iconSize={{width: 12, height: 12}} onPress={() => updateNode([{action: 'set', path: 'style', value: [{style: activeNode.style || {}}]}], activeNodeCategory)}/>
                    </Tooltip>
                  )}
                >
                  <JconEditor
                    value={activeNode.style || {}}
                    onChange={({value}) => {
                      updateNode([{action: 'set', path: 'style', value}], activeNodeCategory);
                    }}
                    schema={[
                      {type: 'Array', typeOptions: [
                        {type: 'Script', defaultValue: '{}'},
                        {type: 'CSSString', defaultValue: '', color: '#b29ea6'},
                        {type: 'selector', title: 'css selector', getDefaultValue: oldValue => ({selector: '&:hover', style: oldValue?.style || {}}), color: '#b98fb7'},
                        {type: 'format', title: 'format', getDefaultValue: oldValue => ({format: 'desktop', style: oldValue?.style || {}}), color: '#9cae89'},
                        {type: 'condition', title: 'condition', getDefaultValue: oldValue => ({condition: '{true}', style: oldValue?.style || {}}), color: '#8b9b92'},
                        {type: 'object', getDefaultValue: oldValue => ({style: oldValue?.style || {}})}
                      ], children: [
                        {type: 'StyleCollection', suggestionGroups: styleKeyGroups, children: [{type: 'Plain'}, {type: 'Script'}]},
                        {type: 'Object', defaultValue: {}, suggestionGroups: styleKeyGroups},
                        {type: 'CSSString'},
                        {type: 'Script'}
                      ]},
                      {type: 'Object', defaultValue: '', suggestionGroups: styleKeyGroups, children: [{type: 'Plain'}, {type: 'Script'}]}
                    ]}
                  />
                </ExpandableSection>
                {/* <KeyValuePairsEditor
                  style={{zIndex: 1000 - 2}}
                  collectionKey={activeNode.id + '-styles'}
                  title={'Style'}
                  object={activeNode.props?.style}
                  suggestionKeyGroups={styleKeyGroups}
                  onChange={object => updateNode([{action: 'set', path: 'props.style', value: object}], activeNodeCategory)}
                /> */}
                {/* {_.map(formats, (format, index) => (
                  <KeyValuePairsEditor
                    suggestionKeyGroups={styleKeyGroups}
                    style={{zIndex: 1000 - (3 + index)}}
                    collectionKey={activeNode.id + '-styleByFormat-' + format.id}
                    title={`${format.title} Styles`}
                    object={activeNode.stylesByFormat?.[format.id]}
                    onChange={object => updateNode([{action: 'set', path: `stylesByFormat.${format.id}`, value: object}], activeNodeCategory)}
                  />
                ))} */}
                {/* {activeNode.type === 'ScrollView' && (
                  <ExpandableSection title={'Scroll Content Style'} isExpandedByDefault styles={{outerView: {zIndex: 1000 - 6}}}>
                    <JconEditor
                      value={activeNode.props?.contentContainerStyle || {}}
                      onChange={({value}) => {
                        updateNode([{action: 'set', path: 'props.contentContainerStyle', value}], activeNodeCategory);
                      }}
                      schema={[
                        {type: 'Array', typeOptions: [
                          {type: 'Object', defaultValue: {}},
                          {type: 'Script', defaultValue: '{}'}
                        ], children: [
                          {type: 'Object', suggestionGroups: styleKeyGroups, children: [{type: 'Plain'}, {type: 'Script'}]},
                          {type: 'Script'}
                        ]},
                        {type: 'Object', defaultValue: '', suggestionGroups: styleKeyGroups, children: [{type: 'Plain'}, {type: 'Script'}]}
                      ]}
                    />
                  </ExpandableSection>
                )} */}
              </>)}
            </>)}
            <ExpandableSection
              title={'Code'}
              fadeInOnLoadDelay={400}
            >
              <CodeInput
                {...{labelWidth}}
                autoheight
                useBorderRadius
                language='javascript'
                theme='vs-light'
                value={jconObjectToJxString(Array.isArray(activeNode.children) ? _.omit(activeNode, ['children']) : activeNode, {shorten: false})}
                onChange={({value}) => {
                  var updatedNode = {...jxStringToJcon(value, {shouldAlert: true}), ...(Array.isArray(activeNode.children) ? _.pick(activeNode, ['children']) : {})};

                  jxnApp = {...jxnApp, components: mapNodes(jxnApp.components, (node, _index, {nodePath}) => {
                    return nodePath === activeNodePath ? updatedNode : node;
                  })};

                  updateJxnApp(jxnApp);
                }}
              />
            </ExpandableSection>
          </View>
        </ScrollView>
      </LabelWidthContext.Provider>
    </Panel>
  );
};

export default PropertiesPane;
