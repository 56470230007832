import { createRef, Component } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { Text } from '@symbolic/rn-lib';
import { pluralize } from 'inflection';

import K from '~/k';

import uploadIconBlack from '~/assets/upload-icon-black.png';

class FileInput extends Component {
  state = {isDragging: false, isDropped: false};

  fileInputRef = createRef();

  drop = createRef();

  componentDidMount() {
    this.drop.current.addEventListener('dragover', this.handleDragOver);
    this.drop.current.addEventListener('drop', this.handleDrop);
  }

  componentWillUnmount() {
    this.drop.current.removeEventListener('dragover', this.handleDragOver);
    this.drop.current.removeEventListener('drop', this.handleDrop);
  }

  handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!this.state.isDragging) {
      this.setState({isDragging: true});
    }
  };

  handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({isDragging: false, isDropped: true});

    const {files} = event.dataTransfer;

    if (files.length > 0 ) {
      this.props.handleFilesPicked(files);
    }
  };

  getLabel = () => {
    const { uploading, uploadingWasSuccessful, uploadingHasFailed} = this.props;

    const messages = {
      idle: 'Drag and Drop or click to browse',
      dragging: 'Drop here to upload.',
      uploading: 'Uploading ...',
      uploadingWasSuccessful: 'File uploaded successfuly!',
      uploadingHasFailed: 'Couldn\'t upload file. Check your internet connection or try again later.'
    };

    const settings = {
      dragging: this.state.isDragging,
      uploading,
      uploadingWasSuccessful,
      uploadingHasFailed
    };

    const key = _.findKey(settings, item => item);

    return key ? messages[key] : messages.idle;
  };

  render() {
    return (
      <View style={{width: '100%'}} ref={this.drop}>
        <TouchableOpacity onPress={() => this.fileInputRef.current.click()} style={{flexDirection: 'row', alignItems: 'center', borderRadius: K.borderRadius, padding: 5, ...this.props.containerStyle}}>
          {this.props.children}
          {/* <Text style={{...K.fonts.label, marginLeft: K.spacing, textAlign: 'center'}}>{this.getLabel()}</Text> */}
          <input accept='image/jpeg, image/png' type='file' onInput={(e) => this.props.handleFilesPicked(e.target.files)} style={{display: 'none'}} ref={this.fileInputRef}/>
          {(!this.props.uploading && !this.props.uploadingWasSuccessful && !this.props.uploadingHasFailed) && <Image source={uploadIconBlack} style={{width: 12, height: 12}}/>}
        </TouchableOpacity>
      </View>
    );
  }
}

export default FileInput;

async function uploadFile({files, api, resource, resourceKey, handleValueChange, trackMedia, session, type}) {
  // setState({uploading: true});

  var file = _.get(files, '0');
  var uploaderId = session.user.id;
  var {id: orgId} = session.activeOrg;

  if (resource && resourceKey) {
    var {id, mediumId} = resource;
  }

  try {
    if (file.size < 5000000) {
      var fileName = _.first(_.split(file.name, '.'));
      var fileExtension = _.last(_.split(file.name, '.'));
      var actionKey = mediumId ? 'update' : 'create';

      var props = {
        fileName,
        fileExtension,
        uploaderId,
        orgId,
        type: type || 'thumbnail',
        hasFile: true,
        [`${resourceKey}Id`]: id
      };

      var dataToSend = {
        create: props,
        update: {
          where: {id: mediumId},
          props
        }
      };

      var medium = await api[actionKey]('medium', dataToSend[actionKey], {files: [file]});

      if (actionKey === 'create') handleValueChange({value: {mediumId: medium.id, url: medium.presignedUrl}});
    }
    else {
      alert('Please choose a file under 5mb');
    }

    setTimeout(async () => {
      // setState({uploading: false, uploadingWasSuccessful: true});
    }, 4000);

  } catch (err) {
    console.error(err);
    // setState({uploading: false, uploadingWasSuccessful: false, uploadingHasFailed: true});
  }
}

export { uploadFile };
