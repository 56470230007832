import { K as LibK } from '@symbolic/rn-lib/dist/styles/index';

var K = {
  ...LibK,
  colors: {
    ...LibK.colors,
    grayBorder: 'rgba(0, 0, 0, 0.1)'
  }
};

export default K;
