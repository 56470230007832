import useEffectUnscoped from '~/helpers/useEffectUnscoped';

var useKeyDown = (handleKeyDown, options) => {
  useEffectUnscoped((depsRef) => {
    var handleKeyDown = (event) => depsRef.current.handleKeyDown(event);

    document.addEventListener('keydown', handleKeyDown, options);

    return () => document.removeEventListener('keydown', handleKeyDown, options);
  }, [], {handleKeyDown});
};

export default useKeyDown;
