import { useRef, useEffect } from 'react';
import _ from 'lodash';

var useEffectUnscoped = (callback, diffArray = [], deps) => {
  var ref = useRef();
  var depsRef = useRef();

  depsRef.current = deps;

  useEffect(() => {
    return () => {
      ref.current?.callbackResult?.();
    };
  }, diffArray);

  if (!ref.current || (diffArray.length && !_.every(diffArray, (item, index) => item === ref.current[index]))) {
    var callbackResult = callback(depsRef);

    ref.current = {callbackResult, diffArray};
  }
};

export default useEffectUnscoped;
