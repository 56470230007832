import React, { useState, useEffect } from 'react';
import jconObjectToJxString from '~/helpers/jconObjectToJxString';
import jxStringToJcon from '~/helpers/jxStringToJcon';

import { Pressable, ScrollView, View, ActivityIndicator, Image } from 'react-native';
import { TextInput, Button, Text, Link, Tooltip } from '@symbolic/rn-lib';
import { api } from '@symbolic/lib';
import { connect } from '@symbolic/redux';
import { setActiveView, resourceActions } from '~/redux';
import { Snack } from 'snack-sdk';

import * as Clipboard from 'expo-clipboard';

import K from '~/k';
import compileJcon from '~/helpers/compileJcon';
import queryString from 'querystring';
import _ from 'lodash';
import Cookies from 'js-cookie';
import lib from '@symbolic/lib';
import usePersistentState from '~/helpers/usePersistentState';
import stackblitzSdk from '@stackblitz/sdk';

import CodeInput from '~/components/CodeInput';
import CommandInput from './CommandInput';
import SettingsPopup from '~/components/SettingsPopup';
import Tree from './Tree';
import PropertiesPane from './PropertiesPane';
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels';
import ExpandableSection from '~/components/ExpandableSection';

import previewIcon from '~/assets/popup-icon-white.png';
import reactIconWhite from '~/assets/react-icon-white.png';
import deployIconWhite from '~/assets/deploy-icon-white.png';
import leftArrowIconWhite from '~/assets/left-arrow-icon-white.png';
import linkIcon from '~/assets/link-icon-white.png';
import leftArrowIcon from '~/assets/left-arrow-black-medium.png';
import createIcon from '~/assets/create-icon.png';
import settingsIconWhite from '~/assets/settings-icon-white.png';
import testsIcon from '~/assets/tests-icon.png';

K.appColor = '#000';

// window.addEventListener('error', event => {
//   console.log(event);
// });

window.onerror = event => {
  if (event.error?.message?.includes('Invalid 3 panel layout')) {
    console.log('caught err');

    event.preventDefault();
  }
};

class AppPage extends React.Component {
  undoQueue = [];
  redoQueue = [];

  constructor(props) {
    super(props);

    this.state = {
      nodes: [],
      search: '',
      isLoading: false,
      isEditingJxn: false,
      deviceSizeMode: 'desktop',
      jxnAppId: 0,
      selectedKeyValuePairs: [],
      activeFile: 'app.jx',
      vmIsLoading: false,
      hasUnsavedJcon: false,
    };

    this.webPreviewRef = {};
    this.commandInputRef = React.createRef();
  }

  async componentDidMount() {
    document.getElementById('root').classList.add('app-page');

    var deviceSizeMode = localStorage.getItem('deviceSizeMode') || 'desktop';
    var liveUpdatesArePaused = JSON.parse(localStorage.getItem('liveUpdatesArePaused') || 'false');

    this.undoQueue = [];
    this.redoQueue = [];

    this.setState({deviceSizeMode, liveUpdatesArePaused});

    var handleKeyDown = (event) => {
      var inputIsFocused = _.includes(['INPUT', 'TEXTAREA', 'SELECT'], document.activeElement.tagName) || document.activeElement.contentEditable === 'true';
      var contentEditable = document.activeElement.contentEditable === 'true';
      var {activeNode} = this;

      if (event.key === 'Escape') {
        if (this.isEditingJxn) {
          this.setIsEditingJxn(false);
        }
        else if (this.state.activeNodePath) {
          setTimeout(() => {
            if (!global.stopPropagation) {
              if (inputIsFocused) {
                document.activeElement.blur();
              }
              else {
                this.setActiveNode({path: null});
              }
            }
          });
        }
      }
      else if (event.key === 'Backspace') {
        setTimeout(() => {
          if (this.state.activeNodePath && (!inputIsFocused || (this.state.commandInputIsFocused && !global.commandInputValue)) && !global.stopPropagation) {
            if (confirm(`Are you sure you want to delete this ${this.activeNode.type ? this.activeNode.type : 'node'}?`)) {
              this.deleteNode({nodePath: this.state.activeNodePath});
            }
          }
        });
      }
      else if (event.keyCode === 187 && lib.event.keyPressed(event, 'ctrlcmd') && event.altKey) {
        this.setCommandInputIsFocused(true);
      }
      else if (event.key === 'd' && lib.event.keyPressed(event, 'ctrlcmd') && this.state.activeNodePath) {
        event.preventDefault();

        this.duplicateNode({nodePath: this.state.activeNodePath});
      }
      else if (event.key === 'j' && lib.event.keyPressed(event, 'ctrlcmd')) { //cmd + j to edit json
        this.setIsEditingJxn(true);
      }
      else if (event.key === 'z' && lib.event.keyPressed(event, 'ctrlcmd')) {
        var handler = () => {
          if (event.shiftKey) {
            this.redo();
          }
          else {
            this.undo();
          }
        };

        if (inputIsFocused) {
          var initialValue = contentEditable ? document.activeElement.innerText : document.activeElement.value;

          setTimeout(() => {
            var updatedValue = contentEditable ? document.activeElement.innerText : document.activeElement.value;

            if (initialValue === updatedValue) {
              handler();
            }
          }, 10);
        }
        else {
          handler();
        }
      }
      else if (event.key === 's' && lib.event.keyPressed(event, 'ctrlcmd')) { //cmd + s to save
        event.preventDefault();

        if (this.isEditingJxn) {
          this.saveJxn();
        }
        else if (inputIsFocused) {
          document.activeElement.blur();

          setTimeout(() => this.updateIframe({force: true}));
        }
        else {
          event.preventDefault();

          this.updateIframe({force: true});
        }
      }
      else if (event.altKey && _.includes(event.key, 'Arrow')) {
        if (activeNode) {
          if (_.includes(['ArrowUp', 'ArrowDown'], event.key)) {
            var flattenNodes = (nodes) => {
              return _.flatMap(nodes, node => [node, ...flattenNodes(node.children || [])]);
            };

            var flatNodes = flattenNodes(this.state.nodes); //TODO
            var flatActiveNodeIndex = _.findIndex(flatNodes, {id: activeNode.id});

            if (event.key === 'ArrowUp' && flatActiveNodeIndex > 0) {
              this.setActiveNodeId(flatNodes[flatActiveNodeIndex - 1].id);
            }
            else if (event.key === 'ArrowDown' && flatActiveNodeIndex < flatNodes.length - 1) {
              this.setActiveNodeId(flatNodes[flatActiveNodeIndex + 1].id);
            }

            event.preventDefault();
          }
          else if (event.key === 'ArrowLeft') {
            var parentNode = this.findNode(node => _.some(node.children, {id: activeNode.id}));

            if (parentNode) {
              this.setActiveNodeId(parentNode.id);
            }

            event.preventDefault();
          }
          else if (event.key === 'ArrowRight') {
            var childNode = activeNode.children?.[0];

            if (childNode) {
              this.setActiveNodeId(childNode.id);
            }

          }
        }
        else {
          this.setActiveNodeId(this.state.nodes[0].id); //TODO
        }

        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown, {capture: true});

    var handleMouseUp = (event) => {
      // if (this.leftPaneRef && (event.target.style.position !== 'fixed' && (!this.leftPaneRef.contains(event.target) || event.target === this.treeScrollViewRef))) {
      //   this.setState({commandInputIsFocused: false, createData: undefined});
      // }

      // setTimeout(() => {
      //   if (document.activeElement && document.activeElement.tagName === 'DIV' && !document.activeElement.contentEditable) {
      //     document.activeElement.blur();
      //   }
      // });
    };

    document.addEventListener('mouseup', handleMouseUp);

    window.addEventListener('message', (event) => {
      // if (event.data?.keyDownEvent) {
      //   handleKeyDown(event.data.keyDownEvent);
      // }
      if (event.data?.devError) {
        this.setState({devError: event.data.devError});
      }
      if (event.data?.pathChanged) {
        this.handleIframeUrlChange(event.data.pathChanged);
      }
      if (event.data?.activeElementTagName) {
        this.iframeActiveElementTagName = event.data.activeElementTagName;
      }
    });

    var isDetectingFocusChange = false;
    var previousTagName = document.activeElement.tagName;

    setInterval(() => {
      if (previousTagName !== 'IFRAME' && document.activeElement.tagName === 'IFRAME' && !isDetectingFocusChange) {
        isDetectingFocusChange = true;

        setTimeout(() => {
          isDetectingFocusChange = false;

          if (this.iframeActiveElementTagName === 'BODY') {
            window.focus();
          }
        }, 100);
      }

      if (previousTagName !== document.activeElement.tagName) {
        previousTagName = document.activeElement.tagName;
      }
    }, 50);

    this.loadApp();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match && prevProps.match.params.appBranchSlug !== this.props.match.params.appBranchSlug) {
      this.loadApp();

      //reload iframe
      this.setState({hideIframe: true}, () => this.setState({hideIframe: false}));
    }
  }

  componentWillUnmount() {
    document.getElementById('root').classList.remove('app-page');
  }

  loadApp = async () => {
    var appId = this.activeAppId;
    var appBranchSlug = this.props.match.params.appBranchSlug || 'main';

    var {data: {appBranch, app}} = await api.request({uri: '/scaffolding/get-app', body: {appId, appBranchSlug, editor: true}});

    try {
      var {data: {deploymentStatus: devDeploymentStatus}} = await api.request({uri: '/scaffolding/get-deployment-status', body: {appBranchId: appBranch.id, environment: 'dev'}});
      var {data: {deploymentStatus: prodDeploymentStatus}} = await api.request({uri: '/scaffolding/get-deployment-status', body: {appBranchId: appBranch.id, environment: 'prod'}});
    }
    catch (error) {
      console.log(error);

      devDeploymentStatus = 'READY';
      prodDeploymentStatus = 'READY';
    }

    sessionStore.set('activeAppId', appId);
    Cookies.set('activeAppId', appId);
    sessionStore.set('activeAppBranchSlug', appBranchSlug);
    Cookies.set('activeAppBranchSlug', appBranchSlug); //related to iframe

    this.appBranch = appBranch;
    this.app = app;

    var {formats} = appBranch;
    var jxnApp = JSON.parse(appBranch.jxn);

    await this.loadJxnApp({jxnApp});

    if (jxnApp.platform === 'cross-platform') {
      setTimeout(() => {
        this.setState({vmIsLoading: false});
      }, 5000);
    }

    this.setState({activeAppId: appId, formats, devDeploymentStatus, prodDeploymentStatus, activeNodePath: JSON.parse(localStorage.getItem('activeNodePathByAppId') || '{}')[this.app.id]});
  };

  loadJxnApp = async ({jxnApp}, callback) => {
    this.setState({jxnApp, files: await compileJcon(jxnApp, {}), isLoading: false, activeNodePath: undefined}, () => {
      callback?.();
    });
  };

  updateJxnApp = async (jxnApp, {oldJxnApp, hitApi = true} = {}) => {
    if (!oldJxnApp) oldJxnApp = _.cloneDeep(this.state.jxnApp);

    //HINT for performance reasons we want to kick of the ui update before calling compileJcon, which is slow due to babel transpilation
    this.setState({jxnApp: {...jxnApp}, jxnAppId: this.state.jxnAppId + 1}, async () => {
      var {devError} = this.state;

      this.setState({devError: null, files: await compileJcon(jxnApp, {})});

      var devScript = (await compileJcon({...jxnApp}, {generateDevScript: true}))['App.js'];

      this.pushToUndoQueue({jxnApp: _.cloneDeep(jxnApp), oldJxnApp});

      if (hitApi) {
        global.sessionToken = await Cookies.get('@sessionToken');

        await api.update('appBranch', {where: {id: this.appBranch.id}, props: {jxn: JSON.stringify(jxnApp), devScript}});
      }

      this.updateIframe({devScript, devError});
    });
  };

  updateIframe = async ({devScript, devError, force = false} = {}) => {
    if (!this.state.liveUpdatesArePaused || force) {
      if (devError || this.state.devError) {
        this.iframeRef.src += '';
      }
      else {
        this.iframeRef?.contentWindow?.postMessage({devScript: devScript || (await compileJcon(this.state.jxnApp, {generateDevScript: true}))['App.js']}, '*'); //HINT hack to break cache
      }
    }
    // if (this.snack) {
    //   this.snack.updateFiles(_.mapValues(_.pick(this.state.files, ['App.js', 'package.json']), content => ({type: 'CODE', contents: content})));
    // }
    // else if (this.vm) {
    //   this.vm.applyFsDiff({
    //     create: _.pick(this.state.files, ['App.js', 'package.json', 'App.module.scss', 'globals.scss']),
    //     destroy: [],
    //   });
    // }
  };

  updateNode = async (updates, nodeCategory, {shouldReloadIframe} = {}) => {
    var oldJxnApp = _.cloneDeep(this.state.jxnApp);
    var {jxnApp} = this.state;
    var found = false;
    var updatedComponentNameData;

    var updateNode = (node, {nodePath}) => {
      if (found) return;

      if (nodePath === this.state.activeNodePath) {
        _.forEach(updates, ({value, path, action}) => {
          if (action === 'set') {
            if (node.type === 'Component' && path === 'name') {
              updatedComponentNameData = {oldName: node.name, newName: value};
            }

            _.set(node, path, value);
          }
          else {
            _.unset(node, path);
          }
        });

        found = true;
      }
      else {
        _.forEach(node.children, (node, index) => updateNode(node, {nodePath: nodePath + '.' + index}));
      }
    };

    _.forEach(jxnApp[nodeCategory], (node, index) => updateNode(node, {nodePath: nodeCategory + '.' + index + ''}));

    if (updatedComponentNameData) {
      var updateComponentNames = (node) => {
        if (node.type === updatedComponentNameData.oldName) {
          node.type = updatedComponentNameData.newName;
        }

        _.forEach(node.children, updateComponentNames);
      };

      _.forEach(jxnApp.components, updateComponentNames);
    }

    this.updateJxnApp(jxnApp, {oldJxnApp, shouldReloadIframe});
  };

  createNodes = async (newNodes, {hitApi = true, shouldSetActiveNode = true, parentNodePath, callback} = {}) => {
    var oldJxnApp = _.cloneDeep(this.state.jxnApp);
    var {jxnApp} = this.state;
    var newNodePath, nodeCategory;

    if (parentNodePath === undefined) {
      nodeCategory = 'components';

      var {parentNodePath, indexInParent} = this.state.createData;
    }

    if (!parentNodePath) {
      nodeCategory = 'components';

      newNodePath = jxnApp[nodeCategory].length + '';

      jxnApp[nodeCategory].push(newNodes[0]);
    }
    else {
      nodeCategory = _.split(parentNodePath, '.')[0];

      var found = false;

      var insertNode = (node, {nodePath}) => {
        if (found) return;

        if (nodePath === parentNodePath) {
          newNodePath = nodePath + '.' + node.children.length;

          if (!node.children) node.children = [];

          if (indexInParent !== undefined) {
            node.children.splice(indexInParent, 0, ..._.cloneDeep(newNodes));
          }
          else {
            node.children.push(..._.cloneDeep(newNodes));
          }

          found = true;
        }
        else {
          _.forEach(node.children, (node, index) => insertNode(node, {nodePath: nodePath + `.${index}`}));
        }
      };

      _.forEach(jxnApp[nodeCategory], (node, index) => insertNode(node, {nodePath: nodeCategory + '.' + index + ''}));
    }

    this.updateJxnApp(jxnApp, {oldJxnApp, hitApi});

    var stateUpdates = {};

    // if (shouldSetActiveNode) {
    //   stateUpdates.activeNodePath = newNodePath;

    //   this.setActiveNode({path: newNodePath, shouldSetState: false});
    // }

    // if (indexInParent !== undefined || shouldSetActiveNode) {
    //   stateUpdates.createData = {...this.state.createData};

    //   if (indexInParent !== undefined) stateUpdates.createData.indexInParent = indexInParent + newNodes.length;
    //   if (shouldSetActiveNode) stateUpdates.createData.parentNodePath = newNodePath; //TODO
    // }

    this.setState(stateUpdates);

    if (callback) callback();
  };

  deleteNode = async ({nodePath: deletedNodePath}) => {
    var oldJxnApp = _.cloneDeep(this.state.jxnApp);
    var {jxnApp} = this.state;
    var found = false;

    var nodeCategory = _.split(deletedNodePath, '.')[0];

    var deleteNode = (node, parentNodes, {index, nodePath}) => {
      if (found) return;

      if (nodePath === deletedNodePath) {
        parentNodes.splice(index, 1);

        found = true;
      }
      else {
        var nodeParentNodes = node.children;

        _.forEach(nodeParentNodes, (node, index) => deleteNode(node, nodeParentNodes, {nodePath: nodePath + '.' + index, index}));
      }
    };

    _.forEach(jxnApp[nodeCategory], (node, index) => deleteNode(node, jxnApp[nodeCategory], {nodePath: nodeCategory + '.' + index + '', index}));

    this.updateJxnApp(jxnApp, {oldJxnApp});

    this.setActiveNode({path: null});
  };

  duplicateNode = async ({nodePath}) => {
    var parentNodePath = nodePath.includes('.') ? nodePath.split('.').slice(0, -1).join('.') : null;

    var node = _.cloneDeep(this.findNode({path: nodePath}));

    if (node.type === 'Component') node.name = 'Untitled';

    await this.createNodes([node], {parentNodePath, shouldSetActiveNode: true});
  };

  componentizeNode = async ({nodePath: componentizeNodePath}) => {
    var nodeToComponentize = _.cloneDeep(this.findNode({path: componentizeNodePath}));
    var oldJxnApp = _.cloneDeep(this.state.jxnApp);
    var {jxnApp} = this.state;
    var found = false;

    var deleteNode = (node, parentNodes, {index, nodePath}) => {
      if (found) return;

      if (nodePath === componentizeNodePath) {
        parentNodes[index] = {type: 'Untitled'};

        found = true;
      }
      else {
        var nodeParentNodes = node.children;

        _.forEach(nodeParentNodes, (node, index) => deleteNode(node, nodeParentNodes, {nodePath: nodePath + '.' + index, index}));
      }
    };

    _.forEach(jxnApp.components, (node, index) => deleteNode(node, jxnApp.components, {nodePath: index + '', index}));

    jxnApp.components.push({
      type: 'Component',
      name: 'Untitled',
      children: [
        nodeToComponentize
      ]
    });

    this.updateJxnApp(jxnApp, {oldJxnApp});

    //TODO select new node - select title
    // this.setActiveNode({path: null});
  };

  getUntitledComponentName = () => {

  };

  findNode = ({path}) => {
    var foundNode;
    var found = false;

    var searchForNode = (node, {nodePath}) => {
      if (found) return;

      if (path === nodePath) {
        foundNode = node;
        found = true;
      }
      else {
        _.forEach(node.children, (node, index) => searchForNode(node, {nodePath: nodePath + '.' + index}));
      }
    };

    _.forEach(['components', 'tests', 'routes', 'helpers'], (nodeType) => {
      _.forEach(this.state.jxnApp[nodeType], (node, index) => searchForNode(node, {nodePath: nodeType + '.' + index + ''}));
    });

    return foundNode;
  };

  setIsEditingJxn = (isEditingJxn) => {
    if (isEditingJxn) {
      this.setState({viewingCode: true, activeFile: 'app.jx', jxnAppString: jconObjectToJxString(this.state.jxnApp, {shorten: true})});
      this.setActiveNode({path: null});
    }
    else {
      if (!this.state.hasUnsavedJcon || confirm('Are you sure you want to leave without saving your changes? Press cancel and then type Cmd/Ctrl + S to save.')) {
        this.setState({viewingCode: false});
      }
    }
  };

  handleJxnChange = async ({value}) => {
    if (this.state.jxnApp !== value) {
      if (!this.state.hasUnsavedJcon) this.setState({hasUnsavedJcon: true});

      this.state.jxnAppString = value; //eslint-disable-line
      //HINT intentionally not triggering render since it's unnecessary
    }
  };

  saveJxn = async () => {
    try {
      var {jxnApp} = this.state;
      var newJxnApp = jxStringToJcon(this.state.jxnAppString);

      this.pushToUndoQueue({jxn: newJxnApp, oldNodes: jxnApp});

      jxnApp = newJxnApp;

      await api.update('appBranch', {where: {id: this.appBranch.id}, props: {jxn: JSON.stringify(jxnApp), devScript: (await compileJcon(jxnApp, {generateDevScript: true}))['App.js']}});

      var depsChanged = !_.isEqual(_.pick(jxnApp, ['dependencies', 'devDependencies']), _.pick(this.state.jxnApp, ['dependencies', 'devDependencies']));

      this.loadJxnApp({jxnApp: {...jxnApp}}, () => this.updateIframe());

      if (this.state.devError) {
        this.iframeRef.src += '';
      }

      this.setState({hasUnsavedJcon: false, devError: null, jsonError: undefined, jxnAppString: jconObjectToJxString(jxnApp, {shorten: true}), jxnAppId: this.state.jxnAppId + 1, ...(depsChanged ? {vmIsLoading: false} : {})});

      // if (depsChanged) {
      //   setTimeout(() => {
      //     this.generateVm();
      //   });
      // }
    }
    catch (error) {
      console.error(error);
      this.setState({jsonError: error});
    }
  };

  pushToUndoQueue = (update) => {
    this.undoQueue.push(update);

    this.redoQueue = [];
  };

  undo = async () => {
    if (this.undoQueue.length > 0) {
      var update = this.undoQueue.pop();
      var jxnApp = update.oldJxnApp;

      this.redoQueue.push(update);

      this.setState({jxnApp: {...jxnApp}}, () => this.updateIframe());

      var devScript = (await compileJcon({...jxnApp}, {generateDevScript: true}))['App.js'];

      api.update('appBranch', {where: {id: this.appBranch.id}, props: {jxn: JSON.stringify(jxnApp), devScript}});
    }
  };

  redo = async () => {
    if (this.redoQueue.length > 0) {
      var update = this.redoQueue.pop();
      var jxnApp = update.jxnApp;

      this.undoQueue.push(update);

      this.setState({jxnApp: {...jxnApp}}, () => this.updateIframe());

      var devScript = (await compileJcon({...jxnApp}, {generateDevScript: true}))['App.js'];

      api.update('appBranch', {where: {id: this.appBranch.id}, props: {jxn: JSON.stringify(jxnApp), devScript}});
    }
  };

  // search = async (input) => {
  //   var mode = _.split(input, ' ')[1].toLowerCase();

  //   this.setState({isSearching: true});

  //   input = input.replace(`ai ${mode} `, '');

  //   if (mode === 'create') {
  //     var {aiOngoingPrompt = ''} = this.state;

  //     this.setState({isCreatingNodes: true});

  //     try {
  //       var {data: {nodes}} = await api.request({uri: '/scaffolding/ai/create-nodes', body: {input: /*aiOngoingPrompt + */input}});
  //     }
  //     catch (err) {
  //       console.error(err);
  //     }

  //     this.setState({isCreatingNodes: false});

  //     this.pushToUndoQueue({oldJxnApp: _.cloneDeep(this.state.jxnApp), nodes: _.cloneDeep(nodes)});

  //     this.setState({aiOldJxn: this.state.jxnApp, aiOngoingPrompt: aiOngoingPrompt + input + JSON.stringify(nodes)});

  //     this.createNodes(nodes, {shouldSetActiveNode: false});
  //   }
  //   else if (mode === 'edit') {
  //     var {nodes} = await api.request({uri: '/scaffolding/ai/edit-nodes', body: {input: aiOngoingPrompt + input}});

  //     this.updateNodes(nodes, {hitApi: false});
  //   }

  //   this.setState({isSearching: false});
  // };

  handlePathsChanged = ({changedPaths, collapsedNodePathsByAppId: oldCollapsedNodePathsByAppId}) => {
    var activeNodePathByAppId = JSON.parse(localStorage.getItem('activeNodePathByAppId') || '{}');
    var collapsedNodePathsByAppId;

    var newActiveNodePath = changedPaths[activeNodePathByAppId[this.app.id]];

    if (newActiveNodePath) {
      activeNodePathByAppId[this.app.id] = newActiveNodePath;

      localStorage.setItem('activeNodePathByAppId', JSON.stringify(activeNodePathByAppId));

      this.setState({activeNodePath: newActiveNodePath});
    }

    collapsedNodePathsByAppId = _.cloneDeep(oldCollapsedNodePathsByAppId);

    _.forEach(oldCollapsedNodePathsByAppId[this.app.id], (isCollapsed, oldNodePath) => {
      var nodePath = changedPaths[oldNodePath];

      if (nodePath) {
        collapsedNodePathsByAppId[this.app.id][nodePath] = isCollapsed;

        delete collapsedNodePathsByAppId[this.app.id][oldNodePath];
      }
    });

    return {
      collapsedNodePathsByAppId
    };
  };

  setActiveNodeId = nodeId => this.setActiveNode({id: nodeId});

  setActiveNode = ({path, shouldSetState = true}) => {
    localStorage.setItem('activeNodePathByAppId', JSON.stringify({...JSON.parse(localStorage.getItem('activeNodePathByAppId') || '{}'), [this.app.id]: path}));

    if (shouldSetState) this.setState({activeNodePath: path, createData: {...this.state.createData, parentNodePath: path, indexInParent: undefined}});
  };

  async generateVm() {
    if (this.state.jxnApp.platform === 'web') {
      //KEEP LINKS
      //https://stackblitz.com/edit/sdk-vm-js?file=index.js,index.html
      //https://developer.stackblitz.com/platform/api/javascript-sdk-vm
      const project = {
        title: this.app.title || '',
        description: 'Created using https://scaffolding.app',
        template: 'node',
        files: this.state.files,
        settings: {
          compile: { clearConsole: false },
        }
      };

      this.vm = await stackblitzSdk.embedProject('web-preview', project, {
        openFile: 'index.js',
        view: 'preview',
        hideNavigation: true,
        hideExplorer: true,
        forceEmbedLayout: true
      });

      this.iframeRef = document.getElementById('web-preview');

      this.iframeRef.style.height = '100%';
      this.iframeRef.style.width = '100%';

      setTimeout(async () => {
        this.setState({vmIsLoading: false});
      }, 11000);
    }
    else {
      this.webPreviewRef.current = this.iframeRef?.contentWindow ?? null;

      const snack = new Snack({
        webPreviewRef: this.webPreviewRef,
        files: _.mapValues(this.state.files, (contents) => ({contents, type: 'CODE'})),
        dependencies: _.mapValues(_.omit({
          ...this.state.jxnApp.dependencies, ...this.state.jxnApp.devDependencies
        }, ['react', 'expo', 'react-native', 'react-native-web', 'react-dom', '@babel/core']), (version) => ({version})),
        online: true
      });

      this.snack = snack;

      const { url } = await snack.getStateAsync();

      console.log(url);

      // this.iframeRef.src = webPreviewURL;

      // setTimeout(() => {
      //   // console.log(this.iframeRef.contentWindow.document.body.innerHTML);
      //   this.setState({vmIsLoading: false});
      // }, 1000);
    }
  }

  getIframeRef = async (ref) => {
    if (ref) {
      this.iframeRef = ref;

      this.iframeRef.src = this.getIframeSrc();
      // if (this.state.jxnApp.platform === 'web') {
      //   if (!this.vm) {
      //     this.generateVm();
      //   }
      // }
      // else {
      //   if (!this.snack) {
      //     this.generateVm();
      //   }
      // }
    }
  };

  handleIframeUrlChange = (path, {updateIframe = false} = {}) => {
    if (!path) path = '/';

    if (path !== this.lastPath) {
      this.lastPath = path;

      var params = new URLSearchParams(window.location.search);

      path === '/' ? params.delete('path') : params.set('path', path);

      window.history.replaceState({}, '', `${window.location.pathname}${path === '/' ? '' : `?${params}`}`);

      if (updateIframe) {
        this.iframeRef.src = this.getIframeSrc();
      }

      this.forceUpdate();
    }
  };

  getIframePath = () => {
    var queryParams = queryString.parse(`${window.location.href.split('?')[1]}`);

    return queryParams.path || '';
  };

  getIframeOrigin = ({environment = 'dev'}) => {
    //HINT && false is easy to switch to true to view dev/prod deployment
    if (process.env.NODE_ENV === 'development' && true) {
      if (this.state.jxnApp.platform === 'web') return 'http://localhost:3000'; //eslint-disable-line
      if (this.state.jxnApp.platform === 'cross-platform') return 'http://localhost:19007'; //eslint-disable-line
    }

    return `https://${this.appBranch[`${environment}Hostname`]}`;
  };

  getIframeSrc = ({environment} = {}) => {
    return this.getIframeOrigin({environment}) + this.getIframePath();
  };

  setCommandInputIsFocused = (commandInputIsFocused) => {
    this.setState({commandInputIsFocused});

    setTimeout(() => {
      this.commandInputRef.current?.focus();
    }, 10);
  };

  get activeAppId() {
    return parseInt(this.props.match.params.appId);
  }

  get isEditingJxn() {
    return this.state.activeFile === 'app.jx' && this.state.viewingCode;
  }

  get activeNode() {
    if (this.state.jxnApp) {
      var node = this.findNode({path: this.state.activeNodePath});

      return node;
    }
    else {
      return undefined;
    }
  }

  render() {
    if (this.state.isLoading || !this.state.jxnApp) return null;

    var {databases, databaseTables} = this.props;
    var {jxnApp, formats, activeNodePath} = this.state;
    var {setActiveNode, activeNode} = this;
    var {activeAppId} = this.state;

    var activeApp = _.find(this.props.apps, {id: activeAppId});
    var activeNodeCategory = activeNodePath?.split('.')[0];

    if (!activeApp) return null;

    // if (activeNode) {
    //   var activeNodeType = _.find(componentTypes, {type: activeNode.type});
    // }

    var headerContent = (
      <View style={{...(!this.state.viewingCode ? {marginBottom: 25, marginTop: 9} : {}), flexDirection: 'row', filter: 'invert(100%)', paddingVertical: 3, paddingHorizontal: K.spacing, borderRadius: 100}}>
        {this.state.viewingCode ? (<>
          <Button style={{...K.button, position: 'relative', top: -8, left: -5 - K.spacing, marginLeft: K.margin, backgroundColor: 'transparent'}} dataSet={{growOnHover: 1}} icon={leftArrowIconWhite} onPress={() => this.setIsEditingJxn(false)}/>
        </>) : (<>
          <Tooltip dataSet={{growOnHover: 1}} left text='Settings'>
            <Button style={{...K.button, backgroundColor: 'transparent'}} icon={settingsIconWhite} onPress={() => this.setState({settingsAreVisible: true})}/>
          </Tooltip>
          <Tooltip dataSet={{growOnHover: 1}} text='View & edit source code' left>
            <Button style={{...K.button, marginLeft: 3, backgroundColor: 'transparent'}} icon={reactIconWhite} onPress={() => this.setIsEditingJxn(true)}/>
          </Tooltip>
          <Tooltip dataSet={{growOnHover: 1}} text='Tests' left>
            <Button
              style={{...K.button, filter: 'invert(100%)', marginLeft: 3, backgroundColor: 'transparent'}}
              icon={testsIcon}
            />
          </Tooltip>
          <View style={{flex: 1}} />
          {this.state.prodDeploymentStatus !== 'NOT_DEPLOYED' && (
            <Tooltip dataSet={{growOnHover: 1}} text='Copy live deployment link'>
              <Pressable
                onPress={() => {
                  Clipboard.setStringAsync(this.getIframeSrc({environment: 'prod'}));

                  this.setState({justCopiedLiveDeploymentLink: true});

                  setTimeout(() => this.setState({justCopiedLiveDeploymentLink: false}), 1000);
                }}
                style={{...K.button, display: 'flex', marginLeft: K.margin, backgroundColor: 'transparent'}}
              >
                <Image style={{width: 16, height: 16}} source={this.state.justCopiedLiveDeploymentLink ? require('~/assets/check-icon-white.png') : linkIcon}/>
              </Pressable>
            </Tooltip>
          )}
          <DeployButton
            environment={'prod'}
            initialDeploymentStatus={this.state.prodDeploymentStatus}
            appBranch={this.appBranch}
            files={this.state.files}
            onDeploySuccess={() => this.setState({prodDeploymentStatus: 'READY', hideIframe: true}, () => this.setState({hideIframe: false}))}
          />
        </>)}
      </View>
    );

    var borderRadius = (this.state.deviceSizeMode === 'mobile' ? 40 : 10);

    if (this.state.viewingCode) {
      var files = this.state.files;
    }

    document.title = 'Scaffolding - ' + activeApp.title;

    return (<>
      <View style={{flex: 1, height: '100%', backgroundColor: '#fff'}}>
        {this.state.viewingCode && (
          <View style={{paddingTop: K.spacing * 2, width: '100%', height: '100%', position: 'absolute', zIndex: 300, backgroundColor: '#ffffff', flexDirection: 'row'}}>
            <View style={{marginLeft: K.spacing * 2}}>
              {headerContent}
              {_.map([{title: 'JX source file (editable)', fileNames: ['app.jx']}, {title: 'generated files (read-only)', fileNames: ['app.jcon', ..._.keys(files)]}], ({title, fileNames}, index) => (
                <View key={index}>
                  <Text style={{opacity: 0.5, marginLeft: 12, marginVertical: 5, ...(index > 0 ? {marginTop: 10} : {})}}>{title}</Text>
                  {_.map(fileNames, (fileName, index) => (
                    <Pressable
                      style={{borderRadius: 10, height: 20, flexDirection: 'row', width: 200, backgroundColor: this.state.activeFile === fileName ? K.appColor : K.colors.gray, marginBottom: 3, paddingHorizontal: 12, alignItems: 'center'}}
                      onPress={() => this.setState({activeFile: fileName})}
                      key={index}
                    >
                      <Text style={{color: this.state.activeFile === fileName ? 'white' : 'black', flex: 1}}>{fileName}</Text>
                      {this.state.hasUnsavedJcon && fileName === 'app.jx' && (
                        <View style={{width: 10, height: 10, backgroundColor: '#ADC5D6', borderRadius: 10}}/>
                      )}
                    </Pressable>
                  ))}
                </View>
              ))}
            </View>
            <View style={{flex: 1, padding: K.spacing * 2, paddingTop: 0}} dataSet={{overflowAutoCodeInput: 1}}>
              <CodeInput
                extraPadding
                lineNumbers
                language={this.state.activeFile.includes('.scss') ? 'sass' : 'javascript'}
                value={this.state.activeFile === 'app.jx' ? this.state.jxnAppString : (this.state.activeFile === 'app.jcon' ? JSON.stringify(this.state.jxnApp, null, 2) : files[this.state.activeFile])}
                style={{height: '100%'}}
                autoFocus
                key={this.state.activeFile}
                onInput={this.state.activeFile === 'app.jx' ? this.handleJxnChange : undefined}
              />
            </View>
          </View>
        )}
        <PanelGroup direction="horizontal" autoSaveId="AppPage">
          <Panel id='left' defaultSizePixels={300} minSizePixels={200} order={1} style={{display: 'flex', flexDirection: 'column', zIndex: 1, height: '100%'}} ref={ref => this.leftPaneRef = ref}>
            <PanelGroup direction="vertical" autoSaveId="AppPageLeftPane">
              <Panel id='leftScrollView' defaultSize={50} order={1}>
                <ScrollView ref={ref => this.treeScrollViewRef = ref} onClick={(event) => {
                  // this.setActiveNode({path: null});
                }} style={{height: '100%', overflow: 'auto', borderTopWidth: 0, borderTopColor: 'rgba(0, 0, 0, 0.1)'}} contentContainerStyle={{paddingVertical: 30, paddingTop: 0, paddingLeft: K.spacing * 2 - 5}}>
                  <Tooltip dataSet={{growOnHover: 1}} left text='Back to home page' style={{position: 'fixed', top: 25, left: 7, opacity: 1}}>
                    <Link to='/' style={{...K.button, backgroundColor: 'transparent', alignItems: 'center'}}>
                      <Image source={leftArrowIcon} nativeID={'HeaderContentHomeButton'} style={{width: 12, height: 12}}/>
                    </Link>
                  </Tooltip>
                  <TextInput
                    style={{marginLeft: K.spacing * 3 - 7, borderRadius: 0, marginTop: 20, marginBottom: 20, height: 40, ...K.fonts.pageHeader, letterSpacing: K.fonts.standard.letterSpacing, backgroundColor: 'transparent', paddingHorizontal: 0}}
                    value={this.props.apps[this.app.id].title}
                    onChange={({value}) => this.props.updateApp({id: this.app.id, props: {title: value}})}
                  />
                  <View style={{marginLeft: -12}}>
                    {_.map(['components', 'tests', 'routes', 'helpers'], nodeCategory => {
                      var nodeCategoryNodes = _.get(jxnApp, nodeCategory, []);

                      if (nodeCategoryNodes.length > 0) {
                        return (
                          <ExpandableSection
                            title={nodeCategory}
                            key={nodeCategory}
                            isExpandedByDefault
                            styles={{outerView: {zIndex: 1000 + 2}, innerView: {}}}
                            caretPosition='left'
                          >
                            <Tree
                              nodeCategory={nodeCategory}
                              {...{jxnApp, activeAppId, activeNode, setActiveNode, activeNodePath, iframePath: this.getIframePath()}}
                              {..._.pick(this, ['duplicateNode', 'deleteNode', 'componentizeNode', 'handlePathsChanged', 'updateJxnApp', 'setCommandInputIsFocused'])}
                              {..._.pick(this.state, ['jxnAppId', 'createData', 'commandInputIsFocused'])}
                              setCreateData={(createData) => this.setState({createData: {...this.state.createData, ...createData}})}
                            />
                          </ExpandableSection>
                        );
                      }
                    })}
                  </View>
                </ScrollView>
              </Panel>
              {this.state.commandInputIsFocused && (
                <Panel id='leftCommandInput' defaultSize={50} order={2} style={{boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)', position: 'relative'}}>
                  <ResizeHandle horizontal style={{borderWidth: 0, borderTopWidth: 1, borderStyle: 'solid', borderTopColor: 'transparent', transition: 'border-top-color 0.6s'}}/>
                  <CommandInput
                    borderRadius={borderRadius - 1}
                    jxnApp={this.state.jxnApp}
                    activeNode={activeNode}
                    isCreatingNodes={this.state.isCreatingNodes}
                    createNodes={this.createNodes}
                    search={this.search}
                    createData={this.state.createData}
                    commandInputRef={this.commandInputRef}
                    commandInputIsFocused={this.state.commandInputIsFocused}
                    parentNodePath={this.state.activeNodePath}
                    setCreateData={(createData) => this.setState({createData: {...this.state.createData, ...createData}})}
                    setCommandInputIsFocused={(commandInputIsFocused) => this.setState({commandInputIsFocused, createData: commandInputIsFocused ? {parentNodePath: this.state.activeNodePath, nodeType: 'View'} : undefined})}
                  />
                </Panel>
              )}
            </PanelGroup>
          </Panel>
          <ResizeHandle style={{borderWidth: 0, borderLeftWidth: 1, borderStyle: 'solid', borderLeftColor: 'transparent', transition: 'border-left-color 0.6s'}}/>
          <Panel id='preview' order={2} minSizePixels={100} style={{zIndex: 1, backgroundColor: '#f5f5f5', display: 'flex', flexDirection: 'column', paddingTop: K.spacing, paddingBottom: K.spacing * 2, paddingLeft: K.spacing * 2 - 15, paddingRight: activeNode ? (K.spacing * 2 - 14) : K.spacing * 2}}>
            {!this.state.viewingCode && headerContent}
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
              <View style={{borderWidth: 0, ...K.shadow, borderColor: 'rgba(0, 0, 0, 0.1)', position: 'relative', borderRadius, flex: 1, width: '100%', height: '100%', ...(this.state.deviceSizeMode === 'desktop' ? {maxWidth: 900, maxHeight: 650} : {}), ...(this.state.deviceSizeMode === 'mobile' ? {maxWidth: 320, maxHeight: 700} : {})}}>
                <View style={{backgroundColor: this.appBranch.slug === 'main' && _.filter(this.props.appBranches, {appId: activeApp.id}).length > 1 ? '#6f3535' : '#000', justifyContent: 'flex-end', paddingVertical: borderRadius > 10 ? 5 : 5, paddingLeft: K.spacing, paddingRight: K.spacing, minHeight: 40 + (borderRadius - 20), borderTopLeftRadius: borderRadius - 1, borderTopRightRadius: borderRadius - 1, flexDirection: 'row', alignItems: 'center'}}>
                  <Tooltip text='Reload App' left>
                    <Pressable dataSet={{growOnHover: 1}} onPress={() => {
                      this.iframeRef.src = this.getIframeSrc();
                    }} style={{...K.button, marginRight: 7, backgroundColor: 'transparent'}}>
                      <Image style={{width: 16, height: 16}} source={require('~/assets/reload-icon-white.png')}/>
                    </Pressable>
                  </Tooltip>
                  <View left style={{flexDirection: 'row', alignItems: 'center', flexGrow: 1}} dataSet={{hoverable: 1}}>
                    <TextInput
                      style={{minWidth: 40, height: 20, backgroundColor: 'rgba(255, 255, 255, 0.2)', borderRadius: 10, marginRight: K.spacing, paddingHorizontal: 12, flex: 1, color: 'white', marginLeft: 2, position: 'relative', fontSize: 13}}
                      value={this.getIframePath()}
                      placeholder='/'
                      onChange={({value}) => this.handleIframeUrlChange(value, {updateIframe: true})}
                      selectTextOnFocus
                      autoWidth
                    />
                  </View>
                  <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Tooltip text='Open development preview in new tab' dataSet={{growOnHover: 1}}>
                      <a href={this.getIframeSrc()} target='blank' style={{...K.button, marginRight: 0, marginLeft: 2, display: 'flex', backgroundColor: 'transparent'}}>
                        <Image style={{width: 16, height: 16}} source={previewIcon}/>
                      </a>
                    </Tooltip>
                    <Tooltip text={this.state.liveUpdatesArePaused ? 'Resume live updates' : 'Pause live updates'}>
                      <Button
                        icon={!this.state.liveUpdatesArePaused ? require('~/assets/pause-icon-white.png') : require('~/assets/play-icon-white.png')}
                        style={{...K.button, marginRight: -5, marginLeft: 1, backgroundColor: 'transparent'}}
                        onPress={() => {
                          this.setState({liveUpdatesArePaused: !this.state.liveUpdatesArePaused});

                          localStorage.setItem('liveUpdatesArePaused', JSON.stringify(!this.state.liveUpdatesArePaused));
                        }}
                      />
                    </Tooltip>
                    <DeviceSizeModePicker
                      value={this.state.deviceSizeMode}
                      onChange={({value}) => this.setState({deviceSizeMode: value})}
                    />
                  </View>
                </View>
                <View style={{flex: 1, ...(this.state.vmIsLoading ? {justifyContent: 'center', alignItems: 'center'} : {}), backgroundColor: 'white', borderBottomLeftRadius: borderRadius, borderBottomRightRadius: borderRadius, overflow: 'hidden'}}>
                  {this.state.vmIsLoading && (<>
                    <View style={{height: 60}}/>
                    <ActivityIndicator size='small' color='black'/>
                    <Timer style={{marginTop: 25, letterSpacing: '0.08em', fontSize: 10, textAlign: 'center', lineHeight: 15, whiteSpace: 'pre-line'}} getText={(counter) => `PREPARING\nVIRTUAL MACHINE\n${counter - 1}s`}/>
                  </>)}
                  <View style={{height: '100%'}}>
                    <iframe
                      ref={this.getIframeRef}
                      style={{width: '100%', height: '100%', backgroundColor: 'white'}}
                    />
                  </View>
                </View>
                <View style={{position: 'fixed', bottom: K.spacing * 2, left: K.spacing * 2, zIndex: 100, borderTopWidth: 0, borderColor: 'rgba(0, 0, 0, 0.075)'}}>
                  <View style={{position: 'relative', flexDirection: 'row', alignItems: 'center'}} dataSet={{growOnHover: 1}}>
                    {activeNodeCategory === 'components' && (
                      <Button
                        icon={createIcon}
                        style={{borderRadius: 20, transition: 'transform 0.5s', transform: [{rotate: this.state.commandInputIsFocused ? '45deg' : '0deg'}], width: 40, height: 40, ...(this.state.commandInputIsFocused ? {backgroundColor: 'white', filter: 'invert(100%)'} : {backgroundImage: 'linear-gradient(to right, #CEBCF8, #AAC4FA)'})}} iconSize={{width: 16, height: 16}}
                        onMouseDown={() => {
                          if (this.state.commandInputIsFocused) {
                            this.setState({commandInputIsFocused: false});

                            this.commandInputRef.current.blur();
                          }
                          else {
                            this.setState({commandInputIsFocused: true});

                            setTimeout(() => this.commandInputRef.current.focus(), 10);
                          }
                        }}
                      />
                    )}
                  </View>
                </View>
              </View>
            </View>
            {/* <View style={{zIndex: -1, height: 65, marginTop: K.spacing, alignItems: 'center', justifyContent: 'center'}}>

            </View> */}
          </Panel>
          {activeNode && (<>
            <ResizeHandle style={{borderWidth: 0, borderRightWidth: 1, borderStyle: 'solid', borderRightColor: 'transparent', transition: 'border-right-color 0.6s'}}/>
            <PropertiesPane
              {...{jxnApp, activeNodePath, activeNode, formats, setActiveNode, databases, databaseTables}}
              {..._.pick(this, ['updateNodes', 'updateNode'])}
              key={this.state.activeNodePath}
              updateJxnApp={this.updateJxnApp}
              deviceSizeMode={this.state.deviceSizeMode}
            />
          </>)}
        </PanelGroup>
      </View>
      {this.state.settingsAreVisible && (
        <SettingsPopup
          {...this.props}
          onClose={() => this.setState({settingsAreVisible: false})}
          app={this.app}
          appBranch={this.appBranch}
        >
          <View style={{filter: 'invert(100%)'}}>
            <DeployButton
              initialDeploymentStatus={this.state.devDeploymentStatus}
              appBranch={this.appBranch}
              files={this.state.files}
              environment={'dev'}
              onDeploySuccess={() => {
                this.iframeRef.src = this.getIframeSrc({environment: 'dev'});

                this.setState({devDeploymentStatus: 'READY'});
              }}
            />
          </View>
        </SettingsPopup>
      )}
    </>);
  }
}

var Timer = ({style, getText}) => {
  var [counter, setCounter] = useState(0);
  var updateCounterRef = React.useRef();

  updateCounterRef.current = () => setCounter(counter + 1);

  React.useEffect(() => {
    var interval = setInterval(() => {
      updateCounterRef.current();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Text style={{...style, opacity: counter > 1 ? 0.6 : 0, transition: 'opacity 0.5s'}}>{getText(counter - 1)}</Text>
  );
};

AppPage = connect({
  mapState: state => {
    return {
      activeView: state.activeView,
      databases: state.resources.databases.byId,
      databaseTables: state.resources.databaseTables.byId,
      apps: state.resources.apps.byId,
      appBranches: state.resources.appBranches.byId || {}
    };
  },
  mapDispatch: {
    setActiveView,
    ..._.pick(resourceActions.apps, ['updateApp', 'updateApps', 'destroyApp']),
    ..._.pick(resourceActions.appBranches, ['updateAppBranch', 'updateAppBranches', 'trackAppBranches']),
  }
})(AppPage);

function DeployButton({appBranch, files, environment, onDeploySuccess, initialDeploymentStatus}) {
  var [isDeploying, setIsDeploying] = useState(false);
  var [counter, setCounter] = usePersistentState(0, 'deployCounter');
  var [deploymentStatus, setDeploymentStatus] = useState(initialDeploymentStatus);

  var isDeployingRef = React.useRef();
  var counterRef = React.useRef();
  var deploymentStatusRef = React.useRef();

  isDeployingRef.current = isDeploying;
  counterRef.current = counter;
  deploymentStatusRef.current = deploymentStatus;

  useEffect(async () => {
    if (deploymentStatus === 'BUILDING' || deploymentStatus === 'QUEUED') {
      setDeploymentStatus(deploymentStatus);
      setIsDeploying(2);
    }
  });

  useEffect(() => {
    if (initialDeploymentStatus !== deploymentStatusRef.current && (initialDeploymentStatus === 'BUILDING' || initialDeploymentStatus === 'QUEUED')) {
      setCounter(0);
      setDeploymentStatus(initialDeploymentStatus);
      setIsDeploying(2);
    }
  }, [initialDeploymentStatus]);

  useEffect(() => {
    if (isDeploying === 2) {
      var interval = setInterval(async () => {
        if (isDeployingRef.current) {
          if (isDeploying === 2) {
            if (deploymentStatusRef.current === 'BUILDING') {
              setCounter(counterRef.current + 1);
            }

            var {data: {deploymentStatus}} = await api.request({uri: '/scaffolding/get-deployment-status', body: {appBranchId: appBranch.id, environment}});

            setDeploymentStatus(deploymentStatus);

            if (deploymentStatus !== 'BUILDING' && deploymentStatus != 'QUEUED') {
              clearInterval(interval);

              if (deploymentStatus === 'READY') {
                setTimeout(() => {
                  onDeploySuccess({deploymentStatus});
                  setIsDeploying(0);
                }, 2000);
              }
              else {
                alert('Something went wrong');
                setIsDeploying(0);
              }
            }
          }
        }
        else {
          setCounter(0);

          clearInterval(interval);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isDeploying]);

  var deploy = async () => {
    setIsDeploying(1);

    try {
      await api.request({uri: '/scaffolding/deploy', body: {appBranchId: appBranch.id, files, environment}});

      setDeploymentStatus('');
      setCounter(0);
      setIsDeploying(2);
    }
    catch (err) {
      console.error(err);

      setIsDeploying(0);
    }
  };

  return (
    <Tooltip text={isDeploying ? 'Deploying (takes ~60s)' : `Deploy ${environment === 'prod' ? 'to production' : 'dev environment'} (takes ~60s)`} placement='bottom'>
      <View dataSet={{growOnHover: 1}} style={{flexDirection: 'row', height: K.inputHeight, alignItems: 'center', ...(isDeploying ? {paddingLeft: 5, marginRight: K.margin} : {marginLeft: 0})}}>
        {isDeploying ? (<>
          <ActivityIndicator size='small' color='white' style={{transform: 'scale(0.65)'}}/>
          <Text style={{color: 'white', opacity: 0.5, marginLeft: 5, marginRight: isDeploying === 2 ? 10 : 0}}>{isDeploying === 2 ? (deploymentStatus === 'BUILDING' ? `BUILD ${counter}s` : deploymentStatus) : ''}</Text>
        </>) : (
          <Button
            style={{backgroundColor: 'transparent', opacity: environment === 'prod' ? 1 : 0.2}}
            icon={deployIconWhite}
            onPress={deploy}
          />
        )}
      </View>
    </Tooltip>
  );
}

export default AppPage;

var ResizeHandle = ({style, horizontal}) => {
  return (
    <PanelResizeHandle style={{display: 'flex', alignItems: 'center', justifyContent: 'center', ...(horizontal ? {paddingTop: 5, paddingBottom: 2, flexDirection: 'column', position: 'absolute', top: 0, left: 0, width: '100%'} : {paddingLeft: 5, paddingRight: 5, backgroundColor: K.colors.gray}), zIndex: 200, ...style}}>
      <View style={{...(horizontal ? {width: 10, height: 1, borderTopWidth: 1, marginBottom: 2} : {width: 1, height: 10, marginRight: 2, borderLeftWidth: 1}), borderColor: 'rgba(0, 0, 0, 0.2)'}}/>
      <View style={{...(horizontal ? {width: 10, height: 1, borderTopWidth: 1} : {width: 1, height: 10, borderLeftWidth: 1}), borderColor: 'rgba(0, 0, 0, 0.2 )'}}/>
    </PanelResizeHandle>
  );
};

var DeviceSizeModePicker = ({value, onChange}) => {
  return (
    <View dataSet={{deviceSizeModePicker: 1}} style={{alignItems: 'flex-end'}}>
      <View style={{flexDirection: 'row'}}>
        {_.map(_.sortBy([
          {deviceSizeMode: 'desktop', icon: require('~/assets/desktop-icon-white.png')},
          {deviceSizeMode: 'mobile', icon: require('~/assets/mobile-icon-white.png')},
          {deviceSizeMode: 'responsive', icon: require('~/assets/responsive-icon-white.png')}
        ], ({deviceSizeMode}) => deviceSizeMode === value ? 1 : 0), ({icon, deviceSizeMode}) => (
          <Tooltip text={`${_.startCase(deviceSizeMode)} Viewport`} key={deviceSizeMode}>
            <Button
              dataSet={{growOnHover: 1}}
              onPress={() => {
                localStorage.setItem('deviceSizeMode', deviceSizeMode);

                onChange({value: deviceSizeMode});
              }}
              style={{width: 23, height: 24, borderRadius: 100, marginRight: 7, marginLeft: 8, backgroundColor: 'transparent'}}
              icon={icon}
            />
          </Tooltip>
        ))}
      </View>
    </View>
  );
};
