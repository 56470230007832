import React, { useState, Fragment } from 'react';

import { View, ScrollView, Pressable } from 'react-native';
import { TextInput, Button, Popup, PickerInput, LabelledView, Text } from '@symbolic/rn-lib';
import { api } from '@symbolic/lib';

import K from '~/k';
import compileJcon from '~/helpers/compileJcon';
import jconObjectToJxString from '~/helpers/jconObjectToJxString';
import jxStringToJcon from '~/helpers/jxStringToJcon';
import * as diff3 from 'node-diff3';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import CodeInput from '~/components/CodeInput';

export default function SettingsPopup({
  onClose, session,
  database, databaseTables, databaseRecords, updateDatabases, updateDatabaseTables, updateDatabaseRecords,
  appBranch, app, appBranches, trackAppBranches, updateAppBranch, updateApps, updateAppBranches,
  ...props
}) {
  var [newAppBranchSlug, setNewAppBranchSlug] = useState('');
  var [mergeAppBranchIds, setMergeAppBranchIds] = useState({});
  var [mergeData, setMergeData] = useState();
  var [mergeViewMode, setMergeViewMode] = useState('Editor');

  if (app) var relevantAppBranches = _.filter(appBranches, {appId: app.id});

  var activeAppBranch = appBranch;

  return (<>
    <Popup {...{onClose}}>
      {app && (<>
        {/* <TextInput
          label='Name'
          grayLabelledView
          value={appBranch.slug || ''}
          onChange={({value}) => {
            updateAppBranch({id: appBranch.id, props: {
              slug: value,
              subdomain: `${value}-${appBranch.branchUrlSalt}.${app.slug}${window.location.origin.includes('localhost') ? '-dev' : ''}`,
              devHostname: false//depends if has deployment or not - autocreate new deployment on create if so
            }});
          }}
        /> */}
        {/* <TextInput
          label='Custom Domain'
          grayLabelledView
          value={app.customDomain || ''}
          onChange={({value}) => {
            updateApp({id: app.id, props: {customDomain: value}});
          }}
        /> */}
        <View style={{marginBottom: K.spacing}}>
          <View style={{flexDirection: 'row', marginBottom: K.margin, backgroundColor: K.colors.gray, borderRadius: K.borderRadius}}>
            <TextInput placeholder='Branch name' style={{flex: 1}} onChange={({value}) => setNewAppBranchSlug(value)}/>
            <Button label='New Branch' onPress={async () => {
              if (newAppBranchSlug) {
                appBranch = await api.get('appBranch', {where: {id: appBranch.id}});

                var s = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
                var branchUrlSalt = Array(10).join().split(',').map(function() { return s.charAt(Math.floor(Math.random() * s.length)); }).join('');
                var subdomain = `${newAppBranchSlug}-${appBranch.branchUrlSalt}.${app.slug}${window.location.origin.includes('localhost') ? '-dev' : ''}`;

                var newAppBranch = await api.create('appBranch', {
                  ..._.omit(appBranch, ['id', 'created', 'lastUpdated', 'deleted', 'slug']),
                  branchUrlSalt,
                  subdomain,
                  files: await compileJcon(JSON.parse(appBranch.jxn), {}),
                  slug: newAppBranchSlug,
                  originalJxn: _.find(relevantAppBranches, {slug: 'main'}).jxn,
                  devHostname: `${subdomain}-dev.scaffolding.site`
                });

                trackAppBranches({appBranches: [newAppBranch]});

                setTimeout(() => {
                  history.push(`/apps/${app.id}/branches/${newAppBranch.slug}`);
                });
              }
              else {
                alert('Please name your branch');
              }
            }}/>
          </View>
          {_.map(relevantAppBranches, appBranch => (
            <View key={appBranch.id} style={{flexDirection: 'row', backgroundColor: K.colors.gray, borderRadius: K.borderRadius, marginBottom: K.margin}}>
              <TextInput value={appBranch.slug} style={{flex: 1}} onChange={async ({value}) => {
                if (appBranch.id === activeAppBranch.id) {
                  await api.update('appBranch', {where: {id: appBranch.id}, props: {slug: value}});

                  window.location.href = `/apps/${app.id}/branches/${value}`;
                }
                else {
                  updateAppBranch({id: appBranch.id, props: {slug: value}});
                }
              }}/>
              <Button onPress={() => {
                localStorage.setItem(`app-${app.id}-active-branch`, appBranch.slug);

                window.location.href = `/apps/${app.id}/branches/${appBranch.slug}`;
              }}>
                <Text>{'>'}</Text>
              </Button>
            </View>
          ))}
          <View style={{marginTop: K.spacing - K.margin}}>
            <View style={{flexDirection: 'row', backgroundColor: K.colors.gray, alignItems: 'center', borderRadius: K.borderRadius}}>
              {_.map(['source', 'target'], key => (
                <Fragment key={key}>
                  <PickerInput
                    style={{flex: 1}}
                    value={mergeAppBranchIds[key]}
                    options={[{title: key, value: key}, ..._.map(relevantAppBranches, appBranch => ({title: appBranch.slug, value: appBranch.id}))]}
                    onChange={({value}) => setMergeAppBranchIds({...mergeAppBranchIds, [key]: parseInt(value)})}
                  />
                  {key === 'source' && (
                    <Text>{'=>'}</Text>
                  )}
                </Fragment>
              ))}
            </View>
            <Button label='review merge' style={{marginTop: K.margin}} onPress={async () => {
              var appBranches = await api.get('appBranches', {where: {id: [mergeAppBranchIds.source, mergeAppBranchIds.target]}});
              var sourceBranch = _.find(appBranches, {id: mergeAppBranchIds.source});
              var targetBranch = _.find(appBranches, {id: mergeAppBranchIds.target});
              var trueSourceBranch = sourceBranch;

              if (sourceBranch.id < targetBranch.id) {
                [sourceBranch, targetBranch] = [targetBranch, sourceBranch];
              }

              if (sourceBranch.id === targetBranch.id) {
                alert('Please select different branches');
              }
              else {
                var sourceJxnApp = jconObjectToJxString(JSON.parse(sourceBranch.jxn));
                var targetJxnApp = jconObjectToJxString(JSON.parse(targetBranch.jxn));
                var originalJxnApp = jconObjectToJxString(JSON.parse(sourceBranch.originalJxn));
                var mergeData = diff3.merge(sourceJxnApp.split('\n'), originalJxnApp.split('\n'), targetJxnApp.split('\n'));
                var diffData = diff3.diffComm(originalJxnApp.split('\n'), sourceJxnApp.split('\n'));
                var resultDiffData = diff3.diffComm(targetJxnApp.split('\n'), mergeData.result);

                setMergeData({
                  sourceJxnApp, targetJxnApp, originalJxnApp,
                  trueSourceBranch,
                  mergedJxnApp: mergeData.result.join('\n'),
                  diffData,
                  resultDiffData //TODO
                });

                onClose();
              }
            }}/>
          </View>
        </View>
      </>)}
      {(app || database) && (
        <LabelledView label='Transfer Workspaces' gray>
          <PickerInput
            showDownArrow
            value={app ? app.orgId : database.orgId}
            options={_.map(session.orgs, org => ({
              title: org.type === 'personal' ? 'My Private Workspace' : org.title,
              value: org.id
            }))}
            onChange={async ({value}) => {
              var orgId = parseInt(value);
              var key = app ? 'app' : 'database';
              var idKey = app ? 'appId' : 'databaseId';
              var id = app ? app.id : database.id;

              await api.request({uri: `/scaffolding/transfer-${key}`, body: {[idKey]: id, orgId}});

              if (app) {
                updateApps({updates: [{where: {id: app.id}, props: {orgId}}]});
                updateAppBranches({updates: _.map(_.filter(appBranches, {appId: app.id}), appBranch => ({where: {id: appBranch.id}, props: {orgId}}))});
              }
              else {
                updateDatabases({updates: [{where: {id: database.id}, props: {orgId}}]});
                updateDatabaseTables({updates: _.map(_.filter(databaseTables, {databaseId: database.id}), databaseTable => ({where: {id: databaseTable.id}, props: {orgId}}))});
                updateDatabaseRecords({updates: _.map(_.filter(databaseRecords, {databaseId: database.id}), databaseRecord => ({where: {id: databaseRecord.id}, props: {orgId}}))});
              }
            }}
          />
        </LabelledView>
      )}
      <Button label='Delete' onPress={async () => {
        if (confirm('Are you sure?')) {
          await props[app ? 'destroyApp' : 'destroyDatabase']({id: (app || database).id});

          window.location.href = '/';
        }
      }}/>
      {props.children}
    </Popup>
    {mergeData && ReactDOM.createPortal((
      <View style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1000, backgroundColor: 'white'}}>
        <View style={{height: '100%', padding: K.spacing * 2}}>
          <View style={{marginBottom: K.spacing, flexDirection: 'row'}}>
            {_.map(['Editor', 'Diff', 'Result + Diff'], key => (
              <Button
                key={key}
                label={key}
                textStyle={{height: 'auto'}}
                style={{marginRight: K.margin}}
                mode={mergeViewMode === key ? 'dark' : 'light'}
                onPress={() => setMergeViewMode(key)}
              />
            ))}
          </View>
          <View style={{flex: 1, border: '1px solid rgba(0, 0, 0, 0.1)', borderRadius: K.borderRadius}}>
            {mergeViewMode === 'Editor' && (
              <CodeInput
                height='100%'
                theme='vs-light'
                language='javascript'
                value={mergeData.mergedJxnApp}
                onChange={({value}) => {
                  setMergeData({...mergeData, mergedJxnApp: value});
                }}
              />
            )}
            {(mergeViewMode === 'Diff' || mergeViewMode === 'Result + Diff') && (
              <ScrollView style={{overflow: 'auto'}} contentContainerStyle={{flexDirection: 'row'}}>
                {(() => {
                  var target = []; //typically main
                  var result = []; //merged result

                  _.forEach(mergeData[mergeViewMode === 'Diff' ? 'diffData' : 'resultDiffData'], ({common, buffer1, buffer2}) => {
                    //common: present in both - buffer 1: removed - buffer 2: inserted

                    if (common) {
                      var chunk = {lines: common, type: 'plain', lineCount: common.length};

                      target.push(chunk);
                      result.push(chunk);
                    }
                    else {
                      var lineCount = Math.max(buffer1.length, buffer2.length);

                      target.push({lines: buffer1, type: 'removed', lineCount});
                      result.push({lines: buffer2, type: 'inserted', lineCount});
                    }
                  });

                  return _.map([
                    {chunks: target},
                    {chunks: result}
                  ], ({chunks}, index) => (
                    <View key={index}>
                      {_.map(chunks, (chunk, index) => (
                        <MergeChunk chunk={chunk} key={index} />
                      ))}
                    </View>
                  ));
                })()}
              </ScrollView>
            )}
          </View>
          <View>
            <View style={{flexDirection: 'row', marginTop: K.spacing * 2, justifyContent: 'flex-end'}}>
              <Button
                label='Cancel'
                onPress={() => setMergeData(undefined)}
              />
              <Button
                style={{marginLeft: K.margin}}
                dark
                label='Accept'
                onPress={() => {
                  setTimeout(async () => { //so input change has time to update state
                    var jxnApp = jxStringToJcon(mergeData.mergedJxnApp);

                    await updateAppBranch({id: mergeAppBranchIds.target, props: {jxn: JSON.stringify(jxnApp), originalJxn: mergeData.trueSourceBranch.jxn}});

                    window.location.href = `/apps/${app.id}`; //TODO use target branch
                  });
                }}
              />
            </View>
          </View>
        </View>
      </View>
    ), document.getElementById('root'))}
  </>);
}

var MergeChunk = ({chunk}) => {
  var [isExpanded, setIsExpanded] = useState();
  var lineHeight = 18;

  var ChunkComponent = View;
  var {lines, lineCount} = chunk;

  if (chunk.type === 'plain' && lineCount > 11) {
    ChunkComponent = Pressable;

    var onPress = () => setIsExpanded(!isExpanded);

    if (!isExpanded) {
      lineCount = 11;

      var lines = [
        ..._.take(lines, 5),
        '...',
        ..._.takeRight(lines, 5)
      ];
    }
  }

  return (
    <ChunkComponent onPress={onPress} style={{height: lineCount * lineHeight, backgroundColor: chunk.type === 'plain' ? '#f5f5f5' : '#e5e5e5'}}>
      {_.map(lines, line => (
        <Text key={line} style={{height: lineHeight, fontFamily: 'Menlo, Monaco, "Courier New", monospace', backgroundColor: {removed: '#FFEBE9', inserted: '#E6FFEC'}[chunk.type]}}>{line}</Text>
      ))}
    </ChunkComponent>
  );
};
