import { useState, useRef } from 'react';
import { View, Text, Pressable } from 'react-native';
import { PickerInput } from '@symbolic/rn-lib';
import _ from 'lodash';
import K from '~/k';

var labelStyle = {color: 'rgba(0, 0, 0, 0.4)', textTransform: 'uppercase', fontSize: 10, letterSpacing: '0.1em', fontWeight: 500, fontFamily: 'Work Sans'};

var JconTypePicker = ({value, onChange, typeOptions}) => {
  var pressableRef = useRef();
  var [isActive, setIsActive] = useState(false);

  typeOptions = _.map(typeOptions, typeOption => {
    if (typeOption.type === 'Script') return {...typeOption, color: '#dfa862'};
    if (typeOption.type === 'Object' || typeOption.type === 'object') return {...typeOption, color: '#99aede'};

    return typeOption;
  });

  var activeTypeOption = _.find(typeOptions, {type: value}) || {title: '?', color: 'gray'};

  return (
    <View style={{position: 'relative', zIndex: 1, userSelect: 'none'}}>
      <Pressable ref={pressableRef} style={{paddingHorizontal: K.spacing, paddingVertical: 4}} onMouseDown={() => isActive && setTimeout(() => pressableRef.current.blur())} onFocus={() => setIsActive(true)} onBlur={() => setIsActive(false)}>
        <Text style={{...labelStyle, color: activeTypeOption.color}}>{activeTypeOption.title || _.startCase(activeTypeOption.type)}</Text>
      </Pressable>
      {isActive && (
        <View style={{position: 'absolute', top: '100%', marginTop: 0, right: 0, paddingVertical: 7, borderRadius: 5, alignItems: 'flex-end', zIndex: 1, backgroundColor: 'white', ...(isActive ? {boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)'} : {})}}>
          {_.map(typeOptions, ({type, color, title}) => value !== type && (
            <Pressable dataSet={{hoverable: 1}} onMouseDown={() => onChange({value: type})} style={{paddingHorizontal: K.spacing, paddingVertical: 3}}>
              <Text style={{...labelStyle, color: color, whiteSpace: 'nowrap'}}>{title || _.startCase(type)}</Text>
            </Pressable>
          ))}
        </View>
      )}
    </View>
  );
};

export default JconTypePicker;
