import React, { useState, useContext, useEffect, useRef } from 'react';
import { View, Pressable } from 'react-native';
import { TextInput, Text, Tooltip } from '@symbolic/rn-lib';
import jconObjectToJxString from '~/helpers/jconObjectToJxString';
import jxStringToJcon from '~/helpers/jxStringToJcon';

import Suggestions from '~/components/Suggestions';
import LabelWidthContext from '~/contexts/LabelWidthContext';
import CodeInput from '~/components/CodeInput';
import K from '~/k';
import lib from '@symbolic/lib';
import ContextMenu from '~/components/ContextMenu';
import getIsCode from '~/helpers/getIsCode';
import useKeyDown from '~/helpers/useKeyDown';

import esprima from 'esprima-next';

var inputStyle = {minHeight: 20, height: 20, borderRadius: 10, backgroundColor: K.colors.gray, paddingLeft: 12};

var getType = (value, {useFriendlyLanguage} = {}) => {
  if (value === null) return 'null';
  if (typeof(value) === 'object') return Array.isArray(value) ? 'array' : 'object';
  if (typeof(value) === 'number') return 'number';
  if (typeof(value) === 'boolean') return 'boolean';
  if (typeof(value) === 'string' && _.startsWith(value, '{') && _.endsWith(value, '}')) return 'script';
  if (Array.isArray(value)) return 'array';
  if (typeof(value) === 'object') return 'object';
  if (typeof(value) === 'string') return useFriendlyLanguage ? 'text' : 'string';
};

var parseValue = (value, {parsePartialNumbers = false} = {}) => {
  var isScript = _.startsWith(value, '{') && _.endsWith(value, '}');

  try {
    var tempValue = value;

    if (isScript) tempValue = tempValue.replace(/^\{/, '').replace(/\}$/, '');

    tempValue = `(${tempValue})`;

    var parsedScript = esprima.parseScript(tempValue);

    //HINT mainly checking that it's not a function that evaluates to a value or that it's a multiline script
    if (
      parsedScript.type === 'Program'
      && parsedScript.body.length === 1
      && parsedScript.body[0].type === 'ExpressionStatement'
      && _.includes(['ObjectExpression', 'ArrayExpression', 'Literal'], parsedScript.body[0].expression.type)
    ) {
      var isPartialNumber = !isNaN(value) && typeof(value) === 'string' && value !== parseFloat(value) + '';

      if (!isPartialNumber || parsePartialNumbers) { //catch values like 00 parsing to 0 when you're trying to type 500 - only do if numeric stringified i
        value = jxStringToJcon(tempValue, {logError: false});
      }
    }
  }
  catch (error) {
    //do nothing
  }

  if (typeof(value) === 'string' && !isNaN(value.replace(/^\{/, '').replace(/\}$/, '')) && (value[0] === '{' && value[1] === '-' || value[0] === '-')) {
    value = parseFloat(value.replace(/^\{/, '').replace(/\}$/, '')); //handle -100 which is actually two expressions
  }

  return value;
};

var ValueInput = ({value, valueType, style, contextMenuActions, disableTypeSwitching, autoFocus, selectTextOnFocus, wrapperStyle, inlineCodeFormatting, position, shorten, onChange, onFocus, onBlur, suggestionGroups}) => {
  var labelWidth = useContext(LabelWidthContext);
  var hasSelectedSuggestionRef = useRef(false);
  var inputRef = useRef();
  var viewRef = useRef();

  var [inputValue, setInputValue] = useState(value);
  var [isFocused, setIsFocused] = useState(false);

  var handleKeyDown = event => {
    if (isFocused && event.shiftKey && lib.event.keyPressed(event, 'ctrlcmd') && event.keyCode === 219) {
      setTimeout(() => convertType({shouldBlur: false}));
    }
    else if (isFocused && lib.event.keyPressed(event, 'ctrlcmd') && (event.keyCode === 8)) {
      setTimeout(() => onChange({value: '', backspace: true}));
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  var typeSymbol = 'T';

  var inputValueTypeLabel = getType(inputValue, {useFriendlyLanguage: 1}), alternativeValueType;

  var typeSymbol = {
    number: '#',
    boolean: 'b',
    null: '∅',
    array: '[]',
    object: ':',
    script: '{}',
    text: 'T'
  }[inputValueTypeLabel];

  var parsedValue = parseValue(inputValue);
  var parsedValueType = getType(parsedValue, {useFriendlyLanguage: 1});

  if (parsedValueType !== inputValueTypeLabel) {
    alternativeValueType = parsedValueType;
  }
  else if (inputValueTypeLabel === 'script') {
    alternativeValueType = 'text';
  }
  else if (inputValueTypeLabel === 'text') {
    alternativeValueType = 'script';
  }
  else if (_.includes(['number', 'boolean', 'null'], inputValueTypeLabel)) {
    alternativeValueType = 'script';
  }
  else {
    alternativeValueType = 'text';
  }

  var convertType = ({shouldBlur = false} = {}) => {
    var newValue = inputValue;

    if (parsedValueType !== inputValueTypeLabel) {
      newValue = parsedValue;
    }
    else if (inputValueTypeLabel === 'script') {
      newValue = newValue.replace(/^\{/, '').replace(/\}$/, '');
    }
    else if (_.includes(['number', 'boolean', 'null'], inputValueTypeLabel)) {
      newValue = `{${newValue}}`;
    }
    else if (inputValueTypeLabel !== 'text') {
      newValue = jconObjectToJxString(newValue, {});
    }
    else {
      newValue = `{${newValue}}`;
    }

    // if (shouldBlur) {
    //   setTimeout(() => {
    //     document.activeElement.blur();
    //   });
    // }

    onChange({value: newValue, autoFocus: !shouldBlur});
  };

  var isScript = _.startsWith(inputValue, '{') && _.endsWith(inputValue, '}');
  var isCode = getIsCode(value);
  var isText = inputValueTypeLabel === 'text';

  useKeyDown(event => {
    if (isCode && isFocused) {
      handleKeyDown(event);
    }
  });

  useEffect(() => {
    if (!isCode && autoFocus) {
      setTimeout(() => {
        viewRef.current.querySelector('input')?.focus();
      }, 10);
    }
  }, [autoFocus, isCode]);

  var handleInput = ({value: newValue}) => {
    if (!disableTypeSwitching) newValue = parseValue(newValue);

    var newlinesChanged = (newValue + '').includes('\n') !== (value + '').includes('\n');
    var lengthChanged = ((newValue + '').length > 17) !== ((value + '').length > 17);

    if (newlinesChanged || lengthChanged) {
      onChange({value: disableTypeSwitching ? newValue : parseValue(newValue), autoFocus: true});
    }
    else {
      setInputValue(newValue);
    }
  };

  var textColor = typeof(inputValue) === 'number' ? '#005cc5' : (typeof(inputValue) === 'boolean' ? '#e36209' : (inputValue === null ? '#d73a49' : '#6a737d'));
  var convertText = `Convert from ${inputValueTypeLabel} to ${alternativeValueType} →`;

  return (<>
    <ContextMenu
      actions={[
        ...(!disableTypeSwitching ? [
          {onPress: convertType, label: `Convert to ${alternativeValueType}`, hotkey: '⌘ Shift {'},
          ...(_.includes(['number', 'boolean', 'null'], inputValueTypeLabel) ? [
            {onPress: () => onChange({value: `{\`${parsedValue}\`}`, autoFocus: true}), label: 'Convert to text'}
          ] : []),
        ] : []),
        ...contextMenuActions
      ]}
    >
      {(ref, onContextMenu) => (
        <View ref={(_ref) => {
          ref.current = _ref;

          viewRef.current = _ref;
        }} style={{position: 'relative', ...(isCode ? style : {}), ...(position === 'bottom' ? {borderBottomRightRadius: 10, overflow: 'hidden'} : {}), ...wrapperStyle}} onContextMenu={onContextMenu} dataSet={{hoverableGrayInput: 1, isFocused, isScript}}>
          {isCode ? (
            <CodeInput
              {...{labelWidth}}
              useBorderRadius
              noTopRadius={position === 'bottom'}
              noBottomRadius={position === 'top'}
              noLeftRadius={position === 'right'}
              noRightRadius={position === 'left'}
              autoFocus={autoFocus}
              value={valueType === 'sass' ? value : valueType === 'script' ? (value || '').replace(/^\{/, '').replace(/\}$/, '') : jconObjectToJxString(value, {inline: inlineCodeFormatting, shorten})}
              language={valueType === 'sass' ? 'sass' : 'javascript'}
              // autoFocus={!!valueTypeOverride}
              onKeyUp={(event, {previousValue}) => {
                if (event.key === 'Backspace' && previousValue === '') {
                  setInputValue('');
                  onChange({value: '', autoFocus: true, backspace: true});
                }
              }}
              onFocus={() => {
                setIsFocused(true);

                onFocus?.();
              }}
              onBlur={() => {
                setIsFocused(false);

                onBlur?.();
              }}
              onChange={({value: newValue}) => {
                if (disableTypeSwitching) {
                  if (valueType === 'script') newValue = `{${newValue}}`;
                }
                else {
                  newValue = parseValue(`{${newValue}}`);
                }

                if (newValue !== value) onChange({value: newValue});
              }}
              onInput={({value: newValue}) => {
                if (disableTypeSwitching) {
                  if (valueType === 'script') newValue = `{${newValue}}`;
                }
                else {
                  newValue = parseValue(`{${newValue}}`);
                }

                setInputValue(newValue);
              }}
            />
          ) : (
            <TextInput
              {...{selectTextOnFocus}}
              getInputRef={ref => inputRef.current = ref}
              value={inputValue + ''}
              style={{...inputStyle, color: 'black', fontSize: 12, ...(isFocused ? {paddingRight: 30} : {}), ...(!isText ? {fontFamily: 'monospace', color: textColor} : {}), ...style}}
              onKeyDown={(event) => {
                if (event.key === 'Backspace' && inputValue === '') onChange({value: '', backspace: true});

                handleKeyDown(event);
              }}
              onFocus={() => {
                setIsFocused(true);

                onFocus?.();
              }}
              onBlur={() => {
                var newValue = disableTypeSwitching ? inputValue : parseValue(inputValue, {parsePartialNumbers: true});

                onBlur?.();

                setTimeout(() => {
                  setIsFocused(false);

                  if (!hasSelectedSuggestionRef.current) {
                    if (newValue !== value) {
                      if (_.startsWith(newValue, '`') && _.endsWith(newValue, '`')) {
                        var tempValue = newValue.replace(/`/g, '');

                        if (tempValue === 'null' || tempValue === 'true' || tempValue === 'false' || !isNaN(tempValue)) newValue = `{${newValue}}`;
                      }

                      onChange({value: newValue});
                    }
                  }
                });
              }}
              onInput={({value: newValue}) => {
                if (newValue === '[' || newValue === '{' || newValue === 'false' || newValue === 'true' || newValue === 'null') {
                  if (newValue === '{') newValue = {};
                  if (newValue === '[') newValue = [];
                  if (newValue === 'false') newValue = false;
                  if (newValue === 'true') newValue = true;
                  if (newValue === 'null') newValue = null;

                  onChange({value: newValue, autoFocus: true});
                }
                else {
                  handleInput({value: newValue});
                }
              }}
              // onChange={() => {
              //   var newValue = disableTypeSwitching ? inputValue : parsedValue;

              //   setTimeout(() => {
              //     if (!hasSelectedSuggestionRef.current) {
              //       if (newValue !== value) onChange({value: newValue});
              //     }
              //   });
              // }}
            />
          )}
          {!disableTypeSwitching && (
            <Tooltip text={convertText} style={{position: 'absolute', top: 0, right: -0.1, zIndex: 10, opacity: 0}} dataSet={{typeSwitcher: 1}}>
              <Pressable
                focusable={false}
                style={{backgroundColor: (isCode ? (isScript ? '#f6f6fd' : '#f6f9fd') : '#f5f5f5'), filter: 'brightness(0.98)', height: 20, borderTopRightRadius: position === 'bottom' ? 0 : 10, borderBottomLeftRadius: position === 'right' ? 0 : 10, borderTopLeftRadius: 0, borderRadius: 10, alignItems: 'center', justifyContent: 'center', width: 30}}
                onMouseDown={convertType}
              >
                <Text style={{opacity: 0.5, position: 'relative', color: textColor, top: (typeSymbol === ':' || inputValue === null) ? -1 : 0, ...(!isText ? {fontFamily: 'monospace'} : {fontFamily: 'serif', fontSize: 13}), ...(inputValue === null ? {fontSize: 15} : {})}}>{typeSymbol}</Text>
              </Pressable>
            </Tooltip>
          )}
        </View>
      )}
    </ContextMenu>
    {!isCode && suggestionGroups && isFocused && (
      <Suggestions
        groups={suggestionGroups}
        inputValue={inputValue}
        hasSelectedSuggestionRef={hasSelectedSuggestionRef}
        onSelect={({key: value}) => {
          onChange({value, pickedSuggestion: true});

          setInputValue(value);
        }}
      />
    )}
  </>);
};

export default React.memo(ValueInput);
