import React from 'react';

import { Provider } from 'react-redux';
import { View, Platform } from 'react-native';
import { AppWrapper } from '@symbolic/rn-lib';
import { Route } from 'react-router-native';
import { api } from '@symbolic/lib';
import { store, resourceActions } from './src/redux';
import { connect, setAppData, updateOrgs, setActiveOrg, logIn, logOut } from '@symbolic/redux';

import _ from 'lodash';
import K from '~/k';
import HomePage from '~/pages/HomePage';
import DatabasePage from '~/pages/DatabasePage/DatabasePage';
import AppPage from '~/pages/AppPage/AppPage';
import HeaderContent from '~/components/HeaderContent';

global.REACT_APP_WORKFLOW = 'managed';
global.APP_VERSION = '1.0.0';
global.APP_BUILD_NUMBER = 1;
global.APP_KEY = 'scaffolding';
global.IS_WEB = Platform.OS === 'web';
global.API_DOMAIN = window.location.href.includes('staging') ? 'https://symbolic-api-staging-1.herokuapp.com' : undefined;

var fontFamily = 'HelveticaNeue, Roboto';

K.fontFamily = fontFamily;

_.forEach(K.fonts, font => {
  font.font = '12px ' + fontFamily;
  font.fontWeight = 300;
  font.fontFamily = fontFamily;
  font.letterSpacing = '0.04em';
});

K.fonts.standard.fontSize = '12px';

//TODO
//TODO easily select parent node from properties/hotkey
//TODO grid-based UI builder
//TODO make props more generic - so style is a key within (type style dictionary)
//TODO mobile mode

//TODO map should be able to pull from state
//TODO index by id
//TODO shift click to interact - normal click to view properties

class Scaffold extends React.Component {
  state = {nodes: [], search: '', apps: [], isLoading: false, resourceStatus: 'unloaded', deviceSizeMode: 'desktop'};

  constructor(props) {
    super(props);

    this.scriptLoads = 0;
    this.styleRefs = {};
  }

  componentDidUpdate() {
    this.considerLoadingResources();
  }

  considerLoadingResources = async () => {
    if (this.props.session.isLoggedIn && this.state.resourceStatus === 'unloaded') {
      this.setState({resourceStatus: 'loading'}, async () => {
        this.props.trackDatabases({databases: await api.get('databases', {where: {orgId: _.map(this.props.session.orgs, 'id')}})});
        this.props.trackDatabaseTables({databaseTables: await api.get('databaseTables', {where: {orgId: _.map(this.props.session.orgs, 'id')}})});

        var {data: {apps, appBranches}} = await api.request({uri: '/scaffolding/get-apps'});

        this.props.trackApps({apps});
        this.props.trackAppBranches({appBranches});

        this.setState({apps});

        if (_.filter(apps, {orgId: this.props.session.activeOrg.id}).length === 0 && this.props.session.activeOrg.type === 'business') {
          this.props.setActiveOrg({activeOrgId: _.find(this.props.session.orgs, {type: 'personal'}).id});
        }

        this.setState({resourceStatus: 'loaded'});
      });
    }
  };

  createApp = async () => {
    var app = await api.create('app', {title: 'Untitled App', orgId: this.state.newAppOrgId});

    var appBranch = await api.create('appBranch', {appId: app.id, orgId: this.state.newAppOrgId, nodes: [{id: 'app', type: 'View', label: 'View (App Container)', children: [{id: 'HelloWorld', label: 'Text (Hello World)', type: 'Text', text: 'Hello World (select me on the left and press backspace to delete me)', style: {}}], style: {}}]});

    app.appBranchId = appBranch.id;

    api.update('app', {props: {appBranchId: appBranch.id}, where: {id: app.id}});

    this.setState({apps: [...this.state.apps, app]});
  };

  render() {
    return (<>
      <AppWrapper
        appName={'Scaffolding'}
        appTagline={' '}
        appKey={'scaffolding'}
        appDescription=' '
        Notifications={() => <View></View>}
        headerContent={HeaderContent}
        headerStyle={{...(this.state.headerIsHidden ? {display: 'none'} : {}), filter: 'invert(1)', position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 100, backgroundColor: 'transparent', pointerEvents: 'none', justifyContent: 'space-between'}}
        menuButtonStyle={{pointerEvents: 'auto'}}
        headerContentContainerStyle={{flex: undefined}}
        // setSessionStore={({key, value}) => this.props.setAppData({key, value, appKey: 'designEngine'})}
        // getSessionStore={(key) => _.get(this.props, `session.user.appData.designEngine.${key}`)}
        trackUsers={this.props.trackUsers}
        getRouterProps={this.getRouterProps}
        noButtonColor
      >
        <Route path={['/', '']} exact component={HomePage} />
        <Route path={['/apps/:appId', '/apps/:appId/branches/:appBranchSlug']} exact render={(props) => <AppPage {...props} setHeaderIsHidden={(headerIsHidden) => this.setState({headerIsHidden})}/>} />
        <Route path={['/databases/:databaseId/tables/:databaseTableId']} exact component={DatabasePage} />
      </AppWrapper>
    </>);
  }
}

Scaffold = connect({
  mapState: () => {
    return {};
  },
  mapDispatch: {
    logIn, logOut, setAppData, updateOrgs, setActiveOrg,
    ..._.pick(resourceActions.users, ['trackUsers']),
    ..._.pick(resourceActions.databases, ['trackDatabases']),
    ..._.pick(resourceActions.databaseTables, ['trackDatabaseTables']),
    ..._.pick(resourceActions.apps, ['trackApps']),
    ..._.pick(resourceActions.appBranches, ['trackAppBranches'])
  }
})(Scaffold);

export default function App() {
  return (
    <Provider store={store}>
      <Scaffold />
    </Provider>
  );
}

//HINT inject web css
if (Platform.OS === 'web') {
  const webCss = `
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;300;400;500;700&display=swap');

* {
  appearance: none;
  -webkit-appearance: none;
  outline: none !important;
  box-sizing: border-box;
}
select {
  border-width: 0px;
  font-size: 11.9px;
  letter-spacing: 0.5px;
  min-width: 0px !important;
}
img {
  image-rendering: -webkit-optimize-contrast;
  z-index: initial !important;
}
#root > div {
  border-width: 0px !important;
}
div::-webkit-scrollbar {
  background-color: #eee;
  height: 10px;
  width: 10px;
}
div::-webkit-scrollbar-track {
  box-shadow: none;
  border: none;
}
div::-webkit-scrollbar-thumb {
  background-color: #ccc;
}
div::-webkit-scrollbar-corner {
  background-color: #ddd;
}
div[data-word-break-all] {
  -ms-word-break: break-all;
  word-break: break-all;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

/* sandbox */
.sp-layout {
  border: none !important;
}
.--sp-preview-actions {
  z-index: 100 !important;
}
.sp-preview-actions {
  display: none !important;
}
.sp-wrapper, .sp-layout, .sp-preview, .sp-preview-container {
  height: 100% !important;
}
.sp-layout {
  border-radius: 0px !important;
}

/* resize panes */
div[role="separator"] > div {
  transition: border-color 0.2s;
}
div[role="separator"]:hover > div {
  border-color: rgba(0, 0, 0, 0.4) !important;
}
div[role="separator"]:hover {
  border-left-color: rgba(0, 0, 0, 0.05) !important;
  border-right-color: rgba(0, 0, 0, 0.05) !important;
}

/* HINT constrain app inside window */

html, body, #root {
  height: 100%;
}
html, body {
  overscroll-behavior-x: none;
}
#root > div > div > div > div > div > div > div:first-child > div {
  margin-right: 20px;
}
div[data-intro-slider] {
  overflow-x: hidden !important;
  transition: opacity 1s;
}
#root > div > div > div > div {
  flex-shrink: initial;
  overflow: hidden;
  height: 100%;
}
#root > div > div > div > div > div {
  height: 100%;
}
div[data-intro-slider] > div > div > div {
  flex-grow: 1;
}

div[data-border-black-on-hover="1"]:hover {
  border-color: black !important;
}

div[data-conditional-opacity-parent="1"]:not(:hover):not(:focus-within) *[data-conditional-opacity-child="1"] {
  opacity: 0 !important;
  pointer-events: none;
}
div[data-database-record-row="1"]:hover > div {
  background-color: #f9f9f9;
}
div[data-active-database-record-row="1"] > div {
  background-color: #fff !important;
}

div[data-grow-on-hover="1"] {
  transform: scale(1);
  transition: transform 100ms ease-in-out !important;
}
div[data-grow-on-hover="1"]:hover {
  transform: scale(1.1);
}
div[data-hoverable="1"]:hover {
  opacity: 0.8;
}
div[data-hoverable-gray="1"] {
  transition: filter 0.2s;
}
*[data-hoverable-gray="1"]:hover, *[data-hoverable-gray="1"]:focus, *[data-hoverable-gray-input="1"] input:hover, div[data-hoverable-gray-input="1"] input:focus, div[data-hoverable-gray-input="1"]:hover .cm-editor, div[data-hoverable-gray-input="1"] .cm-editor.cm-focused {
  filter: brightness(0.98);
}
div[data-select-dot="1"]:hover {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
div[data-hoverable-gray-input="1"]:hover div[data-type-switcher="1"], div[data-hoverable-gray-input="1"][data-is-focused="true"] div[data-type-switcher="1"] {
  opacity: 1 !important;
}

div[data-grow-on-hover="1"] {
  transform: scale(1);
  transition: transform 100ms ease-in-out !important;
}
div[data-grow-on-hover="1"]:hover {
  transform: scale(1.1);
}

iframe {
  border: none;
  height: 30px;
  width: 300px;
}
.contact-form-title {
  margin-bottom: 1rem;
}

.cm-editor {
  overflow: hidden !important;
  font-size: 12px;
}
div[data-overflow-auto-code-input="1"] .cm-editor {
  overflow: auto !important;
}
div[data-overflow-auto-code-input="1"] .cm-editor, div[data-overflow-auto-code-input="1"] .cm-theme {
  height: 100%;
}
.cm-editor {
  background-color: #f6f6fd !important;
  min-height: 20px;
}
div[data-is-script="false"] .cm-editor {
  background-color: #f6f8fd !important;
}
.cm-line {
  padding-left: 10px !important;
  padding-right: 30px !important;
}
.cm-tooltip, .cm-panels {
  font-family: HelveticaNeue, Arial;
}
div[data-use-border-radius] .cm-editor {
  border-radius: 10px;
}
div[data-no-top-radius="1"] .cm-editor {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
div[data-no-bottom-radius="1"] .cm-editor {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
div[data-no-left-radius="1"] .cm-editor {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
div[data-no-right-radius="1"] .cm-editor {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.cm-content {
  padding: 0px 0px !important;
  padding-top: 2px !important;
  min-height: 20px !important;
}
div[data-extra-padding="1"] .cm-content {
  padding: 0px 0px !important;
  padding-top: 8px !important;
  padding-bottom: 6px !important;
}
div[data-line-numbers="0"] .cm-gutters {
  display: none !important;
}
.cm-gutters {
  background-color: #f5f5f5 !important;
}
.cm-activeLine, .cm-activeLineGutter {
  background-color: transparent !important;
}

div[data-properties-pane="1"] .decorationsOverviewRuler {
  display: none !important;
}

div[data-properties-pane="1"] .overflowingContentWidgets {
  display: none;
}

div[data-key-value-pair="1"]:hover, div[data-key-value-pair="1"]:has(:focus) {
  border-color: rgba(0, 0, 0, 0.5) !important;
}
div[data-key-value-pair="1"] input:hover, div[data-key-value-pair="1"] select:hover {
  background-color: #eee !important;
}
div[data-key-value-pair="1"] input:focus, div[data-key-value-pair="1"] select:focus {
  background-color: #ddd !important;
}
div div div div[data-dropdown="1"] select:focus, div div div div[data-dropdown="1"] select:hover {
  background-color: transparent !important;
}
.monaco-editor .margin, .monaco-editor-background {
  background-color: transparent !important;
}
.monaco-editor .overflowingContentWidgets, .monaco-editor .view-overlays:not(.focused) .current-line {
  display: none !important;
}


.dnd-sortable-tree_folder_tree-item {
  padding: 0;
}
.dnd-sortable-tree_folder_tree-item-collapse_button {
  transform: scale(0.75);
  opacity: 0.6;
  min-width: 20px;
}
.dnd-sortable-tree_folder_line, .dnd-sortable-tree_folder_line-to_self-last, .dnd-sortable-tree_folder_line-to_self {
  background: none !important;
}
.dnd-sortable-tree_folder_line-last, .dnd-sortable-tree_folder_line, .dnd-sortable-tree_folder_line-to_self, .dnd-sortable-tree_folder_line-to_self-last {
  width: 10px !important;
  min-width: 10px !important;
}

.dnd-sortable-tree_folder_tree-item-collapse_button-collapsed {
  transform: scale(0.75) rotate(-90deg);
  opacity: 0.2 !important;
}
.dnd-sortable-tree_folder_tree-item-collapse_button, .dnd-sortable-tree_folder_tree-item-collapse_button-collapsed {
  cursor: pointer;
}
.dnd-sortable-tree_folder_tree-item-collapse_button:hover, .dnd-sortable-tree_folder_tree-item-collapse_button-collapsed:hover {
  opacity: 0.4 !important;
}
.dnd-sortable-tree_folder_tree-item-collapse_button {
  background: url('https://symbolic-public.s3.amazonaws.com/scaffolding/app/down-arrow-black-medium.png');
  background-position: center;
  background-size: 14px;
  background-repeat: no-repeat;
  position: relative;
  left: -2px;
  top: -2px;
  margin-right: 0px;
}
.dnd-sortable-tree_folder_wrapper, .dnd-sortable-tree_folder_wrapper * {
  pointer-events: none;
}
.dnd-sortable-tree_folder_tree-item-collapse_button, .dnd-sortable-tree_folder_tree-item > div * {
  pointer-events: auto !important;
}

div[data-properties-pane="1"] .dnd-sortable-tree_folder_tree-item-collapse_button, div[data-properties-pane="1"] .dnd-sortable-tree_folder_line-last, div[data-properties-pane="1"] .dnd-sortable-tree_folder_line-to_self-last, div[data-properties-pane="1"] .dnd-sortable-tree_folder_line-to_self, div[data-properties-pane="1"] .dnd-sortable-tree_folder_line {
  display: none !important;
}
.dnd-sortable-tree_folder_tree-item {
  max-width: 100%;
  width: 100%;
}

div[data-jcon-type-picker="1"]:not(:hover) > div:not(:last-child) {
  width: 0px !important;
  overflow: hidden;
  margin-left: 0px !important;
}
div[data-jcon-type-picker="1"]:hover > div:last-child {
  filter: brightness(0.8);
}
div[data-jcon-type-picker="1"] > div:hover {
  filter: brightness(0.8);
}
#root.app-page > div > div > div > div > div > div:nth-child(2) > div:first-child > div:nth-child(2) {
  display: none;
}
div[data-device-size-mode-picker="1"] {
  width: 115px;
  overflow: hidden;
  transition: width 0.5s;
  transition-delay: 0.25s;
}
div[data-device-size-mode-picker="1"]:not(:hover) {
  width: 40px;
}
div[data-text-gradient="1"] {
  background: linear-gradient(to right, #9c85d4, #6f8cc9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
input[data-new-key-input="1"] {
  font-style: italic !important;
}
  `;

  const style = document.createElement('style');

  style.textContent = webCss;

  document.head.append(style);
}
