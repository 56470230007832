import useEffectUnscoped from '~/helpers/useEffectUnscoped';

function useOnPressOutside(ref, onPressOutside) {
  useEffectUnscoped((depsRef) => {
    function handleClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        depsRef.current.onPressOutside(event);
      }
    }

    document.addEventListener('mouseup', handleClick);

    return () => document.removeEventListener('mouseup', handleClick);
  }, [ref], {onPressOutside});
}

export default useOnPressOutside;
