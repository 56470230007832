import { useState, useEffect } from 'react';
import { View, Image, Pressable } from 'react-native';
import { Text } from '@symbolic/rn-lib';

import K from '../k';
import rightArrow from '~/assets/right-arrow-black.png';
import downArrow from '~/assets/down-arrow-black.png';
import usePersistentState from '~/helpers/usePersistentState';

var ExpandableSection = ({
  children, headerStyle, contentContainerStyle, condensed, isExpandedByDefault, fadeInOnLoadDelay,
  shouldUsePersistentState = true, title, renderDisplayNone, headerContent, styles = {}, indicateHasContent, getHeaderStyle,
  caretPosition = 'right',
}) => {
  if (shouldUsePersistentState) {
    var [isExpanded, setIsExpanded] = usePersistentState(isExpandedByDefault, 'expandableSection-' + title);
  }
  else {
    var [isExpanded, setIsExpanded] = useState(isExpandedByDefault);
  }

  var [isVisible, setIsVisible] = useState(fadeInOnLoadDelay === undefined);

  useEffect(() => {
    if (fadeInOnLoadDelay !== undefined) {
      setTimeout(() => setIsVisible(true), fadeInOnLoadDelay);
    }
  }, [fadeInOnLoadDelay]);

  useEffect(() => {
    if (isExpandedByDefault && !shouldUsePersistentState) {
      setIsExpanded(true);
    }
  }, [isExpandedByDefault]);

  //TODO save in cookies by title/key?

  return (
    <View dataSet={{conditionalOpacityParent: 1}} style={{opacity: isVisible ? 1 : 0, transition: 'opacity 0.5s', borderTopWidth: 0, borderTopColor: 'rgba(0, 0, 0, 0.075)', ...styles.outerView}}>
      <View style={{padding: condensed ? K.margin : K.spacing, paddingHorizontal: K.spacing * 2, alignItems: 'flex-start', ...headerStyle, ...(getHeaderStyle ? getHeaderStyle({isExpanded}) : {}), ...styles.innerView}}>
        <Pressable
          dataSet={{hoverable: 1}}
          onPress={() => setIsExpanded(!isExpanded)}
          style={{flexDirection: 'row', alignItems: 'center'}}
        >
          {caretPosition === 'left' && (
            <Image dataSet={{conditionalOpacityChild: 1}} source={isExpanded ? downArrow : rightArrow} style={{width: 12, height: 12, marginRight: 8, transition: 'opacity 0.3s'}}/>
          )}
          <Text style={{fontSize: 12, opacity: indicateHasContent ? 1 : 0.8, fontFamily: 'Work Sans', textTransform: 'uppercase', letterSpacing: '0.07em', fontWeight: 500}} dataSet={{...(indicateHasContent ? {textGradient: 1} : {})}}>{title}</Text>
          {caretPosition === 'right' && (
            <Image dataSet={{conditionalOpacityChild: 1}} source={isExpanded ? downArrow : rightArrow} style={{width: 12, height: 12, marginRight: K.spacing, transition: 'opacity 0.3s'}}/>
          )}
          {headerContent && (
            <View dataSet={{conditionalOpacityChild: 1}} style={{transition: 'opacity 0.3s', flex: 1, flexDirection: 'row', marginRight: K.spacing}}>
              {headerContent}
            </View>
          )}
        </Pressable>
      </View>
      {(isExpanded || renderDisplayNone) && (
        <View style={{...((!isExpanded && renderDisplayNone) ? {display: 'none'} : {}), position: 'relative', padding: K.spacing, paddingTop: 0, ...(condensed ? {paddingBottom: K.margin} : {}), ...contentContainerStyle}}>
          {children}
        </View>
      )}
    </View>
  );
};

export default ExpandableSection;
