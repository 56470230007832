import React, { useRef, useState, useEffect } from 'react';
import { View } from 'react-native';
import { Text } from '@symbolic/rn-lib';
import ReactDOM from 'react-dom';
import K from '~/k';
import useKeyDown from '~/helpers/useKeyDown';

export default function ContextMenu({onClose, children, actions, menuChildren, ...props}) {
  var [contextMenuEvent, setContextMenuEvent] = useState(false);
  var childrenRef = useRef(null);
  var popupRef = useRef(null);

  useKeyDown((event) => {
    if (contextMenuEvent) {
      if (event.key === 'Escape') {
        setContextMenuEvent(false);

        event.stopPropagation();
      }
    }
  }, {capture: true});

  //document
  useEffect(() => {
    var handleClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setContextMenuEvent(false);
      }
    };

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('contextmenu', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('contextmenu', handleClick);
    };
  });

  return (<>
    {children(childrenRef, (event) => {setTimeout(() => setContextMenuEvent(event)); event.preventDefault();})}
    {contextMenuEvent && (
      ReactDOM.createPortal((
        <View ref={popupRef} style={{position: 'fixed', top: contextMenuEvent.pageY, ...(contextMenuEvent.pageX > window.innerWidth - 200 ? {right: window.innerWidth - contextMenuEvent.pageX} : {left: contextMenuEvent.pageX}), zIndex: 1000, ...K.shadow, borderRadius: K.borderRadius, backgroundColor: 'white', overflow: 'auto', paddingVertical: 3}}>
          {_.map(actions, ({onPress, label, hotkey}, index) => (
            <View key={index} style={{padding: 12, paddingVertical: 3, backgroundColor: 'white', cursor: 'pointer', flexDirection: 'row'}} dataSet={{hoverable: 1}} onClick={() => {
              setContextMenuEvent(false);

              onPress();
            }}>
              <Text style={{width: 135}}>{label}</Text>
              {hotkey && (<Text style={{marginLeft: 10, opacity: 0.5, whiteSpace: 'nowrap', width: 45, textAlign: 'right'}}>{hotkey}</Text>)}
            </View>
          ))}
        </View>
      ), document.getElementById('root'))
    )}
  </>);
}
