import { createStore, resourcesReducerAndActionsFor, reducerAndActionsFor } from '@symbolic/redux';

var {actions: resourceActions, reducer: resourcesReducer} = resourcesReducerAndActionsFor({
  apps: {},
  appBranches: {},
  databases: {},
  databaseTables: {},
  databaseRecords: {indexedFieldKeys: ['databaseTableId']},
  nodes: {},
  nodesData: {},
  notifications: {},
  users: {}
});

var {reducer, actions} = reducerAndActionsFor('activeView', {
  setActiveView: ({data = {}, getState}) => {
    console.log(data);
    return {data: {...getState().activeView.data, ...data}};
  }
});

var { setActiveView } = actions;

var store = createStore({
  reducers: {resources: resourcesReducer, activeView: reducer},
  initialState: {activeView: {data: {}}}
});

export { resourceActions, store, setActiveView };
