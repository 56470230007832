import _ from 'lodash';

import esprima from 'esprima-next';
var astring = require('astring'); //HINT need to use require for this package

export default function jxStringToJcon(jxString) {
  var ast = esprima.parseScript(jxString, {range: true});

  var astNodeToJcon = (astNode) => {
    if (astNode.type === 'ObjectExpression') {
      var obj = {};

      _.forEach(astNode.properties, (property) => {
        obj[property.key.name || property.key.value] = astNodeToJcon(property.value);
      });

      return obj;
    }
    else if (astNode.type === 'ArrayExpression') {
      return _.map(astNode.elements, (element) => {
        return astNodeToJcon(element);
      });
    }
    else {
      if (astNode.type === 'TemplateLiteral' && astNode.quasis.length === 1) {
        return astNode.quasis[0].value.raw;
      }
      else if (astNode.type === 'Literal') {
        return astNode.value;
      }
      else {
        if (astNode.type === 'UnaryExpression' && astNode.operator === '-' && astNode.argument.type === 'Literal' && typeof(astNode.argument.value) === 'number') { //HINT if value is a negative number
          return -astNode.argument.value;
        }
        else {
          return `{${jxString.substring(astNode.range[0], astNode.range[1])}}`;
        }
      }
    }
  };

  var result = astNodeToJcon(ast.body[0].expression, {path: ''});

  var cleanValue = (value, {indentation = ''} = {}) => {
    if (value === null) {
      return null;
    }
    else if (typeof(value) === 'object') {
      var fn = Array.isArray(value) ? _.map : _.mapValues;

      return fn(value, (value) => {
        return cleanValue(value, {indentation: indentation + '  '});
      });
    }
    else {
      if (typeof(value) === 'string' && _.includes(value, '\n')) {
        return value.replace(new RegExp('\n' + indentation, 'g'), '\n');
      }
      else {
        return value;
      }
    }
  };

  result = cleanValue(result);

  return result;
}