import React, { useEffect, useState } from 'react';
import { SortableTree, FolderTreeItemWrapper } from 'dnd-kit-sortable-tree';
import { getComponent } from '~/helpers/getComponentHelpers';
import TreeNode from './TreeNode';
import usePersistentState from '~/helpers/usePersistentState';

var WrappedTreeNode = React.forwardRef(function WrappedTreeNode(props, ref) {
  return (
    <FolderTreeItemWrapper {..._.omit(props, ['activeNodePath', 'setActiveNode', 'deleteNode', 'duplicateNode', 'componentizeNode', 'setCommandInputIsFocused'])} ref={ref} showDragHandle={false}>
      <TreeNode node={props.item.node} {..._.pick(props, ['activeNodePath', 'setActiveNode', 'deleteNode', 'duplicateNode', 'componentizeNode', 'setCommandInputIsFocused', 'item'])}/>
    </FolderTreeItemWrapper>
  );
});

WrappedTreeNode = React.memo(WrappedTreeNode);

var Tree = ({jxnApp, commandInputIsFocused, deleteNode, duplicateNode, componentizeNode, activeNodePath, handlePathsChanged, setActiveNode, updateJxnApp, activeAppId, jxnAppId, createData, setCommandInputIsFocused, nodeCategory}) => {
  var [items, setItems] = useState([]);
  var [uuidToPathMap, setUuidToPathMap] = useState({});
  var [collapsedNodePathsByAppId, setCollapsedNodePathsByAppId] = usePersistentState({}, 'collapsedNodePathsByAppId');

  var collapsedNodePaths = collapsedNodePathsByAppId[activeAppId] || {};
  var showAddButton = /*activeNodePath || */commandInputIsFocused;
  //uuid to path map

  useEffect(() => {
    uuidToPathMap = {};

    if (showAddButton) {
      var createPlaceholderItem = {isCreatePlaceholder: true, id: '+', node: {type: '+'}, children: []};
    }

    var mapNodesToItems = ({nodes, parentNodePath, parentNode}) => {
      var items = _.map(nodes, (node, index) => {
        var name = _.get(node, 'name');
        var type = _.get(node, 'type', _.startCase(nodeCategory));

        if (_.includes(['routes', 'helpers'], nodeCategory)) name = _.get(node, 'path');

        var nodePath = parentNodePath + '.' + index;

        var uuid = _.uniqueId();

        uuidToPathMap[uuid] = nodePath;

        var children = Array.isArray(node.children) ? mapNodesToItems({nodes: node.children, parentNodePath: nodePath, parentNode: node}) : [];

        var isRootComponent = jxnApp.rootComponent === name && type === 'Component';

        return {
          node,
          nodePath,
          id: nodePath + type,
          name,
          uuid,
          isLocalComponentInstance: _.includes(_.map(jxnApp.components, 'name'), type),
          isActive: nodePath === activeNodePath,
          key: type + name,
          isRootComponent,
          collapsed: collapsedNodePaths[nodePath] || false,
          childCount: Array.isArray(children) ? children.length : 0,
          children
        };
      });

      var componentDefinition = getComponent({jxnApp, type: parentNode?.type});

      if (showAddButton && activeNodePath === parentNodePath) {
        // if (createData.indexInParent !== undefined) {
        //   items.splice(createData.indexInParent, 0, createPlaceholderItem);
        // }
        // else {

        items.push(createPlaceholderItem); //createPlaceholderItem
        // }
      }

      return items;
    };

    var items = mapNodesToItems({nodes: _.get(jxnApp, nodeCategory), parentNodePath: nodeCategory, parentNode: null});

    if (commandInputIsFocused && !activeNodePath) {
    //   if (createData.indexInParent !== undefined) {
    //     items.splice(createData.indexInParent, 0, createPlaceholderItem);
    //   }
    //   else {
      items.push(createPlaceholderItem); //createPlaceholderItem
    //   }
    }

    //parent of whereever that thing ended up - can even track an index
    //todo prevent other node moves while in create mode
    setItems(items);
    setUuidToPathMap(uuidToPathMap);
  }, [jxnApp, jxnAppId, createData, commandInputIsFocused, activeNodePath]);

  return (
    <SortableTree
      items={items}
      // { animateLayoutChanges: () => false }
      pointerSensorOptions={{
        activationConstraint: {
          distance: 1,
        }
      }}
      onItemsChanged={async (items, {draggedItem}) => {
        setItems(items);

        var changedPaths = {};

        var mapItemsToNodes = (items, {parentNodePath}) => {
          // var createPlaceholderIndex = _.findIndex(items, 'isCreatePlaceholder');

          // if (createPlaceholderIndex !== -1) {
          //   setCreateData({parentNodeId, indexInParent: createPlaceholderIndex});
          // }

          return _.map(_.reject(items, 'isCreatePlaceholder'), (item, index) => {
            var nodePath = (parentNodePath ? parentNodePath + '.' : '') + index;
            var oldNodePath = uuidToPathMap[item.uuid];

            if (nodePath !== oldNodePath) {
              changedPaths[oldNodePath] = nodePath;
            }

            if (!draggedItem && (collapsedNodePaths[nodePath] !== undefined || item.collapsed !== collapsedNodePaths[nodePath])) {
              collapsedNodePaths[nodePath] = item.collapsed;

              setCollapsedNodePathsByAppId({...collapsedNodePathsByAppId, [activeAppId]: {...collapsedNodePaths}});
            }

            return {...item.node, children: _.includes(['string', 'boolean', 'number'], typeof(item.node.children)) ? item.node.children : mapItemsToNodes(item.children, {parentNodePath: nodePath})}; //TODO hasChildren - check componentTypes (need getter)
          });
        };

        var updatedNodes = mapItemsToNodes(items, {parentNodePath: ''});

        if (draggedItem && !draggedItem.isCreatePlaceholder) {
          ({collapsedNodePathsByAppId} = handlePathsChanged({changedPaths, collapsedNodePathsByAppId}));

          setCollapsedNodePathsByAppId(collapsedNodePathsByAppId);
          updateJxnApp({...jxnApp, [nodeCategory]: updatedNodes});
        }
      }}
      TreeItemComponent={WrappedTreeNode}
      {...{setActiveNode, deleteNode, duplicateNode, componentizeNode, setCommandInputIsFocused}}
    />
  );
};

var prevComponentTree;

export default React.memo(Tree, (prevProps, nextProps) => {
  var mapComponents = (components) => {
    return _.map(components, component => {
      return [component.name || '', component.type, mapComponents(component.children)];
    });
  };

  var newComponentTree = mapComponents(nextProps.jxnApp.components);
  var treeIsSame = prevComponentTree && _.isEqual(newComponentTree, prevComponentTree);

  prevComponentTree = newComponentTree;

  return treeIsSame && prevProps.activeNodePath === nextProps.activeNodePath && _.isEqual(prevProps.createData, nextProps.createData) && prevProps.commandInputIsFocused === nextProps.commandInputIsFocused && prevProps.activeAppId === nextProps.activeAppId && prevProps.jxnAppId === nextProps.jxnAppId && prevProps.jxnApp === nextProps.jxnApp;
});
