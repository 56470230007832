import { useState, useRef } from 'react';
import { View, Image, Pressable } from 'react-native';
import { Text } from '@symbolic/rn-lib';

import _ from 'lodash';
import K from '~/k';
import moreIcon from '~/assets/more-icon.png';
import moreIconWhite from '~/assets/more-icon-white.png';
import useKeyDown from '~/helpers/useKeyDown';
import useOnPressOutside from '~/helpers/useOnPressOutside';

var Dropdown = ({actions, style, dropdownStyle, iconStyle, getCloseDropdown, theme = 'light', children, ...props}) => {
  var [isExpanded, setIsExpanded] = useState(false);
  var dropdownRef = useRef();

  if (getCloseDropdown) getCloseDropdown(() => setIsExpanded(false));

  //TODO portal for zindex?

  useOnPressOutside(dropdownRef, () => {
    setIsExpanded(false);
  });

  useKeyDown((event) => {
    if (isExpanded) {
      if (event.key === 'Escape') {
        setIsExpanded(false);

        event.stopPropagation();
      }
    }
  }, {capture: true});

  return (
    <View style={{position: 'relative', ...style}} dataSet={{dropdown: 1}} ref={dropdownRef} {...props}>
      <Pressable dataSet={{conditionalOpacityChild: 1}} focusable={false} onPress={() => setIsExpanded(!isExpanded)}>
        <Image source={theme === 'light' ? moreIcon : moreIconWhite} style={{width: 12, height: 12, transform: [{rotate: props.showVerticalIcon ? '90deg' : '0deg'}], ...iconStyle}}/>
      </Pressable>
      {isExpanded && (
        <View style={{position: 'absolute', zIndex: 10000, backgroundColor: '#fff', borderRadius: K.borderRadius, ...K.shadow, ...dropdownStyle}}>
          <View style={{position: 'relative'}}>
            {actions ? _.map(actions, action => (
              <Pressable dataSet={{hoverable: 1}} key={action.key || action.label} onPress={action.onPress} style={{paddingHorizontal: K.spacing, paddingVertical: 5}}>
                <Text style={{whiteSpace: 'nowrap'}}>{action.label}</Text>
              </Pressable>
            )) : children}
          </View>
        </View>
      )}
    </View>
  );
};

export default Dropdown;
