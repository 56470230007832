import _ from 'lodash';

export default function mapNodes(nodes, predicate) {
  var eachNode = (node, index, {nodePath}) => {
    node = predicate(node, index, {nodePath});

    if (node.children && Array.isArray(node.children)) node.children = _.map(node.children, (node, index) => eachNode(node, index, {nodePath: nodePath + '.' + index})); //TODO no diff

    return node;
  };

  nodes = _.map(nodes, (node, index) => eachNode(node, index, {nodePath: index + ''}));

  return nodes;
}
