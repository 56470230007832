import React, { useContext } from 'react';
import LabelWidthContext from '~/contexts/LabelWidthContext';
import { View } from 'react-native';
import { Text } from '@symbolic/rn-lib';
import JconTypePicker from './JconTypePicker';
import K from '~/k';

var labelStyle = {color: 'rgba(0, 0, 0, 0.4)', textTransform: 'uppercase', fontSize: 10, letterSpacing: '0.1em', fontWeight: 500, fontFamily: 'Work Sans'};

// color: type === 'Object' ? '#99aede' : (type === 'Script' ? '#dfa862' : (_.find(typeOptions, {type})?.color || 'rgba(0, 0, 0, 0.4)'))

var JconNodeHeader = ({activeInputLabel, type, typeOptions, onChangeType, onContextMenu, contextMenuRef}) => {
  return (
    <View ref={contextMenuRef} onContextMenu={onContextMenu} style={{flexDirection: 'row', alignItems: 'center', paddingBottom: 0, zIndex: 1/*, ...(isExpressionObject ? {position: 'absolute', zIndex: 1, right: 20} : {})*/}}>
      <Text style={{...labelStyle, opacity: activeInputLabel ? 0.8 : 0, transition: 'opacity 0.5s', paddingLeft: K.spacing}}>{activeInputLabel || ''}</Text>
      <View style={{flex: 1}}/>
      <JconTypePicker
        tabIndex={-1}
        value={type}
        typeOptions={_.map(typeOptions, ({title, type, color}) => ({type, color, title: title || _.startCase(type)}))}
        onChange={({value}) => onChangeType({value})}
      />
    </View>
  );
};

export default React.memo(JconNodeHeader);
