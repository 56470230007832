export default function getArePropsEqual({componentName, shouldLogChanges, ignoredPropKeys = {}, useIsEqualPropKeys} = {}) {
  return (prevProps, nextProps) => {
    var getChangedKeys = (prevProps, nextProps, ignoredPropKeys, useIsEqualPropKeys) => {
      var changedKeys = {};

      for (const key in nextProps) {
        var prevProp = prevProps[key];
        var nextProp = nextProps[key];

        var isDifferent = prevProp !== nextProp;

        if (useIsEqualPropKeys?.[key]) {
          isDifferent = !_.isEqual(prevProp, nextProp);
        }

        if (isDifferent) {
          if (!ignoredPropKeys[key]) {
            changedKeys[key] = 1;

            if (shouldLogChanges) console.log(componentName, key, prevProp, nextProp);
          }
          else if (typeof(nextProp) === 'object' && ignoredPropKeys[key].ignoredPropKeys) {
            var subChangedKeys = getChangedKeys(prevProp, nextProp, ignoredPropKeys[key].ignoredPropKeys, ignoredPropKeys[key].useIsEqualPropKeys);

            if (shouldLogChanges && _.size(subChangedKeys)) console.log('sub', componentName, subChangedKeys, prevProp, nextProp);

            if (_.size(subChangedKeys) > 0) changedKeys[key] = 1;
          }
        }
      }

      return changedKeys;
    };

    return _.isEmpty(getChangedKeys(prevProps, nextProps, ignoredPropKeys, useIsEqualPropKeys));
  };
}
