import React, { useEffect, useRef } from 'react';
import { Pressable, View, ActivityIndicator } from 'react-native';
import { Text } from '@symbolic/rn-lib';

import getArePropsEqual from '~/helpers/getArePropsEqual';
import ContextMenu from '~/components/ContextMenu';

var TreeNode = (props) => {
  var {node, setActiveNode, item} = props;
  var {isActive} = item;
  var ref = useRef();

  useEffect(() => {
    if (isActive || item.isCreatePlaceholder) {
      ref.current.scrollIntoView({block: 'nearest', behavior: 'smooth'});
    }
  }, [isActive, item.isCreatePlaceholder]);

  var isComponentOrInstance = node.type === 'Component' || item.isLocalComponentInstance;
  var nodeCategory = _.split(item.nodePath, '.')[0];

  return (
    <ContextMenu
      actions={[
        {onPress: () => props.deleteNode({nodePath: item.nodePath}), label: 'Delete', hotkey: '⌫'},
        {onPress: () => props.duplicateNode({nodePath: item.nodePath}), label: 'Duplicate', hotkey: '⌘ D'},
        ...(node.type !== 'Component' && nodeCategory === 'components' ? [
          {onPress: () => props.componentizeNode({nodePath: item.nodePath}), label: 'Convert to Component'}
        ] : [])
      ]}
    >
      {(contextMenuRef, onContextMenu) => (
        <Pressable
          ref={_ref => {
            if (_ref) {
              ref.current = _ref;
              contextMenuRef.current = _ref;
            }
          }}
          onContextMenu={onContextMenu}
          focusable={false}
          dataSet={{hoverableGray: 1, treeNode: 1}}
          style={{
            marginBottom: 4, marginLeft: item.childCount > 0 ? 0 : 20,
            cursor: 'pointer', borderRadius: node.type === 'Component' ? 5 : 100, height: 20,
            justifyContent: 'center', paddingHorizontal: node.type === 'Component' ? 10 : 12,
            ...(item.isCreatePlaceholder ? {borderWidth: 1, borderColor: 'rgba(0, 0, 0, 0.1)'} : (isActive || !isComponentOrInstance ? {backgroundColor: isActive ? '#000' : '#f0f0f0'} : node.type === 'Component' ? {background: 'linear-gradient(to right, #CEBCF8, #AAC4FA)'} : (item.isLocalComponentInstance ? {background: 'linear-gradient(to right, #e3daf7, #d4e0fa)'} : {}))),
            alignItems: 'center', flexDirection: 'row'
          }}
          onPress={(event) => {
            if (!item.isCreatePlaceholder) {
              setActiveNode({path: isActive ? null : item.nodePath});

              event.stopPropagation();
            }
            else {
              props.setCommandInputIsFocused(true);

              event.stopPropagation();
            }
          }}
        >
          {(item.isCreatePlaceholder && item.isLoading) ? (
            <View style={{transform: [{scale: 0.7}], opacity: 0.5}}>
              <ActivityIndicator size="small" color="#000000"/>
            </View>
          ) : (<>
            <Text style={{userSelect: 'none', ...(item.isCreatePlaceholder ? {color: '#666', position: 'relative', top: -1} : {color: isActive ? '#fff' : '#000000'}), fontWeight: isActive ? 'bold' : 'normal'}}>{(item.name || node.type)}</Text>
            {(item.name && node.type === 'Component') && (
              <Text style={{marginLeft: 5, opacity: 0.7, userSelect: 'none', color: isActive ? '#fff' : '#000000', fontWeight: isActive ? 'medium' : 'normal'}}>{`(${item.isRootComponent ? 'Root ' : ''}${node.type})`}</Text>
            )}
            {node.type !== 'Component' && (node.expressions || node.stylesheet || node.styleSheet || node.props?.onMount || node.props?.onRef) && (
              <View style={{backgroundColor: 'red', width: 6, height: 6, borderRadius: 3, marginLeft: 5}}/>
            )}
            {node.stylesByFormat && (
              <View style={{backgroundColor: 'yellow', width: 6, height: 6, borderRadius: 3, marginLeft: 5}}/>
            )}
          </>)}
        </Pressable>
      )}
    </ContextMenu>
  );
};

TreeNode = React.memo(TreeNode, getArePropsEqual({ignoredPropKeys: {item: {ignoredPropKeys: {uuid: {}, children: {}, parent: {}}}}, useIsEqualPropKeys: {node: {}}}));

export default TreeNode;
