import React, { useEffect, useState, useRef } from 'react';

import { View } from 'react-native';

import CodeMirror from '@uiw/react-codemirror';
import getArePropsEqual from '~/helpers/getArePropsEqual';
import * as eslint from 'eslint-linter-browserify';

import { githubLight } from '@uiw/codemirror-theme-github';
import { javascript, esLint } from '@codemirror/lang-javascript';
import { linter } from '@codemirror/lint';
import { sass } from '@codemirror/lang-sass';

var CodeInput = (props) => {
  var {language, useBorderRadius, noTopRadius, noBottomRadius, noLeftRadius, noRightRadius, value, onChange, onInput, editable = true, height = 'auto', autoFocus, onFocus, onBlur, ...props} = props;

  var [editorIsFullscreen, setEditorIsFullscreen] = useState(false);
  var [temporaryValue, setTemporaryValue] = useState(value);
  var previousValueRef = useRef(value);

  useEffect(() => {
    setTemporaryValue(value);
  }, [value, setTemporaryValue]);

  var viewRef = useRef();
  var textareaRef = useRef();

  useEffect(() => {
    var refInterval = setInterval(() => {
      textareaRef.current = viewRef.current?.querySelector('div[contenteditable]');

      if (textareaRef.current) {
        clearInterval(refInterval);

        if (autoFocus) setTimeout(() => {
          textareaRef.current.focus();

          const range = document.createRange();
          const selection = window.getSelection();

          if (value === '({})') {
            range.setStart(textareaRef.current.childNodes[0], 2);
          }
          else if (value === '[]') {
            range.setStart(textareaRef.current.childNodes[0], 1);
          }
          else {
            range.setStart(textareaRef.current, textareaRef.current.childNodes.length);
          }

          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
        }, 10);
      }
    }, 10);

    return () => {
      if (!textareaRef.current) {
        clearInterval(refInterval);
      }
    };
  }, [autoFocus, viewRef]);

  return (
    <View dataSet={{codeInput: 1, useBorderRadius: useBorderRadius ? 1 : 0, noTopRadius: noTopRadius ? 1 : 0, noBottomRadius: noBottomRadius ? 1 : 0, noLeftRadius: noLeftRadius ? 1 : 0, noRightRadius: noRightRadius ? 1 : 0, extraPadding: props.extraPadding ? 1 : 0, lineNumbers: props.lineNumbers ? 1 : 0}} ref={viewRef} style={{...props.style}}>
      <CodeMirror
        height={height}
        theme={githubLight}
        key={editorIsFullscreen}
        extensions={[
          ...(language === 'javascript' ? [
            javascript({ jsx: true }),

            linter(esLint(new eslint.Linter(), {
              parserOptions: {
                ecmaVersion: 2019,
                sourceType: 'module'
              },
              env: {
                browser: true,
                node: true
              },
              extends: 'eslint:recommended'
            }))
          ] : [sass()])
        ]}
        value={temporaryValue}
        editable={editable}
        onChange={value => {
          previousValueRef.current = temporaryValue;

          if (_.startsWith(value, '{') && _.endsWith(value, '}')) {
            value = value = `(${value})`;
          }

          setTemporaryValue(value);

          onInput?.({value});
        }}
        onBlur={() => {
          setTimeout(() => {
            previousValueRef.current = temporaryValue;

            if (temporaryValue !== undefined && temporaryValue !== value) {
              onChange?.({value: temporaryValue});
            }

            onBlur?.();
          });
        }}
        onFocus={onFocus}
        onKeyUp={(event) => {
          props.onKeyUp?.(event, {value: temporaryValue, previousValue: previousValueRef.current});

          previousValueRef.current = temporaryValue;
        }}
      />
    </View>
  );
};

CodeInput = React.memo(CodeInput, getArePropsEqual({logChangedProps: true, ignoredPropKeys: {onKeyUp: {}, onChange: {}}}));

export default CodeInput;
