// https://www.dofactory.com/css/properties
// https://github.com/vhpoet/react-native-styling-cheat-sheet

export default function getStyleKeyGroups() {
  return [
    {title: 'General', keys: [
      {key: 'display', values: ['none', 'flex', 'block', 'inline', 'inline-block'], description: 'layout mode i.e. flex, block, inline'},
      {key: 'boxShadow', description: 'create shadows i.e. drop shadows, glow effects'},
      {key: 'boxSizing', description: 'include padding/border in width/height or not'},
      {key: 'cursor', description: 'cursor shapes i.e. pointer, move, text'},
      {key: 'filter', description: 'adjust appearance i.e. blur(), desaturate()'}, //last polished description
      {key: 'opacity', description: 'Sets the opacity (transparency) of the element'},
      {key: 'overflow', description: 'Specifies the flow of content that exceeds the container'},
      {key: 'overflowX', description: 'Specifies the flow of content that exceeds the container width'},
      {key: 'overflowY', description: 'Specifies the flow of content that exceeds the container height'},
      {key: 'pointerEvents', description: 'Specifies whether element reacts to pointer events or not'},
      {key: 'transform', description: 'Applies a 2D or 3D transformation to an element'},
      {key: 'userSelect', description: 'Specifies how text can be selected (highlighted)'},
      {key: 'visibility', description: 'Specifies the visibility of an element'},
      {key: 'zIndex', description: 'Sets the vertical stacking order relative to other elements'},
    ]},

    {title: 'Width & Height', keys: [
      {key: 'width', type: 'size', description: 'Sets the width of an element'},
      {key: 'height', type: 'size', description: 'Sets the height of an element'},
      {key: 'maxHeight', type: 'size', description: 'Sets the maximumn height for an element'},
      {key: 'maxWidth', type: 'size', description: 'Sets the maximum width for an element'},
      {key: 'minHeight', type: 'size', description: 'Sets the minimum height for an element'},
      {key: 'minWidth', type: 'size', description: 'Sets the minimum width for an element'},
    ]},

    {title: 'Flex Layout', keys: [
      {key: 'flex', description: 'Shorthand property to set grow/shrink amount', defaultValue: 1},
      {key: 'flexDirection', values: ['row', 'column'], description: 'Specifies the direction for the flex item to align'},
      {key: 'alignItems', values: ['flex-start', 'center', 'flex-end'], description: 'Aligns evenly spaced items in a flex container'},
      {key: 'justifyContent', values: ['flex-start', 'center', 'flex-end', 'space-between'], description: 'Defines the alignment of items in a flex container'},
      {key: 'alignSelf', description: 'Aligns an item inside a flex container'},
      {key: 'flexBasis', description: 'Specifies the initial width of a flex item'},
      {key: 'flexFlow', description: 'Controls the direction and wrapping of flexible items'},
      {key: 'flexGrow', description: 'Specifies how a flex item can grow inside the container'},
      {key: 'flexShrink', description: 'Specifies how a flex item can shrink inside the container'},
      {key: 'flexWrap', description: 'Specifies how flexible items wrap inside the container'},
      {key: 'order', description: 'Specifies the order of an item in a flex container'},
    ]},

    {title: 'Position', keys: [
      {key: 'position', values: ['absolute', 'relative', 'fixed', 'sticky', 'static'], description: 'Sets the element\'s positioning method'},
      {key: 'bottom', description: 'Positions the element from the bottom of the relative container'},
      {key: 'left', description: 'Positions the element from the left of the relative container'},
      {key: 'right', description: 'Positions the element from the right of the relative container'},
      {key: 'top', description: 'Positions the element from the top of the relative container'},
    ]},

    {title: 'Text', keys: [
      {key: 'color', description: 'Specifies the color of text in an element'},
      {key: 'font', description: 'Sets font family, variant, weight, height, and size for an element'},
      {key: 'fontFamily', description: 'Sets the font family for an element'},
      {key: 'fontKerning', description: 'Sets the spacing between the font\'s characters'},
      {key: 'fontSize', description: 'Sets the size of the font for an element'},
      {key: 'fontSizeAdjust', description: 'Specifies a fall-back font size'},
      {key: 'fontStretch', description: 'Sets the text characters to a wider or narrower variant'},
      {key: 'fontStyle', description: 'Set the font style to normal, italic, or oblique'},
      {key: 'fontWeight', description: 'Sets the weight or thickness of the font'},
      {key: 'letterSpacing', description: 'Sets the spacing between characters'},
      {key: 'lineHeight', description: 'Sets the vertical spacing between lines of text'},
      {key: 'textAlign', description: 'Sets the alignment of text inside an element'},
      {key: 'textDecoration', description: 'Defines the style and color of underlined text'},
      {key: 'textShadow', description: 'Adds a shadow effect to text'},
      {key: 'textTransform', description: 'Defines text capitalization or casing'},
      {key: 'whiteSpace', description: 'Specifies how white-space is handled inside an element'}
    ]},

    {title: 'Background', keys: [
      {key: 'backgroundColor', color: true, description: 'Sets the background color of the element'},
      {key: 'backgroundImage', description: 'Specifies a background image for an element'},
      {key: 'backgroundOrigin', description: 'Specifies the background image origin position'},
      {key: 'backgroundPosition', description: 'Sets the position of a background image'},
      {key: 'backgroundRepeat', description: 'Specifies how the background image is repeated'},
      {key: 'backgroundSize', description: 'Sets the size of the background image'},
    ]},

    {title: 'Border', keys: [
      {key: 'border', proprietary: true, description: 'Specifies a border for an element'},

      {key: 'borderBottom', webOnly: true, description: 'Specifies a bottom border for an element'},
      {key: 'borderLeft', webOnly: true, description: 'Sets the left border of the element'},
      {key: 'borderRight', webOnly: true, description: 'Sets the right border of the element'},
      {key: 'borderTop', webOnly: true, description: 'Sets the right border of the element'},

      {key: 'borderColor', description: 'Sets the color of the border'},
      {key: 'borderStyle', description: 'Defines the style of the border'},
      {key: 'borderWidth', description: 'Sets the border width of the element'},

      {key: 'borderBottomColor', description: 'Sets the color of a bottom border '},
      {key: 'borderBottomStyle', description: 'Sets the style of the bottom border'},
      {key: 'borderBottomWidth', description: 'Sets the width of the bottom border'},

      {key: 'borderLeftColor', description: 'Sets the color of the left border'},
      {key: 'borderLeftStyle', description: 'Sets the style of the left border'},
      {key: 'borderLeftWidth', description: 'Sets the width of the left border'},

      {key: 'borderRightColor', description: 'Sets the color of the right border'},
      {key: 'borderRightStyle', description: 'Sets the style of the right border'},
      {key: 'borderRightWidth', description: 'Sets the width of the right border'},

      {key: 'borderTopColor', description: 'Sets the color of the top border'},
      {key: 'borderTopStyle', description: 'Sets the style of the top border'},
      {key: 'borderTopWidth', description: 'Sets the width of the top border'},
    ]},

    {title: 'Border Radius', keys: [
      {key: 'borderRadius', description: 'Sets the radius of the border'},

      {key: 'borderBottomRadius', proprietary: true, transform: (value) => ({borderBottomLeftRadius: value, borderBottomRightRadius: value}), description: 'Sets the radius of the bottom right and left corners'},
      {key: 'borderLeftRadius', description: 'Sets the radius of the left top and bottom corners'},
      {key: 'borderRightRadius', description: 'Sets the radius of the right top and bottom corners'},
      {key: 'borderTopRadius', description: 'Sets the radius of the top right and left corners'},

      {key: 'borderBottomLeftRadius', description: 'Sets the border radius of the bottom left corner'},
      {key: 'borderBottomRightRadius', description: 'Sets the border radius of the bottom right corner'},
      {key: 'borderTopLeftRadius', description: 'Sets the border radius of the top left corner'},
      {key: 'borderTopRightRadius', description: 'Sets the border radius of the top right corner'},
    ]},

    {title: 'Margin & Padding', keys: [
      {key: 'margin', type: 'size', description: 'Sets the margin (outside spacing) for an element'},

      {key: 'marginVertical', type: 'size', description: 'Sets the top and bottom margin (outside spacing) for an element'},
      {key: 'marginHorizontal', type: 'size', description: 'Sets the left and right margin (outside spacing) for an element'},

      {key: 'marginBottom', type: 'size', description: 'Sets the bottom margin (outside spacing) for an element'},
      {key: 'marginLeft', type: 'size', description: 'Sets the left margin (outside spacing) for an element'},
      {key: 'marginRight', type: 'size', description: 'Sets the right margin (outside spacing) for an element'},
      {key: 'marginTop', type: 'size', description: 'Sets the top margin (outside spacing) for an element'},

      {key: 'padding', type: 'size', description: 'Sets the spacing between content and element border'},

      {key: 'paddingVertical', type: 'size', description: 'Sets the spacing between content and bottom element border'},
      {key: 'paddingHorizontal', type: 'size', description: 'Sets the spacing between content and bottom element border'},

      {key: 'paddingBottom', type: 'size', description: 'Sets the spacing between content and bottom element border'},
      {key: 'paddingLeft', type: 'size', description: 'Sets the spacing between content and left element border'},
      {key: 'paddingRight', type: 'size', description: 'Sets the spacing between content and right element border'},
      {key: 'paddingTop', type: 'size', description: 'Sets the spacing between content and top element border'},
    ]},

    {title: 'Transition', keys: [
      {key: 'transformOrigin', description: 'Sets the origin for the transformation of the element'},
      {key: 'transformStyle', description: 'Specifies the display behavior of 3D space nested elements'},
      {key: 'transition', description: 'Creates transitions from one property value to another'},
      {key: 'transitionDelay', description: 'Creates a delay before the transition effect starts'},
      {key: 'transitionDuration', description: 'Specifies the time the transition will take'},
      {key: 'transitionProperty', description: 'Specifies the CSS property that will transition'},
      {key: 'transitionTimingFunction', description: 'Defines the speed curve function of the transition'},
    ]},

    {title: 'Other', keys: [
      {key: 'objectFit', description: 'Specifies how an image or video fits inside a container'},
      {key: 'objectPosition', description: 'Specifies the image or video position inside a container'},
      {key: 'listStyle', webOnly: true, description: 'Defines the markers (bullet points) for items in a list'},
      {key: 'outline', description: 'Adds an outline (highlighted border) to an element'},
    ]}
  ];
}
