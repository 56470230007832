import React, { useState, Fragment } from 'react';

import { View, Image, Pressable } from 'react-native';
import { TextInput, Tooltip, Link, Button, Text } from '@symbolic/rn-lib';
import { Route, withRouter } from 'react-router-native';
import { resourceActions, setActiveView } from '~/redux';
import { connect } from '@symbolic/redux';

import K from '~/k';
import homeIcon from '~/assets/home-icon-white.png';
import settingsIconWhite from '~/assets/settings-icon-white.png';
import SettingsPopup from '~/components/SettingsPopup';
import _ from 'lodash';

var HeaderContent = ({databases, ...props}) => {
  var [settingsAreVisible, setSettingsAreVisible] = useState();

  return (
    <Route path={['/databases/:databaseId/tables/:databaseTableId', '/apps/:appId', '/apps/:appId/branches/:appBranchSlug']} exact render={({match}) => {
      var database = databases[match.params.databaseId];

      return (
        <View style={{pointerEvents: 'auto'}}>
          {database && (
            <View style={{flexDirection: 'row'}}>
              <Tooltip left text='Home'>
                <Link to='/' style={{...K.button, backgroundColor: 'transparent', alignItems: 'center'}}>
                  <Image source={homeIcon} nativeID={'HeaderContentHomeButton'} style={{...K.defaultIconSize}}/>
                </Link>
              </Tooltip>
              <Tooltip left text='Settings'>
                <Button style={{...K.button, marginLeft: K.margin, backgroundColor: 'transparent'}} icon={settingsIconWhite} onPress={() => setSettingsAreVisible(!settingsAreVisible)}/>
              </Tooltip>
            </View>
          )}
          {(database) && (
            <TextInput
              style={{position: 'absolute', top: 30, width: 250, marginTop: K.spacing * 2, marginLeft: K.spacing, color: 'white', backgroundColor: 'transparent', paddingHorizontal: 0, ...K.fonts.pageHeader, letterSpacing: K.fonts.standard.letterSpacing}}
              value={(database).title}
              onChange={({value}) => {
                props['updateDatabase']({id: (database).id, props: {title: value}});
              }}
            />
          )}
          {settingsAreVisible && (
            <SettingsPopup
              {...props}
              onClose={() => setSettingsAreVisible(false)}
              database={database}
            />
          )}
        </View>
      );
    }} />
  );
};

HeaderContent = withRouter(connect({
  mapState: state => {
    return {
      databases: state.resources.databases.byId || {},
      databaseTables: state.resources.databaseTables.byId || {},
      databaseRecords: state.resources.databaseRecords.byId || {},
      appBranches: state.resources.appBranches.byId || {},
      apps: state.resources.apps.byId || {},
      activeView: state.activeView
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.databases, ['updateDatabase', 'updateDatabases', 'destroyDatabase']),
    ..._.pick(resourceActions.apps, ['updateApp', 'updateApps', 'destroyApp']),
    ..._.pick(resourceActions.appBranches, ['updateAppBranch', 'updateAppBranches', 'trackAppBranches']),
    ..._.pick(resourceActions.databaseTables, ['updateDatabaseTables']),
    ..._.pick(resourceActions.databaseRecords, ['updateDatabaseRecords']),
    setActiveView
  }
})(HeaderContent));

export default HeaderContent;
