var componentLibraries = [
  {
    name: 'ui',
    displayName: 'UI',
    dependencies: {
      'react-native': {platforms: ['cross-platform'], version: ''},
      'next': {platforms: ['web'], version: ''},
      //next/image?
    },
    platforms: ['web', 'cross-platform'],
    components: [
      {
        type: 'Component',
        name: 'View',
        forwardRef: true,
        imports: [
          {import: {View: {alias: 'ReactNativeView'}}, from: 'react-native', platforms: ['cross-platform']}
        ],
        children: [
          {type: 'div', name: 'View', platforms: ['web'], style: [{display: 'flex', flexDirection: 'column'}, '{props.style}'], props: ['{props}', {ref: '{ref}'}], children: '{props.children}'}, //style display: flex
          {type: 'ReactNativeView', platforms: ['cross-platform'], children: '{props.children}', props: ['{props}', {ref: '{ref}'}]}
        ]
      },
      {
        type: 'Component',
        name: 'Text',
        supportsChildren: false,
        forwardRef: true,
        propTypes: [
          {name: 'text', defaultValue: 'text'}
        ],
        imports: [
          {import: {Text: {alias: 'ReactNativeText'}}, from: 'react-native', platforms: ['cross-platform']}
        ],
        expressions: [
          {type: 'var', var: {text: {}, restProps: '...'}, value: '{props}'}
        ],
        //TODO default styles
        children: [
          {type: 'div', platforms: ['web'], props: ['{restProps}', '{ref}'], style: [{}, '{props.style}'], children: '{props.children || text}'}, //TODO without text
          {type: 'ReactNativeText', platforms: ['cross-platform'], props: ['{restProps}', '{ref}'], style: [{}, '{props.style}'], children: '{props.children || text}'}
        ]
      },
      {
        type: 'Component',
        name: 'TextInput',
        supportsChildren: false,
        forwardRef: true,
        imports: [
          {import: {TextInput: {alias: 'ReactNativeTextInput'}}, from: 'react-native', platforms: ['cross-platform']}
        ],
        propTypes: [
          {name: 'value', defaultValue: 'text'},
          {name: 'placeholder'},
          {name: 'multiline'},
          // {name: 'autoHeight'},
          {name: 'autoFocus'},
          {name: 'onChange', defaultValue: '{(event) => console.log(event.target.value)}'},
          {name: 'onChangeEnd', exampleValue: '{(event) => console.log(event.target.value)}'},
          {name: 'onFocus', title: ''},
          {name: 'onBlur'},
          {name: 'onKeyDown', title: ''},
          {name: 'onKeyUp', title: ''},
        ],
        expressions: [
          {type: 'state', var: ['inputValue', 'setInputValue'], initialState: '{props.value}'},
          {type: 'var', var: {multiline: {}, value: {}, onChange: {}, onBlur: {}, onChangeEnd: {}, restProps: '...'}, value: '{props}'},
          {type: 'var', var: 'handleChange', value: `{(event) => {
  setInputValue(event.target.value);

  onChange?.(event);
}}`},
          {type: 'var', var: 'handleBlur', value: `{(event) => {
  onChangeEnd?.(event);
  onBlur?.(event);
}}`},

          {type: 'ref', var: 'inputValueRef'},
          '{inputValueRef.current = inputValue}',
          {type: 'effect', setup: `{() => {
  if (inputValueRef.current !== value) {
    setInputValue(value);
  }
}}`, dependencies: '{[value]}'}, //HINT detect onChangeEnd getting set after not being set during development

        ],
        children: [
          {type: 'input', name: 'TextInput', shouldRender: '{!multiline}', platforms: ['web'], props: ['{restProps}', {value: '{inputValue}', onChange: '{handleChange}', onBlur: '{handleBlur}', ref: '{ref}'}], style: [{padding: 0, margin: 0, border: '0px solid black'}, '{props.style}']},
          {type: 'textarea', name: 'TextInput', shouldRender: '{multiline}', platforms: ['web'], props: ['{restProps}', {value: '{inputValue}', onChange: '{handleChange}', onBlur: '{handleBlur}', ref: '{ref}'}], style: [{padding: 0, margin: 0, resize: 'none', border: '0px solid black'}, '{props.style}']},
          {type: 'ReactNativeTextInput', name: 'TextInput', platforms: ['cross-platform'], props: ['{props}', {value: '{inputValue}', onChange: '{handleChange}', onBlur: '{handleBlur}', ref: '{ref}'}]}
        ]
      },
      {
        type: 'Component',
        name: 'Link',
        forwardRef: true,
        imports: [
          // {import: {Pressable: {alias: 'ReactNativePressable'}}, from: 'react-native', platforms: ['cross-platform']},
          // {import: {Link: {alias: 'NextLink'}}, from: 'next', platforms: ['web']}
        ],
        propTypes: [
          {name: 'url', defaultValue: '/'},
          // {name: 'text', exampleValue: 'Click here'},
          {name: 'target', exampleValue: '_blank'},
          {name: 'rel', exampleValue: 'noopener noreferrer'},
          {name: 'alt', exampleValue: 'Description of link'},
        ],
        expressions: [
          {type: 'var', var: {url: {}, restProps: '...'}, value: '{props}'}
        ],
        children: [
          {type: 'a', platforms: ['web'], props: ['{props}', {href: '{url}', ref: '{ref}'}], style: [{display: 'flex', flexDirection: 'column', textDecoration: 'none', color: 'black'}, '{props.style}'], children: '{props.text || props.children}'},
          // {type: 'ReactNativePressable', platforms: ['cross-platform'], props: ['{props}', {href: '{url}'}]}
        ]
      },
      {
        type: 'Component',
        name: 'Image',
        supportsChildren: false,
        forwardRef: true,
        imports: [
          {import: {Image: {alias: 'ReactNativeImage'}}, from: 'react-native', platforms: ['cross-platform']}
        ],
        propTypes: [
          {name: 'source', defaultValue: 'https://domain.com/image.jpg'},
          {name: 'alt', exampleValue: 'Description of image'},
        ],
        expressions: [
          {type: 'var', var: {source: {}, restProps: '...'}, value: '{props}'}
        ],
        children: [
          {type: 'img', platforms: ['web'], props: [{src: '{source}'}, '{restProps}', {ref: '{ref}'}]},
          {type: 'ReactNativeImage', platforms: ['cross-platform'], props: ['{props}', {ref: '{ref}'}]}
        ]
      },
      {
        type: 'Component',
        name: 'Button',
        forwardRef: true,
        imports: [
          {import: {Pressable: {alias: 'ReactNativePressable'}}, from: 'react-native', platforms: ['cross-platform']}
        ],
        propTypes: [
          {name: 'onPress', defaultValue: '{() => console.log(\'press\')}'},
          // {name: 'text', exampleValue: 'Click here'},
        ],
        expressions: [
          {type: 'var', var: {onPress: {}, text: {}, restProps: '...'}, value: '{props}'},
        ],
        children: [
          {type: 'button', platforms: ['web'], style: [{
            display: 'flex',
            flexDirection: 'column',
            appearance: 'none',
            backgroundColor: 'transparent',
            border: 'none',
            padding: '0px',
            cursor: 'pointer'
          }, '{props.style}'], props: [{onClick: '{onPress}'}, '{restProps}', {ref: '{ref}'}], children: '{props.children}'},
          {type: 'ReactNativePressable', style: ['{props.style}'], platforms: ['cross-platform'], props: ['{props}', {onPress: '{onPress}', ref: '{ref}'}], children: '{props.children}'}
        ]
      },
      {
        type: 'Component',
        name: 'ScrollView',
        forwardRef: true,
        imports: [
          {import: {ScrollView: {alias: 'ReactNativeScrollView'}}, from: 'react-native', platforms: ['cross-platform']}
        ],
        propTypes: [
          {name: 'contentContainerStyle', defaultValue: {}},
          // {name: 'horizontal', defaultValue: false},
          // {name: 'scrollEnabled', defaultValue: true},
          // {name: 'showsHorizontalScrollIndicator', defaultValue: false},
          // {name: 'showsVerticalScrollIndicator', defaultValue: false},
        ],
        expressions: [
          {type: 'var', var: {contentContainerStyle: {}, horizontal: {}, restProps: '...'}, value: '{props}'}
        ],
        children: [
          {type: 'div', platforms: ['web'], props: ['{restProps}', {ref: '{ref}'}], style: [{overflow: 'auto'}, '{props.style}'], children: [
            {type: 'div', style: [{display: 'flex', flexDirection: '{horizontal ? "row" : "column"}'}, '{contentContainerStyle}'], children: '{props.children}'}
          ]},
          {type: 'ReactNativeScrollView', platforms: ['cross-platform'], props: ['{props}', {ref: '{ref}'}], style: [{flexGrow: 0}, '{props.style}'], children: '{props.children}'}
        ]
      }
    ]
  },
  {
    name: 'av',
    displayName: 'Audio/Video',
    dependencies: {
      'expo-av': {platforms: ['cross-platform'], version: ''},
    },
    platforms: ['web', 'cross-platform'],
    components: [
      {
        type: 'Component',
        name: 'Video',
        allowChildren: false,
        imports: [
          {import: {Video: {alias: 'ExpoVideo'}}, from: 'expo-av', platforms: ['cross-platform']}
        ],
        propTypes: [
          {name: 'source', defaultValue: 'https://domain.com/video.mp4'},
          {name: 'sourceType', defaultValue: 'mp4'},
          {name: 'controls'},
          {name: 'loop'},
          {name: 'muted'},
          {name: 'autoPlay'},
          {name: 'restProps'},
        ],
        expressions: [
          {type: 'var', var: {source: {}, sourceType: {}, restProps: '...'}, value: '{props}'}
        ],
        children: [
          {type: 'video', platforms: ['web'], props: ['{restProps}'], children: [
            {type: 'source', props: [{src: '{source}', type: '{sourceType}'}]}
          ]},
          // {type: 'ReactNativeVideo', platforms: ['cross-platform'], props: ['{props}', {controls: '{controls}', loop: '{loop}', muted: '{muted}', autoplay: '{autoplay}'}]}
        ]
      }
    ]
  },
  {
    name: '3d',
    displayName: '3D',
    dependencies: {
      '@react-three/fiber': {},
      'three': {},
    },
    type: 'component-library',
    platforms: ['web'],
    helpers: {
      getPosition: '{(props) => []}'
    },
    components: [
    //var {FontLoader} = await import('three/examples/jsm/loaders/FontLoader');
    //var {TextGeometry} = await import('three/examples/jsm/geometries/TextGeometry');
    //var roboto = await import('./roboto.json');
    // extend({TextGeometry});}`,
      {
        type: 'Component',
        name: 'Canvas3D',
        imports: [
          {import: {Canvas: {}, extend: {}, useFrame: {}, useThree: {}}, from: '@react-three/fiber', platforms: ['web', 'cross-platform']},
          {import: {OrbitControls: {}}, from: 'three/examples/jsm/controls/OrbitControls', platforms: ['web', 'cross-platform']},
          {import: '*', alias: 'THREE', from: 'three', platforms: ['web', 'cross-platform']},
        ],
        setup: `{extend({OrbitControls});

const CameraControls = ({cameraMouseControls}) => {
  var {camera, gl: { domElement }} = useThree();
  var controls = useRef();

  var mouseButtons = {
    LEFT: THREE.MOUSE.ROTATE,
    MIDDLE: THREE.MOUSE.DOLLY,
    RIGHT: THREE.MOUSE.PAN
  };

  if (cameraMouseControls) {
    mouseButtons = {
      LEFT: THREE.MOUSE.PAN,
      MIDDLE: THREE.MOUSE.PAN,
      RIGHT: THREE.MOUSE.PAN
    };
  }

  useFrame(() => controls.current.update());

  return <orbitControls ref={controls} args={[camera, domElement]} {...{mouseButtons}}/>;
};

const SetCamera = ({canvasRef, effectComposerRef, position = [100, 100, 50]}) => {
  var [cameraSet, setCameraSet] = useState(false);

  useThree((three) => {
    if (!cameraSet) {
      setCameraSet(true);

      var {camera} = three;

      camera.fov *= 1;
      camera.far = 200000;
      camera.zoom = 1; //1.4;
      camera.position.set(position[0], position[1], position[2]);
      camera.wireframe = true;
      camera.lookAt([0, 0, 0]);
      camera.updateProjectionMatrix();
    }

    three.gl.toneMapping = THREE.NoToneMapping //HINT important to get accurate colors
  });

  return null;
};

function Canvas3D({children, cameraPosition, cameraMouseControls, ...props}) {
  //<View style={componentProps.style}>

  return (
    <Canvas
      colormanagement={'false'}
      dpr={Math.max(window.devicePixelRatio, 2)}
      antialias='true'
      logarithmicdepthbuffer='true'
      {...props}
    >
      <CameraControls {...{cameraMouseControls}}/>
      <SetCamera position={cameraPosition}/>
      {children}
    </Canvas>
  );
  // </View>
}}`
      },
      // Group3D: {},
      {
        type: 'Component',
        name: 'Light3D',
        setup: `{function Light3D({lightType, ...props}) { //TODO
  if (lightType === 'ambient') {
    return (
      <ambientLight intensity={0.5} color='#fff'/>
    );
  }
  // else if (lightType === 'directional') {
  //   return (
  //     <directionalLight
  //       position={position} intensity={0.5} color={'#fff'} castShadow shadow-radius={0} shadow-mapSize-height={4096} shadow-mapSize-width={4096}
  //       shadow-camera-left={-4000} shadow-camera-right={4000} shadow-camera-top={4000} shadow-camera-bottom={-4000} shadow-camera-fov={1} shadow-camera-far={2000}
  //       //TODO
  //     />
  //   );
  // }
}}`,
      },
      {
        type: 'Component',
        name: 'Cube3D',
        expressions: [
          {type: 'var', var: {x: {}, y: {}, z: {}, width: {}, depth: {}, height: {}, rotationY: {}, fill: {}, opacity: {}, restProps: '...'}, value: '{props}'},
          {type: 'var', var: 'dimensions', value: '{[width || 0, height || 0, depth || 0]}'},
          {type: 'var', var: 'position', value: '{[x || 0, y || 0, z || 0]}'},
        ],
        children: [
          {type: 'group', props: [{position: '{position}', 'rotation-y': '{rotationY}'}, '{restProps}'], children: [
            {type: 'mesh', props: {castShadow: true, receiveShadow: true}, children: [
              {type: 'boxGeometry', props: {attach: 'geometry', args: '{dimensions}'}},
              {type: 'meshLambertMaterial', props: {attach: 'material', color: '{fill}', opacity: '{opacity}', transparent: '{!!opacity}'}}
            ]}
          ]}
        ]
      }
    ]
  },
  /*{
    title: 'React Router',
    dependencies: {
      'react-router-dom': {platforms: ['web'], version: ''},
      'react-router-native': {platforms: ['cross-platform'], version: ''},
    },
    type: 'component-library',
    platform: 'hybrid',
    components: {
      Route: {
        imports: [
          {import: {Route: {alias: 'ReactRouterRoute'}}, from: 'react-router-dom', platforms: ['web']},
          {import: {Route: {alias: 'ReactRouterRoute'}}, from: 'react-router-native', platforms: ['cross-platform']}
        ],
        propSpec: [
          {name: 'path', types: ['string', 'array']},
          {name: 'exact', types: ['boolean']},
          {name: 'strict', types: ['boolean']},
          {name: 'sensitive', types: ['boolean']},
          {name: 'component', types: ['string']},
          {name: 'render', types: ['string']},
          {name: 'children', types: ['string']},
        ],
        children: [
          {type: 'ReactRouterRoute', props: '{props}'}
        ]
      },
      RouteSwitch: {
        imports: [
          {import: {Switch: {alias: 'ReactRouterSwitch'}}, from: 'react-router-dom', platforms: ['web']},
          {import: {Switch: {alias: 'ReactRouterSwitch'}}, from: 'react-router-native', platforms: ['cross-platform']}
        ],
        children: [
          {type: 'ReactRouterSwitch', props: '{props}'}
        ]
      }
    }
  }*/
  //routing


  // {type: 'Canvas2D', category: '2D', hasStyles: true, hasChildren: true, defaultProps: {width: 400, height: 400, style: {width: 400, height: 400}}},
  // {type: 'Group', category: '2D', hasChildren: true},
  // {type: 'Rect', category: '2D'},
  // {type: 'Image', category: '2D'},
  // {type: 'Circle', category: '2D'},
  // {type: 'Ellipse', category: '2D'},
  // {type: 'Line', category: '2D'},
  // {type: 'Text', category: '2D'},
  // {type: 'Path', category: '2D'},
];

export default componentLibraries;
