import React, { useEffect, useState } from 'react';
import { View, Pressable, ScrollView } from 'react-native';
import { Text } from '@symbolic/rn-lib';

import useKeyDown from '~/helpers/useKeyDown';
import K from '~/k';

var labelStyle = {color: 'rgba(0, 0, 0, 0.4)', textTransform: 'lowercase', letterSpacing: '0.05em', fontSize: 11};

export default function Suggestions({inputValue, groups, style, hasSelectedSuggestionRef, ...props}) {
  var [activeKeyIndex, setActiveKeyIndex] = useState(0);
  var [suggestionInfoIsShowing, setSuggestionInfoIsShowing] = useState(false);

  inputValue = inputValue + '';

  var onSelect = (...args) => {
    hasSelectedSuggestionRef.current = true;

    setTimeout(() => hasSelectedSuggestionRef.current = false, 10);

    props.onSelect(...args);
  };

  useKeyDown((event) => {
    if (event.key === 'Escape' && activeKeyIndex >= 0) {
      setActiveKeyIndex(-1);

      global.stopPropagation = true;

      setTimeout(() => global.stopPropagation = false, 10);
    }
  }, {capture: true});

  groups = _.map(groups, group => ({
    ...group,
    keys: _.filter(group.keys, ({key}) => {
      if (!inputValue) return true;
      if (_.includes(key.toLowerCase(), inputValue.toLowerCase())) return true;

      var words = _.map(_.split(inputValue, ' '), value => _.trim(value));

      if (words.length > 1 && _.every(words, word => _.includes(key.toLowerCase(), word))) return true;
    })
  }));

  groups = _.filter(groups, group => group.keys.length > 0);

  var allKeys = _.flatMap(groups, 'keys');

  var netIndex = 0;

  useKeyDown((event) => {
    if (event.key === 'ArrowDown') {
      if ((activeKeyIndex || 0) < allKeys.length) {
        setActiveKeyIndex(activeKeyIndex === null ? 0 : activeKeyIndex + 1);

        event.preventDefault();
        event.stopPropagation();
      }
    }
    else if (event.key === 'ArrowUp') {
      if ((activeKeyIndex || 0) > 0) {
        setActiveKeyIndex(activeKeyIndex === null ? 0 : activeKeyIndex - 1);

        event.preventDefault();
        event.stopPropagation();
      }
    }
    else if (event.key === 'Enter' || (event.key === 'Tab' && !event.shiftKey)) {
      if (activeKeyIndex !== null && allKeys[activeKeyIndex]) onSelect(allKeys[activeKeyIndex]);
    }
  }, {capture: true});

  useEffect(() => {
    var newActiveKeyIndex = 0;

    if (activeKeyIndex !== newActiveKeyIndex) setActiveKeyIndex(newActiveKeyIndex);
  }, [inputValue]);

  if (groups.length === 1 && groups[0].keys.length === 1 && groups[0].keys[0].key.toLowerCase() === inputValue.toLowerCase()) return null;

  return !!allKeys.length && (
    <View style={{position: 'absolute', top: 'calc(100% + 4px)', width: '100%', left: -K.spacing, maxWidth: 300, backgroundColor: 'white', borderRadius: 5, ...K.shadow, overflow: 'hidden', zIndex: 1000, ...style}}>
      <ScrollView
        style={{maxHeight: 200, width: '100%'}}
        contentContainerStyle={{padding: K.spacing, paddingVertical: K.spacing, paddingTop: K.spacing}}
      >
        {_.map(groups, (group, index) => {
          return (
            <View key={group.title} style={{marginBottom: index === groups.length - 1 ? 0 : K.spacing}}>
              {!!group.title && (
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <Text style={{fontFamily: 'HelveticaNeue-Medium', marginBottom: 6, marginLeft: K.spacing, fontSize: 13}}>{group.title}</Text>
                  <View style={{flex: 1}}/>
                  {index === 0 && (
                    <Pressable
                      focusable={false}
                      onMouseDown={(event) => {
                        setSuggestionInfoIsShowing(!suggestionInfoIsShowing);

                        event.preventDefault();
                      }}
                      style={{marginRight: K.spacing, alignItems: 'center', justifyContent: 'center', position: 'relative', top: -2}}
                    >
                      <Text style={{...labelStyle}}>{!suggestionInfoIsShowing ? 'show info' : 'hide info'}</Text>
                    </Pressable>
                  )}
                </View>
              )}
              {_.map(group.keys, ({key, description, defaultValue}, index) => {
                var isActive = netIndex === activeKeyIndex;

                netIndex += 1;

                return (
                  <Pressable
                    focusable={false}
                    style={{minHeight: 20, lineHeight: 14, paddingTop: 2, paddingBottom: 3, display: 'block', borderRadius: 10, backgroundColor: isActive ? 'black' : K.colors.gray, paddingHorizontal: K.spacing, justifyContent: 'center', marginBottom: index === group.keys.length - 1 ? 0 : 3}}
                    key={key}
                    onMouseDown={() => onSelect({key, defaultValue})}
                    dataSet={{hoverableGray: 1}}
                  >
                    <Text style={{display: 'inline', lineHeight: 14, color: isActive ? 'white' : 'black'}}>{key}</Text>
                    {suggestionInfoIsShowing && description && (
                      <Text style={{display: 'inline', opacity: isActive ? 0.7 : 0.5, lineHeight: 14, color: isActive ? 'white' : 'black'}}>{' ' + description}</Text>
                    )}
                  </Pressable>
                );
              })}
            </View>
          );
        })}
      </ScrollView>
    </View>
  );
}
