import React, { useEffect, useState, useRef } from 'react';
import { SortableTree } from 'dnd-kit-sortable-tree';

import SelectedItemPathsContext from '~/contexts/SelectedItemPathsContext';
import AutoFocusDataContext from '~/contexts/AutoFocusDataContext';
import useKeyDown from '~/helpers/useKeyDown';
import lib from '@symbolic/lib';
import WrappedJconNode from '~/components/JconEditor/JconNode';

export default function JconEditor({value, schema, onChange}) {
  var [items, setItems] = useState([]);
  var [selectedItemPaths, setSelectedItemPaths] = useState([]);
  var [autoFocusData, setAutoFocusData] = useState({});

  var triggerChangeRef = useRef();

  triggerChangeRef.current = (callback) => {
    var clonedValue = _.cloneDeep(value);

    clonedValue = callback(clonedValue) || clonedValue;

    onChange({value: clonedValue});
    setSelectedItemPaths([]);
  };

  var valueRef = useRef();

  valueRef.current = value;

  var triggerChange = (callback) => {
    triggerChangeRef.current(callback);
  };

  var getOnChange = (callback, valuePath) => {
    return (arg) => {
      if (typeof(arg) === 'object') {
        callback(arg);
      }
      else {
        callback(arg(_.get(valueRef.current, valuePath)));
      }
    };
  };

  useKeyDown(event => {
    var inputIsFocused = _.includes(['INPUT', 'TEXTAREA', 'SELECT'], document.activeElement.tagName) || document.activeElement.contentEditable === 'true';

    if (event.key === 'Escape' && selectedItemPaths.length) {
      setSelectedItemPaths([]);

      global.stopPropagation = true;

      setTimeout(() => global.stopPropagation = false, 10);
    }
    else if (event.key === 'Backspace' && !inputIsFocused) {
      if (selectedItemPaths.length) {
        global.stopPropagation = true;

        if (confirm(`Are you sure you want to delete ${selectedItemPaths.length > 1 ? `these ${selectedItemPaths.length} attributes` : 'this attribute'}?`)) {
          setTimeout(() => global.stopPropagation = false, 10);

          triggerChange(clonedValue => {
            _.forEach(selectedItemPaths, path => {
              deleteJconNode({path, clonedValue});
            });
          });
        }
      }
    }
  }, {capture: true});

  var deleteJconNode = ({path, clonedValue}) => {
    var parentPath = _.split(path, '.');

    parentPath.pop();

    parentPath = parentPath.join('.');

    var parent = parentPath ? _.get(clonedValue, parentPath) : clonedValue;

    //if path ends in number
    if (Array.isArray(parent)) {
      _.pullAt(parent, parseInt(_.last(_.split(path, '.'))));
    }
    else {
      _.unset(clonedValue, path);
    }
  };

  var togglePathSelectedRef = useRef();

  togglePathSelectedRef.current = ({path, event}) => {
    if (lib.event.keyPressed(event, 'ctrlcmd')) {
      selectedItemPaths = _.cloneDeep(selectedItemPaths);

      if (_.includes(selectedItemPaths, path)) {
        _.pull(selectedItemPaths, path);
      }
      else {
        selectedItemPaths.push(path);
      }

      setSelectedItemPaths(selectedItemPaths);
    }
    else {
      _.includes(selectedItemPaths, path) ? setSelectedItemPaths([]) : setSelectedItemPaths([path]);
    }
  };

  var togglePathSelected = args => {
    togglePathSelectedRef.current(args);
  };

  useEffect(() => {
    var flatIndex = 0;

    //type picker when multiple options
    var valueToItems = (value, {schema, path}) => {
      var items = [];

      var getTypeOfValue = (value, {schema}) => {
        return _.find(schema, ({type}) => {
          if (type === 'ExpressionObject') {
            if (typeof value === 'object' && value !== null && value.type) return true;
          }
          else if (type === 'StyleCollection') {
            return typeof(value) === 'object' && value.style;
          }
          else if (type === 'Array') {
            if (Array.isArray(value)) return 'Array';
          }
          else if (type === 'Object') {
            if (typeof value === 'object' && value !== null) return 'Object';
          }
          else if (type === 'Script') {
            if (typeof value === 'string' && value.startsWith('{') && value.endsWith('}')) return 'Script';
          }
          else if (type === 'CSSString') {
            return typeof value === 'string' && !(value.startsWith('{') && value.endsWith('}'));
          }
          else if (type === 'Value') {
            return 'Value';
          }
        })?.type || 'Value';
      };

      if (value !== undefined) {
        if (Array.isArray(value) && _.find(schema, {type: 'Array'})) {
          var arraySchema = _.find(schema, {type: 'Array'});

          schema = _.find(schema, {type: 'Array'}).children;

          _.forEach(value, (value, index) => {
            var type = getTypeOfValue(value, {schema});
            var itemPath = (path ? path + '.' : '') + index;

            flatIndex += 1;

            items.push({
              flatIndex, type, value, togglePathSelected,
              id: itemPath,
              path: itemPath,
              typeOptions: arraySchema.typeOptions,
              canHaveChildren: _.includes(['Object', 'Array', 'StyleCollection'], type),
              autoFocusPath: autoFocusData.path === itemPath ? autoFocusData.type : null,
              isSelected: _.includes(selectedItemPaths, itemPath),
              onChange: getOnChange(({value: newValue, changeType, autoFocus, autoFocusType, backspace}) => {
                triggerChange(clonedValue => {
                  if (backspace) {
                    (path ? _.get(clonedValue, path) : clonedValue).splice(index, 1);
                  }
                  else if (type === 'Script' || !changeType) {
                    (path ? _.get(clonedValue, path) : clonedValue)[index] = newValue;
                  }
                  else if (type === 'ExpressionObject') {
                    if (autoFocus) {
                      setAutoFocusData({path: itemPath, type: autoFocusType});
                    }

                    _.set(clonedValue, (itemPath ? itemPath + '.' : '') + changeType, newValue);
                  }
                });
              }, itemPath),
              delete: () => {
                triggerChange(clonedValue => {
                  deleteJconNode({path: itemPath, clonedValue});
                });
              },
              ...(_.includes(['Object', 'Array', 'StyleCollection'], type) ? {children: valueToItems(type === 'StyleCollection' ? value.style : value, {schema, parentType: type, path: itemPath + (type === 'StyleCollection' ? '.style' : '')})} : {})
            });
          });

          flatIndex += 1;

          items.push({
            flatIndex, schema, path,
            id: path + 'Array',
            type: 'Array',
            typeOptions: arraySchema.typeOptions,
            canHaveChildren: false,
            isAddButton: true,
            disableSorting: true,
            create: ({value: newValue}) => {
              if (newValue && newValue.type) {
                setAutoFocusData({path: (path ? path + '.' : '') + (value.length), type: newValue.type === 'effect' ? 'value' : 'var'});
              }

              triggerChange(clonedValue => {
                (path ? _.get(clonedValue, path) : clonedValue).push(_.cloneDeep(newValue));
              });
            }
          });
        }
        else if (typeof value === 'object' && value !== null && _.find(schema, {type: 'Object'})) {
          var objectSchema = _.find(schema, {type: 'Object'});

          schema = objectSchema.children;

          _.forEach(value, (value, key) => {
            var itemPath = (path ? path + '.' : '') + key;

            flatIndex += 1;

            items.push({
              key, value, flatIndex, togglePathSelected,
              id: itemPath,
              type: 'KeyValuePair',
              suggestionGroups: objectSchema.suggestionGroups,
              path: itemPath,
              canHaveChildren: false,
              autoFocusPath: autoFocusData.path === itemPath ? autoFocusData.type : null,
              isSelected: _.includes(selectedItemPaths, itemPath),
              onChange: getOnChange(({value: newValue, changeType, autoFocus, autoFocusType, backspace}) => {
                triggerChange(clonedValue => {
                  if (changeType === 'key') {
                    var newPath = (path ? path + '.' : '') + newValue;

                    _.unset(clonedValue, itemPath);

                    if (newValue) _.set(clonedValue, newPath, value);

                    if (autoFocus) {
                      setAutoFocusData({path: newPath, type: autoFocusType || 'key'});
                    }
                  }
                  else {
                    if (backspace) {
                      _.unset(clonedValue, itemPath);
                    }
                    else {
                      if (autoFocus) {
                        setAutoFocusData({path: itemPath, type: 'value'});
                      }

                      _.set(clonedValue, itemPath, newValue);
                    }
                  }
                });
              }, itemPath),
              delete: () => {
                triggerChange(clonedValue => {
                  deleteJconNode({path: itemPath, clonedValue});
                });
              }
            });
          });

          flatIndex += 1;

          items.push({
            path, schema, flatIndex,
            id: path + 'Object',
            type: 'Object',
            canHaveChildren: false,
            isAddButton: true,
            suggestionGroups: objectSchema.suggestionGroups,
            disableSorting: true,
            create: ({key, value = ''}) => {
              key = _.trim(key).replace(/:$/, '').replace(/=$/, '');

              setAutoFocusData({path: (path ? path + '.' : '') + key, type: 'value'});

              triggerChange(clonedValue => {
                _.set(clonedValue, (path ? (path + '.') : '') + key, value);
              });
            }
          });
        }
      }

      return items;
    };

    setItems(valueToItems(value, {schema, path: ''}));
  }, [value, selectedItemPaths, autoFocusData]);

  return (
    <SelectedItemPathsContext.Provider value={selectedItemPaths}>
      <AutoFocusDataContext.Provider value={autoFocusData}>
        <SortableTree
          items={items}
          dropAnimation={null}
          sortableProps={{animateLayoutChanges: () => false}}
          pointerSensorOptions={{
            activationConstraint: {
              distance: 1,
            }
          }}
          onItemsChanged={async (items, {draggedItem}) => {
            if (draggedItem) {
              var schemaIsValid = true;

              var itemToValue = ({value, item, items, schema}) => {
                var originalValue = value;

                if (item && item.type === 'StyleCollection') value = value.style;

                var newValue = value;

                items = _.reject(items, 'isAddButton');

                if (schema && Array.isArray(value) && _.find(schema, {type: 'Array'})) {
                  newValue = [];

                  _.forEach(items, item => {
                    newValue.push(itemToValue({value: item.value, item, items: item.children, schema: _.find(schema, {type: 'Array'}).children}));
                  });
                }
                else if (schema && !Array.isArray(value) && typeof value === 'object' && value !== null && _.find(schema, {type: 'Object'})) { //object
                  newValue = {};

                  _.forEach(items, item => {
                    newValue[item.key] = item.value;

                    if (!item.key) schemaIsValid = false;
                  });

                  if (item && item.type === 'StyleCollection') newValue = {...originalValue, style: newValue};
                }
                else if (schema && item && !_.find(schema, {type: item.type})) {
                  schemaIsValid = false;
                }

                return newValue;
              };

              var newValue = itemToValue({value, item: null, items, schema});

              if (schemaIsValid) {
                setItems(items);
                setSelectedItemPaths([]);
                onChange({value: newValue});
              }
              else {
                alert('Oops! It looks like something got dropped in the wrong place - please try again.');
              }
            }
          }}
          TreeItemComponent={WrappedJconNode}
          // {...{activeNodePath, setActiveNode}}
        />
      </AutoFocusDataContext.Provider>
    </SelectedItemPathsContext.Provider>
  );
}
