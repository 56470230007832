import React, { useState, useContext, useRef } from 'react';
import { FolderTreeItemWrapper } from 'dnd-kit-sortable-tree';
import { View, Pressable, Image } from 'react-native';
import { Text, TextInput } from '@symbolic/rn-lib';
import LabelWidthContext from '~/contexts/LabelWidthContext';
import K from '~/k';
import Suggestions from '~/components/Suggestions';
import ValueInput from '~/components/ValueInput';
import JconNodeHeader from '~/components/JconEditor/JconNodeHeader';
import CodeInput from '~/components/CodeInput';
import getArePropsEqual from '~/helpers/getArePropsEqual';
import ContextMenu from '~/components/ContextMenu';
import getIsCode from '~/helpers/getIsCode';

var inputStyle = {minHeight: 20, height: 20, borderRadius: 10, backgroundColor: K.colors.gray, paddingLeft: 12};

var getIsShortValue = (value) => {
  return (value + '').length < (typeof(value) === 'string' ? 17 : 10) && !_.includes(value + '', '\n');
};

var JconNode = ({item, isFirst, ...props}) => {
  var {labelWidth,} = props;
  var children = null;
  var {value, type, onChange} = item;
  var hasSelectedSuggestionRef = useRef(false);
  var addInputRef = useRef();
  var activeInputLabelRef = useRef();
  var addButtonPressableRef = useRef();
  var [activeInputLabel, setActiveInputLabel] = useState(null);
  var [isFocused, setIsFocused] = useState(false);
  var [inputValue, setInputValue] = useState('');
  var [addOptionsAreShowing, setAddOptionsAreShowing] = useState(false);

  var labelStyle = {color: 'rgba(0, 0, 0, 0.4)', textTransform: 'uppercase', fontSize: 10, letterSpacing: '0.1em', fontWeight: 500, fontFamily: 'Work Sans'};

  activeInputLabelRef.current = activeInputLabel;

  // useEffect(() => {
  //   setInputValue()
  // }, [path]);

  var getValueType = (value) => {
    var valueType = 'string';

    if (typeof(value) === 'string' && _.startsWith(value, '{') && _.endsWith(value, '}')) valueType = 'script';
    else if (typeof(value) === 'string') valueType = 'string';
    else if (typeof(value) === 'number') valueType = 'number';
    else if (typeof(value) === 'boolean') valueType = 'boolean';
    else if (value === null) valueType = 'null';
    else if (Array.isArray(value)) valueType = 'array';
    else if (typeof(value) === 'object' && value !== null) valueType = 'object';

    return valueType;
  };

  var getDefaultValue = (type) => {
    var typeOption = _.find(item.typeOptions, {type});

    if (typeOption.getDefaultValue) {
      return typeOption.getDefaultValue(value);
    }
    else {
      return typeOption.defaultValue;
    }
  };

  var contextMenuActions = [
    {onPress: item.delete, label: 'Delete', hotkey: `${K.isMac ? '⌘' : '⌘'} ⌫`}
  ];

  if (item.isAddButton) {
    var typeOptions = _.map(item.typeOptions, typeOption => {
      if (typeOption.type === 'Script') return {...typeOption, color: '#dfa862'};
      if (typeOption.type === 'Object' || typeOption.type === 'object') return {...typeOption, color: '#99aede'};

      return typeOption;
    });

    children = (
      <View style={{flexDirection: 'row', position: 'relative', justifyContent: 'space-between', borderRadius: 10, ...(item.type === 'Array' ? {marginTop: 10} : {})}}>
        {item.type === 'Array' ? (<>
          <Pressable
            ref={addButtonPressableRef}
            focusable={false}
            onMouseDown={() => addOptionsAreShowing && setTimeout(() => addButtonPressableRef.current.blur())}
            onFocus={() => setAddOptionsAreShowing(true)} onBlur={() => setAddOptionsAreShowing(false)}
            dataSet={{hoverableGray: 1}}
            style={{...inputStyle, marginRight: K.margin, textAlign: 'center', paddingLeft: 0, alignItems: 'center', justifyContent: 'center', width: 30, ...(addOptionsAreShowing ? {backgroundColor: '#eee'} : {})}}
          >
            <Text style={{opacity: 0.6, width: 10, height: 10, fontSize: 11.7, marginTop: -4}}>+</Text>
          </Pressable>
          <View style={{position: 'absolute', top: '100%', marginTop: 5, backgroundColor: 'white', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)', borderRadius: 5, paddingVertical: 7}}>
            {addOptionsAreShowing && _.map(typeOptions, typeOption => (
              <Pressable style={{paddingVertical: 3, paddingHorizontal: K.spacing}} onMouseDown={() => item.create({value: getDefaultValue(typeOption.type)})}>
                <Text style={{...labelStyle, color: typeOption.color}}>+ {typeOption.title || _.startCase(typeOption.type)}</Text>
              </Pressable>
            ))}
          </View>
          {/* <View style={{flex: 1}}/>
          <JconTypePicker
            value={newValueType}
            typeOptions={_.map(item.typeOptions, ({title, type, color}) => ({type, title: title || _.startCase(type), color}))}
            onChange={({value}) => {
              setNewValueType(value);
            }}
          /> */}
        </>) : (<>
          <View style={{flex: 1, flexDirection: 'row'}}>
            <TextInput
              style={{...inputStyle, flex: 1, fontStyle: 'italic'}}
              dataSet={{newKeyInput: 1, hoverableGray: 1}}
              getInputRef={ref => addInputRef.current = ref}
              placeholder='+'
              placeholderTextColor='rgba(0, 0, 0, 1)'
              value={inputValue}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setTimeout(() => {
                setIsFocused(false);

                setTimeout(() => {
                  if (!hasSelectedSuggestionRef.current && inputValue) {
                    item.create({key: inputValue});

                    setInputValue('');
                  }
                });
              })}
              onInput={({value}) => {
                setInputValue(value);

                if (_.endsWith(value, ':') || _.endsWith(value, '=')) {
                  addInputRef.current.blur();

                  // event.preventDefault();
                }
              }}
              onChange={({value}) => {
                setTimeout(() => {
                  if (!hasSelectedSuggestionRef.current) {
                    item.create({key: value});

                    setInputValue('');
                  }
                });
              }}
            />
          </View>
          {item.suggestionGroups && isFocused && (
            <Suggestions
              // style={{left: 'auto', right: 0}}
              groups={item.suggestionGroups}
              inputValue={inputValue}
              hasSelectedSuggestionRef={hasSelectedSuggestionRef}
              keyStyle={{fontStyle: 'italic'}}
              onSelect={({key: value, defaultValue}) => {
                item.create({key: value, ...(defaultValue !== undefined ? {value: defaultValue} : {})});

                setInputValue('');
              }}
            />
          )}
        </>)}
      </View>
    );
  }
  else if (type === 'KeyValuePair') {
    var valueType = getValueType(value);
    var inline = getIsShortValue(item.key) && getIsShortValue(item.value) && (!getIsCode(item.value) || _.includes([null, false, true], item.value));
    var suggestedValues = _.find(_.flatMap(item.suggestionGroups, 'keys'), {key: item.key})?.values;

    children = (
      <View style={{position: 'relative', ...(inline ? {flexDirection: 'row', flex: 1} : {})}}>
        <ValueInput
          contextMenuActions={contextMenuActions}
          value={item.key}
          dataSet={{hoverableGray: 1}}
          // valueType={keyType}
          style={{fontStyle: 'italic', height: 20, borderRadius: 10, ...(inline ? {width: labelWidth, borderTopRightRadius: 0, borderBottomRightRadius: 0} : {alignSelf: 'stretch', borderBottomRightRadius: 0, borderBottomLeftRadius: 0})}}
          position={inline ? 'left' : 'top'}
          selectTextOnFocus
          disableTypeSwitching
          shorten
          autoFocus={item.autoFocusPath === 'key'}
          suggestionGroups={item.suggestionGroups}
          onChange={({value, pickedSuggestion, backspace}) => {
            if (_.endsWith(value, ':') || _.endsWith(value, '=')) {
              value = value.slice(0, -1);
            }
            console.log(pickedSuggestion ? {autoFocus: true, autoFocusType: 'value'} : {});
            onChange({value, changeType: 'key', ...(pickedSuggestion ? {autoFocus: true, autoFocusType: 'value'} : {}), backspace});
          }}
        />
        <View style={{backgroundColor: '#ededed', ...(inline ? {width: 1} : {height: 1})}}/>
        <ValueInput
          {...{valueType, contextMenuActions}}
          value={item.value}
          wrapperStyle={{...(inline ? {flex: 1} : {alignSelf: 'stretch'})}}
          style={{...(inline ? {minWidth: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0} : {borderTopLeftRadius: 0, borderTopRightRadius: 0})}}
          position={inline ? 'right' : 'bottom'}
          right
          shorten
          autoFocus={item.autoFocusPath === 'value'}
          suggestionGroups={suggestedValues ? [{title: 'Suggestions', keys: _.map(suggestedValues, value => ({key: value}))}] : null}
          onChange={({value, autoFocus, backspace}) => {
            if (_.includes(['provideContexts', 'useContexts'], item.path) && Array.isArray(value)) {
              value = _.sortBy(value, string => string);
            }

            onChange({value, changeType: 'value', autoFocus, backspace});
          }}
        />
      </View>
    );
  }
  else if (item.type === 'ExpressionObject') {
    var valueKey = {var: 'value', state: 'initialState', ref: 'initialValue', effect: 'setup'}[value.type];
    var valueType = getValueType(value[valueKey]);
    var varType = getValueType(value.var);
    var inline = false; //getIsShortValue(value.type === 'effect' ? value.dependencies : value.var) && getIsShortValue(value[valueKey]);
    var varInputLabel = (value.type === 'var' ? '' : 'variable ') + 'name' + (_.includes(['script', 'object', 'array'], varType) ? 's' : '');

    if (value.type === 'effect') {
      var dependencies = value.dependencies || [];

      dependencies = '{[' + _.map(dependencies, (dependency) => {
        if (_.startsWith(dependency, '{') && _.endsWith(dependency, '}')) {
          return dependency.replace(/^\{/, '').replace(/\}$/, '');
        }

        return dependency;
      }).join(', ') + ']}';
    }

    children = (<>
      <JconNodeHeader activeInputLabel={activeInputLabel} isExpressionObject type={value.type} typeOptions={item.typeOptions} onChangeType={({value: type}) => onChange({value: getDefaultValue(type)})}/>
      <View dataSet={{conditionalOpacityParent: 1}} style={{position: 'relative', ...(inline ? {flexDirection: 'row', alignItems: 'center', flex: 1} : {})}}>
        {/* <Text dataSet={{conditionalOpacityChild: 1}} style={{...tooltipStyle, transition: 'opacity 0.5s', top: -15, left: K.spacing}}>name</Text>
        <Text dataSet={{conditionalOpacityChild: 1}} style={{...tooltipStyle, transition: 'opacity 0.5s', top: 'calc(100% + 3px)', left: inline ? labelWidth + K.spacing : K.spacing}}>value</Text> */}
        {value.type !== 'effect' && (<>
          <ValueInput
            contextMenuActions={contextMenuActions}
            value={value.var}
            valueType={varType}
            style={{fontStyle: 'italic', ...(inline ? {width: labelWidth, borderTopRightRadius: 0, borderBottomRightRadius: 0} : {alignSelf: 'stretch', borderBottomRightRadius: 0, borderBottomLeftRadius: 0})}}
            inlineCodeFormatting
            position={inline ? 'left' : 'top'}
            selectTextOnFocus
            autoFocus={item.autoFocusPath === 'var'}
            onFocus={() => setActiveInputLabel(varInputLabel)}
            onBlur={() => setTimeout(() => activeInputLabelRef.current === varInputLabel && setActiveInputLabel(''), 10)}
            onChange={({value: newValue, autoFocus, backspace}) => {
              if (Array.isArray(newValue) && value.type === 'state' && (!Array.isArray(value.var) || (newValue[0] !== value.var[0] && newValue[1] === value.var[1]))) {
                newValue = [newValue[0], `set${_.upperFirst(newValue[0])}`];
              }

              onChange({value: newValue, changeType: 'var', autoFocus, autoFocusType: 'var', backspace});
            }}
          />
          <View style={{backgroundColor: '#ededed', ...(inline ? {width: 1} : {height: 1})}}/>
        </>)}
        <ValueInput
          contextMenuActions={contextMenuActions}
          value={value[valueKey]}
          valueType={valueType}
          wrapperStyle={{...(inline ? {flex: 1} : {alignSelf: 'stretch'})}}
          style={{...(inline ? {flex: 1, minWidth: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0} : {alignSelf: 'stretch', borderTopLeftRadius: 0, borderTopRightRadius: 0})}}
          position={value.type === 'effect' ? (inline ? 'left' : 'top') : (inline ? 'right' : 'bottom')}
          autoFocus={item.autoFocusPath === 'value'}
          onFocus={() => setActiveInputLabel(_.startCase(valueKey))}
          onBlur={() => setTimeout(() => activeInputLabelRef.current === _.startCase(valueKey) && setActiveInputLabel(''))}
          onChange={({value, autoFocus, backspace}) => {
            onChange({value, changeType: valueKey, autoFocus, autoFocusType: 'value', backspace});
          }}
        />
        {value.type === 'effect' && (<>
          <View style={{backgroundColor: '#ededed', ...(inline ? {width: 1} : {height: 1})}}/>
          <ValueInput
            contextMenuActions={contextMenuActions}
            value={dependencies}
            valueType={'script'}
            disableTypeSwitching
            style={{fontStyle: 'italic', ...(inline ? {width: labelWidth, borderTopRightRadius: 0, borderBottomRightRadius: 0} : {alignSelf: 'stretch', borderBottomRightRadius: 0, borderBottomLeftRadius: 0})}}
            inlineCodeFormatting
            position={inline ? 'right' : 'bottom'}
            selectTextOnFocus
            autoFocus={item.autoFocusPath === 'dependencies'}
            onChange={({value, autoFocus, backspace}) => {
              value = value.replace(/^\{\[/, '').replace(/\]\}$/, '').split(',').map(string => {
                return '{' + string.trim() + '}';
              });

              onChange({value, changeType: 'dependencies', autoFocus, autoFocusType: 'dependencies', backspace});
            }}
          />
        </>)}
      </View>
    </>);
  }
  else if (type === 'Object') {
    children = (
      <ContextMenu
        actions={contextMenuActions}
      >
        {(ref, onContextMenu) => (
          <JconNodeHeader
            contextMenuActions={contextMenuActions}
            type={'Object'}
            typeOptions={item.typeOptions}
            contextMenuRef={ref}
            onContextMenu={onContextMenu}
            onChangeType={({value: type}) => onChange({value: getDefaultValue(type)})}
          />
        )}
      </ContextMenu>
    );
  }
  else {
    var valueType = getValueType(value);
    var headerType = type;

    if (type === 'StyleCollection') {
      if (value.format !== undefined) {
        headerType = 'format';
        valueType = 'string';
      }
      else if (value.condition !== undefined) {
        headerType = 'condition';
        valueType = getValueType(value[0]?.condition);
      }
      else if (value.selector !== undefined) {
        headerType = 'selector';
        valueType = 'sass';
      }
      else {
        headerType = 'object';
        valueType = 'object';
      }
    }

    children = (<>
      <ContextMenu
        actions={contextMenuActions}
      >
        {(ref, onContextMenu) => (<>
          <JconNodeHeader
            type={headerType}
            typeOptions={item.typeOptions}
            contextMenuRef={ref}
            onContextMenu={onContextMenu}
            onChangeType={({value: type}) => onChange({value: getDefaultValue(type)})}
          />
          {type === 'CSSString' && (
            <View dataSet={{hoverableGrayInput: 1}} ref={ref} onContextMenu={onContextMenu}>
              <CodeInput
                language={'sass'}
                value={value}
                onChange={({value}) => {
                  onChange({value});
                }}
              />
            </View>
          )}
        </>)}
      </ContextMenu>
      {type !== 'CSSString' && headerType !== 'object' && (
        <ValueInput
          value={type === 'StyleCollection' ? value[headerType] : value}
          shorten={type === 'StyleCollection'}
          contextMenuActions={contextMenuActions}
          valueType={valueType}
          disableTypeSwitching={type === 'Script'}
          onChange={({value: newValue, backspace}) => onChange((oldValue) => ({value: type === 'StyleCollection' ? ({[headerType]: newValue, style: oldValue.style}) : newValue, backspace}))}
        />
      )}
    </>);
  }

  var isFirst = item.depth === 0 && item.index === 0;
  var isSelected = item.isSelected;
  var isObject = type === 'Object' || (type === 'StyleCollection' && value.format === undefined && value.condition === undefined && value.selector === undefined);
  var isTypeAndValue = _.includes(['Script', 'ExpressionObject', 'StyleCollection'], type) && !isObject;
  var isKeyValuePair = type === 'KeyValuePair';

  return (
    <View
      dataSet={{conditionalOpacityParent: 1}}
      style={{minWidth: labelWidth * 2 - 18, position: 'relative', marginLeft: item.children ? 0 : 0, marginBottom: isObject && !item.isAddButton ? 0 : 4, marginTop: !item.isAddButton && (type === 'Script' || type === 'ExpressionObject' || isObject ? (isFirst ? 0 : 0) : 0), width: '100%'}}
    >
      {!item.isAddButton && (
        <div
          {...props.handleProps}
          title='Reorder'
          className={'hoverable-jcon-icon-button'}
          tabIndex={-1}
          data-hoverable={1}
          role=''
          style={{
            position: 'absolute', width: K.spacing + 10, zIndex: 1, cursor: 'move', ...(isObject ? {right: 84} : {left: -K.spacing - 7}), top: (isKeyValuePair || isObject) ? -3 : -2, paddingLeft: 3,
            fontSize: 15, opacity: 0.4,
            paddingTop: isTypeAndValue ? 22 : (isObject ? 3 : 3)
          }}
        >
          <div data-conditional-opacity-child={1}>=</div>
        </div>
      )}
      {children}
      {(!!item.togglePathSelected || isSelected) && !item.isAddButton && (
        <div
          data-conditional-opacity-child={isSelected ? 0 : 1}
          data-select-dot={1}
          data-grow-on-hover={1}
          tabIndex={-1}
          title={isSelected ? 'Deselect' : 'Select'}
          style={{
            position: 'absolute', zIndex: 1, right: -22 + (isObject ? 69 : 4), cursor: 'pointer',
            top: (isTypeAndValue ? 24 : (isObject ? 5 : 4)),
            transition: 'opacity 0.5s, background-color 0.2s', width: isObject ? 10 : 12, height: isObject ? 10 : 12, borderRadius: 6,
            ...(isSelected ? {backgroundColor: 'rgba(0, 0, 0, 0.3)'} : {backgroundColor: 'rgba(0, 0, 0, 0.1)'})
          }}
          onMouseDown={(event) => {
            item.togglePathSelected({path: item.path, event});

            event.preventDefault();

            document.activeElement.blur();
          }}
        />
      )}
      {/* {!item.isAddButton && (
        <div
          title='Delete'
          data-hoverable={1}
          onClick={() => item.delete()}
          style={{
            position: 'absolute', width: K.spacing + 10, zIndex: 1, cursor: 'pointer', right: -23, top: (isKeyValuePair || isObject) ? 0 : 1, paddingLeft: 3,
            fontSize: 11, opacity: 0.6,
            paddingTop: isTypeAndValue ? 22 : (isObject ? 3 : 3)
          }}
        >
          <img data-conditional-opacity-child={1} style={{transition: 'opacity 0.5s', width: 14, height: 14}} src={require('~/assets/x-icon-black.png')}/>
        </div>
      )} */}
    </View>
  );
};

JconNode = React.memo(JconNode, getArePropsEqual({
  ignoredPropKeys: {
    handleProps: {},
    item: {
      ignoredPropKeys: {
        create: {},
        children: {},
        parent: {},
        schema: {},
        suggestionGroups: {},
        onChange: {},
        togglePathSelected: {},
        typeOptions: {}
      },
      useIsEqualPropKeys: {
        value: {}
      }
    }
  },
  useIsEqualPropKeys: {
    selectedItemPaths: {}
  }
}));

var WrappedJconNode = React.forwardRef(function WrappedJconNode(props, ref) {
  var labelWidth = useContext(LabelWidthContext);

  return (
    <FolderTreeItemWrapper {..._.omit(props, ['activeNodePath', 'setActiveNode', 'sortableProps'])} manualDrag ref={ref} showDragHandle={false} style={{zIndex: 1000 - props.item.flatIndex, ...props.style}}>
      <JconNode {..._.pick(props, ['activeNodePath', 'setActiveNode', 'item', 'handleProps'])} {...{labelWidth}}/>
    </FolderTreeItemWrapper>
  );
});

export default WrappedJconNode;
