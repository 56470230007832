import jconObjectToJxString from '~/helpers/jconObjectToJxString';
import _ from 'lodash';
import allComponentLibaries from '~/component-libraries';
import detectMultipleStatements from '~/helpers/detectMultipleStatements';
import * as sass from 'sass';
import { memo } from '@symbolic/lib';

const prettier = require('prettier/standalone');
const babelParser = require('prettier/parser-babel');
const prettierPluginEstree = require('prettier/plugins/estree');
const reactToCSS = require('react-style-object-to-css');

var { transform } = require('@babel/standalone');

var compileJcon = async (jconPackage, {generateDevScript = false} = {}) => {
  try {
    var isWeb = jconPackage.platform === 'web';
    var App_js = '', globals_scss = '', App_module_scss = '';
    var {platform} = jconPackage;
    var styleObject = {};

    if (isWeb && !jconPackage.useServerSideRendering) {
      App_js += '\'use client\'\n\n';
    }

    var idsByType = {};

    if (jconPackage.styleSheet) {
      globals_scss += jconPackage.styleSheet + '\n\n';
    }

    if (jconPackage.styleModule) {
      App_module_scss += jconPackage.styleModule + '\n\n';
    }

    // --------- <IMPORTS> ---------

    App_js += '//DEVELOPER NOTE: This file will be split into more organized files/packages prior to public early access\n\n';

    if (isWeb && !generateDevScript) {
      App_js += 'import classes from \'./App.module.scss\'\n';
    }

    var userImports = jconPackage.imports || [];

    var imports = [
      {import: 'React', from: 'react'},
      {import: {useState: {}, useEffect: {}, useRef: {}}, from: 'react', spaceAfter: userImports.length > 0},
      ...userImports
    ];

    var componentLibraries = _.filter(allComponentLibaries, ({name, platforms}) => !platforms || _.includes(platforms, platform) && (!jconPackage.componentLibraries || _.includes(jconPackage.componentLibraries, name)));

    _.forEach(componentLibraries, componentLibrary => {
      _.forEach(componentLibrary.components, component => {
        _.forEach(component.imports, (_import) => {
          if (!_import.platforms || _.includes(_import.platforms, platform)) {
            imports.push(_import);
          }
        });
      });
    });

    _.forEach(imports, ({import: _import, require: _require, alias, from, spaceAfter}) => {
      if (generateDevScript && !_require) _require = _import;

      if (_require) {
        if (typeof(_require) === 'string') {
          App_js += `var ${alias || _require} = require('${from}');\n`;
        }
        else if (typeof(_require) === 'object') {
          App_js += `var { ${_.join(_.map(_require, ({alias}, key) => key + (alias ? `: ${alias}` : '')), ', ')} } = require('${from}');\n`;
        }
      }
      else {
        if (typeof(_import) === 'string') {
          App_js += `import ${_import}${alias ? ` as ${alias}` : ''} from '${from}';\n`;
        }
        else if (typeof(_import) === 'object') {
          App_js += `import { ${_.join(_.map(_import, ({alias}, key) => key + (alias ? ` as ${alias}` : '')), ', ')} } from '${from}';\n`;
        }
      }

      if (spaceAfter) App_js += '\n';
    });

    if (!isWeb) {
      App_js += 'import { Dimensions, Platform } from \'react-native\';\n';
    }

    // --------- </IMPORTS> ---------

    App_js += '\n';

    var valueToCode = (value, {indentation = '', name, inline = false, forceSingleStatement = false} = {}) => {
      if (typeof value === 'string' && _.startsWith(value, '{') && _.endsWith(value, '}')) {
        var value = value.replace(/^\{/, '').replace(/\}$/, '');

        if (value === '') value = 'undefined';

        if (detectMultipleStatements(value)) {
          value = `(() => {\n  ${value.replace(/\n/g, '\n' + '  ')}\n})()`;
        }
        else {
          value = _.trim(value);

          if (value.endsWith(';')) value = value.slice(0, -1);
        }

        //replace newline with newline + indentation
        value = value.replace(/\n/g, '\n' + indentation);
      }
      else {
        value = jconObjectToJxString(value, {indentation: indentation, isChild: true, inline, parseScripts: true});
      }

      return value;
    };

    //TODO object to react - break out style transformer and this to helpers
    var nodeToReact = (node, {indentation = '', parentIsNativeLogic, parentNode} = {}) => {
      var {children} = node;
      var isNativeLogic = _.includes(['If', 'Map'], node.type);

      if (typeof(children) === 'string' && _.startsWith(children, '{') && _.endsWith(children, '}')) {
        if (isNativeLogic) {
          children = valueToCode(children, {indentation: ''});
        }
        else {
          children = '{' + valueToCode(children, {indentation: ''}) + '}';
        }
      }
      else if (Array.isArray(children)) {
        children = _.filter(children, node => !node.platforms || _.includes(node.platforms, platform));
      }

      if (node.type === 'Component') {
        var multipleChildren = Array.isArray(children) && children.length > 1;

        if (node.styleSheet) {
          globals_scss += node.styleSheet + '\n\n';
        }

        if (node.styleModule) {
          App_module_scss += node.styleModule + '\n\n';
        }

        App_js += indentation + `export const ${node.name} = ` + (node.forwardRef ? 'React.forwardRef(' : '') + `(props${node.forwardRef ? ', ref' : ''}) => {\n`;

        if (node.useContexts) {
          App_js += indentation + `  var {${_.join(node.useContexts, ', ')}} = useContexts(${JSON.stringify(node.useContexts)});\n\n`;
        }

        //expressions
        if (node.expressions?.length > 0) {
          _.forEach(node.expressions, expression => {
            if (typeof(expression === 'string') && _.startsWith(expression, '{') && _.endsWith(expression, '}')) {
              var code = expression.replace(/^\{/, '').replace(/\}$/, '');

              if (code.startsWith('(') && code.endsWith(')()')) {
                if (_.includes(code, '{') && _.includes(code, '}')) { //HINT multiline script
                  var startCharacterIndex = _.indexOf(code, '{');
                  var endCharacterIndex = _.lastIndexOf(code, '}');

                  code = code.substring(startCharacterIndex + 1, endCharacterIndex);
                }
                else { //HINT single line script
                  var startCharacterIndex = '(() => '.length;
                  var endCharacterIndex = code.length - ')()'.length;

                  code = code.substring(startCharacterIndex, endCharacterIndex);
                }
              }

              App_js += indentation + `  ${code.replace(/\n/g, '\n' + indentation)}\n\n`;
            }
            else {
              var {type, var: _var, value} = expression;

              if (type === 'var') {
                if (Array.isArray(_var)) {
                  _var = '[' + _.join(_var, ', ') + ']';
                }
                else if (typeof(_var) === 'object') {
                  _var = '{' + _.map(_var, (value, key) => value === '...' ? `...${key}` : key).join(', ') + '}';
                }
                else if (_.startsWith(_var, '{') && _.endsWith(_var, '}')) {
                  _var = _var.replace(/^\{/, '').replace(/\}$/, '');

                  if (_.startsWith(_var, '(') && _.endsWith(_var, ')')) {
                    _var = _var.replace(/^\(/, '').replace(/\)$/, '');
                  }
                }

                var useValueParens = typeof(value) === 'object';

                App_js += indentation + `  var ${_var} = ${useValueParens ? '(' : ''}${valueToCode(value, {indentation: indentation + '  '})}${useValueParens ? ')' : ''};\n\n`;
              }
              else if (type === 'state') {
                App_js += indentation + `  var [${_.join(_var, ', ')}] = useState(${valueToCode(expression.initialState, {indentation})});\n\n`;
              }
              else if (type === 'persistentState') {
                App_js += indentation + `  var [${_.join(_var, ', ')}] = usePersistentState(${valueToCode(expression.initialState, {indentation})}, ${JSON.stringify(expression.key || expression.var[0])});\n\n`;
              }
              else if (type === 'effect') {
                App_js += indentation + `  useEffect(${expression.setup.replace(/^\{/, '').replace(/\}$/, '')}, ${valueToCode(expression.dependencies, {indentation: ''})});\n\n`;
              }
              else if (type === 'ref') {
                App_js += indentation + `  var ${_var} = useRef(${valueToCode(expression.initialValue, {indentation})});\n\n`;
              }
              else if (type === 'records') { //TODO parse custom expression types - via arg
                App_js += indentation + `  var [${_.join(_var, ', ')}] = useRecords(${valueToCode(_.pick(expression, ['tableId']), {indentation})});\n\n`;
              }
            }
          });

          App_js += '\n';
        }

        if (children) {
          var provideContextsCode = node.provideContexts ? `provideContexts({${node.provideContexts.join(', ')}}, ` : '';

          if (Array.isArray(children) && children.length > 0) {
            App_js += indentation + `  return ${provideContextsCode}(${multipleChildren ? '<>' : ''}\n`; //TODO fragment

            _.forEach(children, _node => nodeToReact(_node, {indentation: indentation + '    ', parentIsNativeLogic: !multipleChildren, parentNode: _node}));

            App_js += indentation + `  ${multipleChildren ? '</>' : ''})${node.provideContexts ? ')' : ''};\n`;
          }
          else if (typeof(children) === 'string') {
            App_js += ` return ${provideContextsCode}${valueToCode(children)}${node.provideContexts ? ')' : ''}\n`;
          }
        }

        App_js += `}${node.forwardRef ? ')' : ''}\n\n`;
      }
      else {
        var tagName = node.type;

        var hasChildren = children?.length > 0 || typeof(children) === 'string';
        var multipleChildren = Array.isArray(children) && children.length > 1;

        if (node.type === 'If') {
          App_js += indentation + `${parentIsNativeLogic ? '' : '{'}(${valueToCode(node.condition)}) && (${multipleChildren ? '<>' : ''}\n`;
        }
        else if (node.type === 'Map') {
          App_js += indentation + `${parentIsNativeLogic ? '' : '{'}(${valueToCode(node.data, {indentation})}).map((${_.join(node.var, ', ')}) => (${multipleChildren ? '<>' : ''}\n`;
        }
        else {
          if (node.shouldRender !== undefined) {
            App_js += indentation + `${parentIsNativeLogic ? '' : '{'}(${valueToCode(node.shouldRender, {indentation})}) && (\n`;

            indentation += '  ';
          }

          App_js += indentation + `<${tagName}`;

          var propSets = Array.isArray(node.props) ? node.props : [node.props];
          var propCount = _.sum(_.map(propSets, propSet => typeof(propSet) === 'string' ? 1 : _.size(propSet)));
          var propsMultiline = (_.some(propSets, propSet => typeof(propSet) === 'object' && propSet.style) && propCount > 1) || propCount > 3;
          var prefix = propsMultiline ? `\n${indentation}  ` : ' ';
          var classNames = [];

          //TODO consider splitting into multiline
          _.forEach(propSets, propSet => {
            if (typeof(propSet) === 'string') {
              if (propSet !== '{}') App_js += `${prefix}{...${valueToCode(propSet)}}`;
            }
            else {
              if (propSet?.className) classNames.push(valueToCode(propSet.className, {indentation: ''}));

              _.forEach(_.omit(propSet, ['style', 'className']), (value, key) => {
                if (key === 'contentContainerStyle' && typeof(value) === 'object') {
                  value = transformStyleObject(value);
                }

                value = valueToCode(value, {indentation, inline: true});

                App_js += prefix + key + (_.startsWith(value, '"') && _.endsWith(value, '"') && _.countBy(value)['"'] == 2 ? `=${value}` : (value ? `={${value}}` : ''));
              });
            }
          });

          /* ------- <STYLE> ------- */
          var styleSets = [];

          if (node.style) {
            styleSets.push(...(Array.isArray(node.style) ? node.style : [node.style]));
          }

          if (node.props?.style) {
            styleSets.push(...(Array.isArray(node.props.style) ? node.props.style : [node.props.style]));
          }

          var styleStrings = [];
          var cssModuleStyleStrings = [];

          var moduleClassName = node.name;

          if (!moduleClassName) {
            idsByType[node.type] = (idsByType[node.type] || 0) + 1;

            moduleClassName = `${node.type}${idsByType[node.type]}`;
          }

          var moduleClassNameIsSafeKey = moduleClassName.match(/^[a-zA-Z_$][a-zA-Z_$0-9]*$/);
          var hasStaticStyle = false;

          _.forEach(styleSets, (styleSet) => {
            if (typeof(styleSet) === 'string') {
              if (_.startsWith(styleSet, '{') && _.endsWith(styleSet, '}')) {
                styleStrings.push((isWeb ? '...' : '') + valueToCode(styleSet));
              }
              else {
                cssModuleStyleStrings.push(styleSet);
              }
            }
            else {
              var styles = styleSet;
              var isAllDynamic = false;

              if (typeof(styleSet) === 'object' && styleSet.style) {
                var isStyleCollection = true;
                var {condition, format, selector, style: styles} = styleSet;

                if (condition !== undefined) {
                  isAllDynamic = true;
                }
                if (format !== undefined && !isWeb) {
                  isAllDynamic = true;
                }
              }

              if (typeof(styles) === 'object') {
                styles = transformStyleObject(styles);

                var filter = isAllDynamic ? () => false : (value) => !(typeof(value) === 'string' && _.startsWith(value, '{') && _.endsWith(value, '}'));

                var staticObject = _.pickBy(styles, filter);
                var dynamicObject = _.omitBy(styles, filter);

                if (_.size(staticObject)) {
                  hasStaticStyle = true;

                  if (!isWeb) {
                    styleObject[moduleClassName] = staticObject;

                    styleStrings.push(moduleClassNameIsSafeKey ? `styles.${moduleClassName}` : `styles[${JSON.stringify(moduleClassName)}]`);
                  }
                  else {
                    var objectCSS = _.trim(reactToCSS(_.omitBy(staticObject, value => value === ''))).replace(/; {2}/g, ';  \n');

                    if (format !== undefined) {
                      objectCSS = `@media (${reactToCSS(jconPackage.formats[format]).replace(';  ', '')}) {\n  ${objectCSS}\n}`;
                    }
                    else if (selector !== undefined) {
                      objectCSS = `${selector} {\n  ${objectCSS}\n}`;
                    }

                    if (isWeb || isStyleCollection) {
                      cssModuleStyleStrings.push(objectCSS);
                    }
                  }
                }

                if (_.size(dynamicObject)) {
                  var styleString = valueToCode(dynamicObject, {indentation: '', inline: true});

                  if (condition !== undefined) {
                    styleString = `${isWeb ? '...' : ''}((${valueToCode(condition, {indentation: '', inline: true})}) ? ${styleString} : {})`;
                  }
                  else if (format !== undefined && !isWeb) {
                    styleString = `useFormat(${JSON.stringify(format)}, ${styleString})`;
                  }
                  else if (isWeb) {
                    styleString = styleString.replace(/^\{/, '').replace(/\}$/, ''); //HINT not removing script - removing object curly braces
                  }

                  styleStrings.push(styleString);
                }
              }
            }
          });

          if (styleStrings.length) {
            if (!isWeb) {
              if (!classNames.length && styleStrings.length === 1 && hasStaticStyle) {
                var styleString = `style={${styleStrings[0]}}`;
              }
              else {
                var styleString = `style={useStyle([\n${indentation + '  '}${_.join(styleStrings, `, \n${indentation}  `)}\n${indentation}]${className ? `, {className: ${className}}` : ''})}`;
              }
            }
            else {
              var styleString = 'style={{' + _.join(styleStrings, ', ') + '}}';
            }

            App_js += prefix + styleString;
          }

          // -------- STYLE>

          if (cssModuleStyleStrings.length || hasStaticStyle) {
            classNames.push(isWeb ? (
              'classes' + (moduleClassNameIsSafeKey ? `.${moduleClassName}` : `[${JSON.stringify(moduleClassName)}]`)
            ) : JSON.stringify(moduleClassName));
          }

          if (parentNode) {
            classNames.push('(props.className || \'\')');
          }

          var className = _.join(classNames, ' + \' \' + '); //TODO string interpolation

          if (isWeb && className) {
            App_js += prefix + `className={${className}}`;
          }

          if (cssModuleStyleStrings.length) {
            App_module_scss += `.${moduleClassName} {\n`;

            _.forEach(cssModuleStyleStrings, (styleString, index) => {
              App_module_scss += `  ${styleString.replace(/\n/g, '\n  ')}\n${index < cssModuleStyleStrings.length - 1 ? '\n' : ''}`;
            });

            App_module_scss += '}\n\n';
          }

          // if (key === 'style' && Array.isArray(value)) {
          //   App_js += `${prefix} style={{`;

          //   App_js += _.join(_.filter(_.map(value, styleSet => {
          //     return typeof(styleSet) === 'object' ? valueToCode(styleSet, {indentation: '', inline: true}).replace(/^\{/, '').replace(/\}$/, '') : `...${valueToCode(styleSet, {indentation})}`;
          //   }), value => !!value), ', ');

          //   App_js += '}}';
          // }
        }

        if (hasChildren) {
          if (!isNativeLogic) App_js += (propsMultiline ? `\n${indentation}` : '') + '>\n';

          if (node.useChildrenCallback) {
            App_js += indentation + `  {(${node.var[0]}, ${node.var[1]}) => (\n`;
          }

          if (Array.isArray(children)) {
            _.forEach(children, childNode => nodeToReact(childNode, {parentIsNativeLogic: isNativeLogic && !multipleChildren, indentation: indentation + (node.useChildrenCallback ? '  ' : '') + '  '}));
          }
          else {
            App_js += indentation + (node.useChildrenCallback ? '    ' : '') + '  ' + children + '\n';
          }

          if (node.useChildrenCallback) {
            App_js += indentation + '  )}\n';
          }

          if (!isNativeLogic) App_js += indentation + `</${tagName}>\n`;
        }
        else {
          if (isNativeLogic) {
            App_js += indentation + '  null\n';
          }
          else {
            App_js += (propsMultiline ? `\n${indentation}` : '') + '/>\n';
          }
        }

        if (isNativeLogic) {
          App_js += indentation + (multipleChildren ? '</>' : '') + (node.type === 'Map' ? ')' : '') + (parentIsNativeLogic ? ')\n' : ')}\n');
        }
        else {
          if (node.shouldRender !== undefined) {
            App_js += indentation.replace('  ', '') + (parentIsNativeLogic ? ')\n' : ')}\n');
          }
        }
      }
    };

    _.forEach(_.filter(jconPackage.components, {type: 'Component'}), node => nodeToReact(node));

    var __initial_App_module_scss = App_module_scss;

    App_module_scss = '';

    _.forEach(componentLibraries, componentLibrary => {
      _.forEach(componentLibrary.components, (component, name) => {
        if (typeof(component.setup) === 'string') {
          App_js += component.setup.replace(/^\{/, '').replace(/\}$/, '') + '\n\n';
        }
        else {
          nodeToReact({type: 'Component', name, ...component});
        }
      });
    });

    App_module_scss = App_module_scss + __initial_App_module_scss;

    App_js += '\n//-------- everything below this line is temporarily inline - all above components will have their own files - and all below logic will be imported from npm packages -----------\n\n';

    if (_.size(styleObject)) {
      App_js += 'var styles = ' + jconObjectToJxString(styleObject, {isChild: true}) + ';\n\n';
    }

    var forEachRoute = (callback) => {
      var handleRoute = (route, parentPath) => {
        route = {...route, fullPath: parentPath ? parentPath + route.path : route.path};

        callback(route);

        if (route.children) {
          _.forEach(route.children, childRoute => {
            handleRoute(childRoute, route.fullPath);
          });
        }
      };

      _.forEach(jconPackage.routes, route => {
        handleRoute(route);
      });
    };

    //consider /robots.txt, /folder/file.txt, /*, /:slug, /:slug/file.txt
    //needs all routes to find match

    //eventually we're going to loop over all routes/component files and need to do something similar
    if (generateDevScript) {
      App_js += `var getActiveRoute = (url, routes) => {
  return routes.find(route => route.path === url || route.path === '/*');
};\n\n`;

      App_js += 'function __App(props) {\n';
    }
    else {
      App_js += 'export default function __App(props) {\n';
    }

    var files = {};

    if (!isWeb || generateDevScript) {
      var css = sass.compileString(globals_scss + '\n\n' + App_module_scss).css;
    }

    if (!isWeb && jconPackage.styleFormats) {
      App_js += `  var activeFormats = useActiveFormats();

    return (
      <__ActiveFormatsContext.Provider value={activeFormats}>
        <${jconPackage.rootComponent} />
      </__ActiveFormatsContext.Provider>
    );
  };\n\n`;
    }
    else {
      if (generateDevScript && css) {
        App_js += `
  if (typeof(document) !== 'undefined') {
    useEffect(() => {
      document.getElementById('scaffolding-styles')?.remove();

      var style = document.createElement('style');

      style.id = 'scaffolding-styles';

      style.innerHTML = ${JSON.stringify(css)};

      document.head.appendChild(style);
    });
  }\n\n`;
      }

      if (jconPackage.rootComponent) {
        App_js += `  return ${jconPackage.useServerSideRendering ? '' : 'typeof(window) !== "undefined" && '}(
    <${jconPackage.rootComponent} />
  );\n`;
      }
      else {
        if (generateDevScript) {
          App_js += `var activeRoute = getActiveRoute(props.__requestUrl, props.__routes);

  var components = {
    App
  };

  var RouteComponent = components[activeRoute.component];

  return activeRoute ? <RouteComponent {...props}/> : null;\n`;
        }
      }

      App_js += '}\n\n';
    }

    App_js += `
//to become @scaffolding/react
var usePersistentState = (initialValue, key) => {
  var localStorageValue = localStorage.getItem(key);

  initialValue = localStorageValue !== null ? JSON.parse(localStorageValue) : initialValue;

  var [state, setState] = useState(initialValue);

  return [state, (value) => {
    setState(value);

    localStorage.setItem(key, JSON.stringify(value));
  }];
};

var scaffoldingApiRequest = (uri, body, {files} = {}) => {
  var options = {};

  if (files) {
    options.body = new FormData();

    for (var f in files) {
      options.body.append(f, files[f]);
    }

    for (var key in body) {
      var value = body[key];

      if (typeof(value) !== 'undefined') {
        options.body.append(key, typeof(value) === 'object' ? JSON.stringify(value) : value);
      }
    }
  }
  else {
    options.body = JSON.stringify(body);
    options.headers = {'Content-Type': 'application/json'};
  }

  return fetch("${process.env.NODE_ENV === 'development' ? 'http://localhost:3301' : 'https://api.symbolicframeworks.com'}" + '/scaffolding' + uri, {
    method: 'post',
    mode: 'cors',
    ...options
  }).then(response => response.json());
};

var useRecords = ({tableId}) => {
  var [records, setRecords] = useState([]);

  useEffect(() => {
    scaffoldingApiRequest('/database-records', {action: 'get', tableId}).then(({data: records}) => {
      setRecords(records);
    });
  }, []);

  //TODO put in useEffect
  var recordsStore = {
    create: async (record, {files, ...params} = {}) => {
      var {data: newRecords} = await scaffoldingApiRequest('/database-records', {action: 'create', tableId, record, ...params}, {files});

      setRecords([...records, ...newRecords]);

      return newRecords[0];
    },
    update: async (updates, {id, files, ...params} = {}) => {
      var oldRecord = records.find(_record => _record.id === id);
      var updatedRecord = {...oldRecord, ...updates};
      var updatedProperties = {...updatedRecord};

      delete updatedProperties.id;

      await scaffoldingApiRequest('/database-records', {action: 'update', tableId, updatedRecord: updatedProperties, recordId: id, ...params}, {files})

      setRecords(records.map(_record => _record.id === updatedRecord.id ? updatedRecord : _record));

      return updatedRecord;
    },
    delete: async ({id}) => {
      var deletedRecord = await scaffoldingApiRequest('/database-records', {action: 'delete', tableId, recordId: id});

      setRecords(records.filter(_record => _record.id !== id));
    },
    set: (records) => setRecords(records)
  };

  return [records, recordsStore];
};

var useRecord = ({tableId, where}) => {

};

var __Contexts = {};

var provideContexts = (values, children) => {
  for (var contextKey in values) {
    if (!__Contexts[contextKey]) {
      __Contexts[contextKey] = React.createContext(contextKey);
    }
  }

  var renderProviders = (contextsData, children) => {
    if (contextsData.length) {
      var [[contextKey, Context], ...restContextsData] = contextsData;

      return <Context.Provider value={values[contextKey]}>{renderProviders(restContextsData, children)}</Context.Provider>;
    }
    else {
      return children;
    }
  }

  return renderProviders(Object.keys(values).map(contextKey => [contextKey, __Contexts[contextKey]]), children || null);
};

var useContexts = (contextKeys) => {
  var contextValues = {};

  contextKeys.forEach(contextKey => {
    if (!__Contexts[contextKey]) {
      throw new Error('Context not found: ' + contextKey);
    }
    else {
      contextValues[contextKey] = React.useContext(__Contexts[contextKey]);
    }
  });

  return contextValues;
};

`;

    if (!isWeb) {
      App_js += `function useStyle(style, {className} = {}) {
    if (className) style.push({$$css: true, _: className});

    return style;
  };\n`;

      if (jconPackage.styleFormats) {
        App_js += `\nconst __styleFormats = ${jconObjectToJxString(jconPackage.styleFormats, {isChild: true})};

  var __ActiveFormatsContext = React.provideContext('__activeFormats');

  function useFormat(formatKey, style) {
    var activeFormats = React.useContext(__ActiveFormatsContext);

    return activeFormats[formatKey] ? style : {};
  }

  function useActiveFormats() {
    var getActiveFormatsData = ({window, activeFormats}) => {
      var newActiveFormats = {};
      var formatsHaveChanged = false;

      for (var key in __styleFormats) {
        var format = __styleFormats[key];

        if (format.minWidth !== undefined && window.width >= format.minWidth) {
          newActiveFormats[key] = true;
        }
        else if (format.maxWidth !== undefined && window.width <= format.maxWidth) {
          newActiveFormats[key] = true;
        }
        else if (format.minHeight !== undefined && window.height >= format.minHeight) {
          newActiveFormats[key] = true;
        }
        else if (format.maxHeight !== undefined && window.height <= format.maxHeight) {
          newActiveFormats[key] = true;
        }

        if (activeFormats && newActiveFormats[key] !== activeFormats[key]) {
          formatsHaveChanged = true;
        }
      }

      return {newActiveFormats, formatsHaveChanged};
    }

    var [activeFormats, setActiveFormats] = useState(getActiveFormatsData({window: Dimensions.get('window')}).newActiveFormats);

    var handleDimensionsChangeRef = useRef();

    handleDimensionsChangeRef.current = ({window, screen}) => {
      var {newActiveFormats, formatsHaveChanged} = getActiveFormatsData({window, activeFormats});

      if (formatsHaveChanged) setActiveFormats(newActiveFormats);
    };

    useEffect(() => {
      const subscription = Dimensions.addEventListener('change', ({window, screen}) => {
        handleDimensionsChangeRef.current({window, screen});
      });

      return () => subscription.remove();
    });

    return activeFormats;
  }
  \n`;
      }
    }

    files['App.js'] = App_js;

    if (!isWeb && css.length) {
      files['App.js'] += `
${isWeb ? '' : 'if (Platform.OS === \'web\') {'}
  if (typeof(document) !== 'undefined') {
    var webCSS = ${JSON.stringify(css)}

    const style = document.createElement('style');

    style.textContent = webCSS;

    document.head.append(style);
  }
${isWeb ? '' : '}'}\n\n`;
    }

    if (generateDevScript) {
      files['pages/[...path].js'] = `import App, { getServerSideProps } from '../App';

      export { getServerSideProps };

      export default App;
      `;

          files['pages/index.js'] = `import App, { getServerSideProps } from '../App';

      export { getServerSideProps };

      export default App;
      `;

      if (jconPackage.routes) {
        files['App.js'] += `var getServerSideProps = async ({req, res, __appData}) => {
  var props = {__requestUrl: req.url, __routes: __appData.routes};

  var activeRoute = getActiveRoute(req.url, __appData.routes);

  if (activeRoute && activeRoute.getServerSideProps) {
    props = {...props, ...(await (eval(activeRoute.getServerSideProps))({req, res})).props};
  }

  return {props};
}\n\n`;

        files['App.js'] += '({__App, getServerSideProps});\n\n';
      }
      else {
        files['App.js'] += '({__App, getServerSideProps: null});\n\n';
      }

      try {
        files['App.js'] = transform(files['App.js'], {presets: ['react'], plugins: ['transform-modules-commonjs']}).code.replace('"use strict";\n', '');
      }
      catch (error) {
        console.log('Babel error: ', error);
      }
    }
    else {
      if (jconPackage.routes && jconPackage.routes.length) {
        var compileRoute = (route, filename) => {
          var {path} = route;

          filename += filename ? '/' + path : path;
          console.log(route, filename)
          if (route.handler) {
            files[filename + '.js'] = `export const maxDuration = 300;\n\nvar handler = ${route.handler.replace(/^\{/, '').replace(/\}$/, '')};\n\nexport default handler;\n\n`;
          }
          else if (route.component) {
            files[filename + '.js'] = `import { ${route.component} } from '../App';\n\n`;

            if (route.getServerSideProps) {
              files[filename + '.js'] += `export const getServerSideProps = ${route.getServerSideProps.replace(/^\{/, '').replace(/\}$/, '')}\n\n`;

              files[filename + '.js'] += `export default ${route.component};\n\n`;
            }
          }

          if (route.children) {
            _.forEach(route.children, childRoute => {
              compileRoute(childRoute, filename);
            });
          }
        };

        _.forEach(jconPackage.routes, (route) => compileRoute(route, ''));
      }
      else {
        files['pages/[...path].js'] = `import App from '../App';

export default App;
`;

            files['pages/index.js'] = `import App from '../App';

export default App;
`;
      }

      _.forEach(jconPackage.files, (file) => files[file.path] = file.content);
    }

    try {
      files['App.js'] = await prettier.format(files['App.js'], {parser: 'babel', plugins: [babelParser, prettierPluginEstree]});
    }
    catch (error) {
      console.log('Prettier error: ', error);
    }

    if (isWeb && !generateDevScript) {
      files['globals.scss'] = globals_scss;
      files['App.module.scss'] = App_module_scss;
    }

    var deps = _.size(jconPackage.dependencies) ? _.join(_.map(jconPackage.dependencies, (version, dep) => `"${dep}": "${version}"`), ',\n    ') : '';
    var devDeps = _.size(jconPackage.devDependencies) ? _.join(_.map(jconPackage.devDependencies, (version, dep) => `"${dep}": "${version}"`), ',\n    ') : '';

    if (isWeb) {
      files['pages/_app.js'] = `import '../globals.scss';

export default function MyApp({ Component, pageProps }) {
  return (
    <Component {...pageProps} />
  );
}\n`;

      files['package.json'] = `{
  "name": "jcon-app",
  "version": "0.1.0",
  "private": true,
  "scripts": {
    "dev": "next dev",
    "build": "next build",
    "start": "next start",
    "lint": "next lint"
  },
  "dependencies": {
    ${deps}
  },
  "devDependencies": {
    ${devDeps}
  }
}
`;
    }
    else {
      files['package.json'] = `{
  "name": "jcon-app",
  "version": "1.0.0",
  "main": "node_modules/expo/AppEntry.js",
  "scripts": {
    "start": "expo start",
    "android": "expo start --android",
    "ios": "expo start --ios",
    "web": "expo start --web"
  },
  "dependencies": {
    ${deps}
  },
  "devDependencies": {
    ${devDeps}
  },
  "private": true
}
`;

      // files['.babelrc'] = `{
      //   "presets": ["react-native"]
      // }`;
      files['babel.config.js'] = `module.exports = function(api) {
  api.cache(true);
  return {
    "presets": ["module:metro-react-native-babel-preset"],
  };
};
`;

      files['app.json'] = `{
  "expo": {
    "name": "expo-test",
    "slug": "expo-test",
    "version": "1.0.0",
    "orientation": "portrait",
    "userInterfaceStyle": "light",
    "assetBundlePatterns": [
      "**/*"
    ],
    "ios": {
      "supportsTablet": true
    },
    "android": {
    }
  }
}
`;

    }
  }
  catch (err) {
    console.log('CompileJCON error: ', err);
  }

  return files;
};

var transformStyleObject = (styles) => {
  styles = {...styles};

  for (var key in styles) {
    var value = styles[key];
    var keyMappings = {
      paddingVertical: ['paddingTop', 'paddingBottom'],
      paddingHorizontal: ['paddingLeft', 'paddingRight'],
      marginVertical: ['marginTop', 'marginBottom'],
      marginHorizontal: ['marginLeft', 'marginRight'],
      borderLeftRadius: ['borderTopLeftRadius', 'borderBottomLeftRadius'],
      borderRightRadius: ['borderTopRightRadius', 'borderBottomRightRadius'],
      borderTopRadius: ['borderTopLeftRadius', 'borderTopRightRadius'],
      borderBottomRadius: ['borderBottomLeftRadius', 'borderBottomRightRadius']
    }[key];

    if (keyMappings) { //TODO don't use keyMappings for padding/margin on cross-platform
      _.forEach(keyMappings, (mappedKey) => {
        if (styles[mappedKey] === undefined) {
          styles[mappedKey] = value;
        }
      });

      delete styles[key];
    }
  }

  return styles;
}

export default memo(compileJcon, {argSpecs: [{destructure: false}, {destructure: true}]});
